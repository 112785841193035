/** @jsx jsx */
import { jsx } from '@emotion/core';
import { darken } from 'polished';

import TickSvg from '../../../../components/svg/picto/TickSvg';
import {
  ShColor,
  ShColors,
  ShServiceColorPalettes,
} from '@shoootin/design-tokens';
import { ShStyles } from '../../../../designSystem/constants/ShStyles';
import { ShService } from '@shoootin/config';

export const ShInputCheckboxSizes = {
  xs: 12,
  s: 16,
  sm: 20,
  m: 24,
} as const;
export const ShInputCheckboxLabelSizes = {
    xs: 12,
    s: 14,
    sm: 16,
    m: 16,
} as const;

export type ShInputCheckboxSize = keyof typeof ShInputCheckboxSizes;
export const ShInputCheckboxSizeList = Object.keys(
  ShInputCheckboxSizes,
) as ShInputCheckboxSize[];

export type ShInputCheckboxProps = {
  value: boolean;
  onChange?: (value: boolean) => void;
  service?: ShService;
  size?: ShInputCheckboxSize;
};

export const ShInputCheckbox = ({
  value = false,
  onChange,
  service,
  size = 'm',
}: ShInputCheckboxProps) => {
  const serviceStroke: ShColor = service
    ? ShServiceColorPalettes[service].dark1
    : 'base';
  return (
    <span
      onClick={() => onChange && onChange(!value)}
      css={{
        ...ShStyles.centered,
        backgroundColor: ShColors.whiteD,
        transition: `background-color .1s cubic-bezier(.4,0,.2,1),
        stroke-dashoffset .1s cubic-bezier(0,0,.2,1)`,
        cursor: 'pointer',
        width: `${ShInputCheckboxSizes[size] + 10}px`,
        height: `${ShInputCheckboxSizes[size] + 10}px`,
        strokeWidth: 4,
        strokeDasharray: 24,
        strokeDashoffset: value ? 0 : 24,

        '&:hover': {
          backgroundColor: darken(0.025, ShColors.whiteD),
        },

        '&:active': {
          backgroundColor: darken(0.05, ShColors.whiteD),
        },
      }}
    >
      <TickSvg width={ShInputCheckboxSizes[size]} color={serviceStroke} />
    </span>
  );
};
