/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, {
  useState,
  useEffect,
  useRef,
  ReactNode,
  FocusEventHandler,
  ComponentType,
  MouseEventHandler,
} from 'react';
import { createPortal } from 'react-dom';
import { useCombobox, UseComboboxReturnValue } from 'downshift';

import { ShColors } from '@shoootin/design-tokens';
import { Manager, Popper, Reference } from 'react-popper';
import { transparentize } from 'polished';
import { SvgProps } from '../../../../components/svg/SvgUtils';
import {
  ShInputItemContainer,
  ShInputTextRawElements,
} from '../ShInputText/ShInputText';
import { ShInputSize, useShInputSize } from '../ShInputTheme';
import { ShInputRequiredMark } from '../utils/ShInputRequiredMark/ShInputRequiredMark';
import { ShInputIcon } from '../utils/ShInputIcon/ShInputIcon';
import { ShInputError } from '../utils/ShInputError/ShInputError';
import { ShSpinner } from '../../spinner/ShSpinner/ShSpinner';
import { animated, Transition } from 'react-spring';
import { Arrow } from '@shoootin/front/src/primitives/appPoppers';

export type ShInputAutocompleteOption = {
  value: string;
  text?: string; // What's the input text if this option gets selected (fallback to value)
  content?: ReactNode; // The display of this option
};

export const toShInputAutocompleteOptions = (
  values: readonly string[],
  createContent: (value: string) => ReactNode = (value) => String(value),
): ShInputAutocompleteOption[] =>
  values.map((value) => ({
    value,
    content: createContent(value),
  }));

export type ShInputAutocompleteProps<
  Option extends ShInputAutocompleteOption = ShInputAutocompleteOption
> = {
  // Allowing free text means that the autocomplete behaves like a text input
  // the input won't be automatically erased on blur if the entered text does not match any option
  // Similar to "free solo" mode of material-ui
  // See https://material-ui.com/components/autocomplete/#free-solo
  allowFreeText?: boolean;
  isLoading?: boolean;
  value: string;
  onChange: (inputValue: string, option?: Option) => void;
  // undefined means don't show the suggestions
  // empty array means show "placeholderNoOptions" in dropdown
  options: Option[] | undefined | null;
  className?: string;
  placeholder?: string;
  placeholderNoOptions?: string;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  name?: string;
  required?: boolean;
  error?: string | null;
  icon?: {
    component: ComponentType<SvgProps>;
    onClick?: MouseEventHandler;
  };
  themeSize?: ShInputSize;
};

// downshift + popperjs impl inspired by https://codesandbox.io/s/qkm58q0l4w?file=/index.js:381-536
// TODO we should migrate to popper-react v2 with hooks (need to migrate/cleanup the gatsby code as well!)
export const ShInputAutocomplete = <
  Option extends ShInputAutocompleteOption = ShInputAutocompleteOption
>({
  allowFreeText = false,
  isLoading = false,
  value,
  onChange,
  options,
  className,
  placeholder,
  placeholderNoOptions = 'No result',
  onFocus,
  onBlur,
  name,
  required,
  error,
  icon,
  themeSize,
}: ShInputAutocompleteProps<Option>) => {
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(
    options?.find((o) => o.value === value),
  );

  // const selectedOption = options?.find((o) => o.value === value);

  const [lastSelectedOption, setLastSelectedOption] = useState<
    Option | undefined
  >(selectedOption);

  const inputRef = useRef<HTMLLabelElement | null>(null);
  const getInputWidth = () => {
    // The width if the dropdown should match the width of the dropdown target
    return inputRef.current
      ? inputRef.current.getBoundingClientRect().width
      : undefined;
  };

  const optionToString = (item?: ShInputAutocompleteOption) =>
    item?.text ?? item?.value ?? '';

  const combobox = useCombobox<ShInputAutocompleteOption>({
    initialInputValue: value,
    items: options ?? [],
    selectedItem: selectedOption,
    itemToString: optionToString,
    onInputValueChange: ({ inputValue }) => {
      __DEV__ && console.debug('onInputValueChange', inputValue);
      // onChange(inputValue ?? '');
    },
    onSelectedItemChange: (changes) => {
      __DEV__ && console.debug('onSelectedItemChange', changes);
      const option = changes.selectedItem as Option | undefined;
      setLastSelectedOption(option);
      setSelectedOption(option);
      onChange(optionToString(option), option);
    },
  });

  // TODO find a way to make this comp 100% controlled
  // Not ideal solution, but prevents input to loose caret
  // See https://github.com/downshift-js/downshift/issues/217#issuecomment-627392212
  // if we fire onChange event in onInputValueChange, it triggers after onSelectedItemChange and it resets the input value

  useEffect(() => {
    if (combobox.inputValue !== value) {
      console.log(
        'combobox.inputValue !== value',
        combobox.inputValue,
        value,
        combobox.selectedItem,
      );
      onChange(combobox.inputValue);
    } else {
      console.log(
        'combobox.inputValue === value',
        combobox.inputValue,
        value,
        combobox.selectedItem,
      );
    }
  }, [combobox.inputValue, value]);

  const comboboxInputProps = combobox.getInputProps({
    placeholder,
    onFocus: (e) => {
      combobox.openMenu();
      onFocus && onFocus(e);
    },
    onBlur: (e) => {
      onBlur && onBlur(e);

      // annoying but blur fires before onSelectedItemChange so no choice...
      setTimeout(() => {
        // if we don't allow free text,
        // we revert the input value to the last selected option
        if (!allowFreeText && value && lastSelectedOption) {
          __DEV__ &&
            console.debug('reset to lastSelectedOption', lastSelectedOption);
          combobox.selectItem(lastSelectedOption);
        }
      }, 50);
    },
    name,
  });

  const comboboxMenuProps = combobox.getMenuProps(
    {},
    // TODO temporarily disabled, as we downgraded downshift@4.2.1
    //  (on newer versions, onSelectedItemChange does not fire appropriately :o)

    // Remove useless warning, see https://github.com/downshift-js/downshift/pull/1053#issuecomment-634039471
    // @ts-ignore
    // { suppressRefError: true },
  );

  return (
    <ShInputTextRawElements.Container className={className}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <ShInputTextRawElements.InputWrapper
              {...combobox.getComboboxProps({
                ref: (r) => {
                  ref(r);
                  inputRef.current = r;
                },
              })}
            >
              <ShInputTextRawElements.Input
                {...comboboxInputProps}
                themeSize={themeSize}
              />
              {isLoading && (
                <ShInputItemContainer size={themeSize}>
                  <ShSpinner size={'xs'} color={'disabled'} />
                </ShInputItemContainer>
              )}
              {!isLoading && required && (
                <ShInputItemContainer size={themeSize}>
                  <ShInputRequiredMark />
                </ShInputItemContainer>
              )}
              {icon && (
                <ShInputItemContainer onClick={icon.onClick} size={themeSize}>
                  <ShInputIcon component={icon.component} />
                </ShInputItemContainer>
              )}
            </ShInputTextRawElements.InputWrapper>
          )}
        </Reference>

        {error && <ShInputError>{error}</ShInputError>}

        {/*{combobox.isOpen && options && (*/}
        <ShInputAutocompletePopper
          opened={combobox.isOpen && combobox.inputValue !== ''}
          width={getInputWidth()}
          selectedOption={selectedOption}
          options={options ?? []}
          placeholderNoOptions={placeholderNoOptions}
          // isLoading={isLoading}
          combobox={combobox}
          menuProps={comboboxMenuProps}
        />
        {/*)}*/}
      </Manager>
    </ShInputTextRawElements.Container>
  );
};

const ShInputAutocompletePopper = ({
  opened,
  width,
  selectedOption,
  options,
  placeholderNoOptions,
  // isLoading,
  combobox,
  menuProps,
}: {
  opened: boolean;
  width?: number;
  // isLoading: boolean;
  selectedOption: ShInputAutocompleteOption | undefined;
  options: ShInputAutocompleteOption[];
  placeholderNoOptions?: string;
  combobox: UseComboboxReturnValue<ShInputAutocompleteOption>;
  menuProps: any;
}) => {
  // We render in a body portal, which generally solves naturally all z-index problems
  // The portals naturally stacks on top of each others
  const portalTarget = document.body;

  return (
    <DefaultPopperTransition visible={opened}>
      {({ transitionStyle }) =>
        createPortal(
          <Popper
            placement="bottom"
            modifiers={{
              flip: { enabled: false },
              arrow: { enabled: false },
              ...(true && {
                preventOverflow: {
                  enabled: true,
                  boundariesElement: 'viewport',
                  padding: {
                    // top padding because of the sticky header
                    top: 100,
                    bottom: 0,
                    right: 0,
                    left: 0,
                  },
                  escapeWithReference: true,
                },
                hide: { enabled: true },
              }),
            }}
          >
            {({ placement, ref, style, outOfBoundaries }) => {
              return (
                <animated.div
                  ref={ref}
                  style={
                    {
                      // zIndex: 999999,
                      // margin: arrow ? '1em' : '0em',
                      // pointerEvents: opened ? 'auto' : 'none',
                      width: width,
                      ...style,
                      ...transitionStyle,
                      ...(outOfBoundaries
                        ? {
                            opacity: 0,
                            transition: 'opacity 200ms ease-in-out', // TODO how to animate this with react-spring too?
                          }
                        : undefined),
                    } // We expect portals to always be on top. Unfortunately layout is using z-index so adding portals to body is not enough
                  }
                  data-placement={placement}
                >
                  <div
                    // ref={ref}
                    // style={style}
                    css={{
                      // position: 'absolute',
                      // zIndex: 1, // Do not use z-index! use natural portals stacking
                      // width,
                      backgroundColor: 'white',
                      boxSizing: 'border-box',
                      borderBottomLeftRadius: 3,
                      borderBottomRightRadius: 3,
                      boxShadow: '0 0 0.7rem 0 rgba(0, 0, 0, 0.2)',
                    }}
                  >
                    {/*<div
                      css={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: transparentize(0.8, ShColors.black),
                        display: 'flex',
                        alignItems: 'center',
                        padding: 20,
                        pointerEvents: 'none',
                        transition: 'opacity 100ms ease-in',
                        opacity: isLoading ? 1 : 0,
                      }}
                    >
                      <ShSpinner size={'s'} />
                    </div>*/}
                    {options.length === 0 ? (
                      <div
                        css={{
                          display: 'flex',
                          alignItems: 'center',
                          padding: 20,
                        }}
                      >
                        {placeholderNoOptions}
                      </div>
                    ) : (
                      <ul
                        css={{
                          maxHeight: 200,
                          overflowX: 'hidden',
                          overflowY: 'auto',
                          padding: 0,
                          margin: 0,
                          backgroundColor: 'white',
                          listStyleType: 'none',
                        }}
                        {...menuProps}
                      >
                        {options.map((option, i) => {
                          const isSelected = option === selectedOption;

                          const isHighlighted = i === combobox.highlightedIndex;
                          const backgroundColor = isSelected
                            ? ShColors.whiteD
                            : isHighlighted
                            ? ShColors.whiteD
                            : ShColors.white;

                          return (
                            <li
                              key={option.value + '_' + i}
                              css={{
                                padding: 10,
                                borderTop:
                                  i > 0
                                    ? `1px solid ${ShColors.whiteD}`
                                    : 'none',
                                backgroundColor,
                                cursor: 'pointer',
                              }}
                              {...combobox.getItemProps({
                                item: option,
                                index: i,
                              })}
                            >
                              {option.content ?? option.text ?? option.value}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                  {/*{arrow && (
                          <Arrow
                              ref={arrowProps.ref}
                              style={arrowProps.style}
                              data-placement={placement}
                          />
                      )}*/}
                </animated.div>
              );
            }}
          </Popper>,
          portalTarget,
        )
      }
    </DefaultPopperTransition>
  );
};

const DefaultPopperTransition = ({
  native = true,
  visible,
  children,
}: {
  native?: boolean;
  visible: boolean;
  children: ({
    transitionStyle,
  }: {
    transitionStyle: { top: number; opacity: number };
  }) => ReactNode;
}) => (
  <Transition
    native={native}
    items={visible}
    from={{ opacity: 0, top: -20 }}
    enter={{ opacity: 1, top: 0 }}
    leave={{ opacity: 0, top: -20 }}
  >
    {(transitionStyle: any, item) => {
      return item && children({ transitionStyle });
    }}
  </Transition>
);
