/** @jsx jsx */
import { jsx } from '@emotion/core';

import { SvgProps } from '../../../components/svg/SvgUtils';
import { ShColors } from '@shoootin/design-tokens';

const ArchitectureSvg = ({ width = 40, color = 'black' }: SvgProps) => (
  <svg
    viewBox="0 0 40 40"
    height={width}
    width={width}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    stroke={ShColors[color]}
    css={{
      fill: 'none !important',
    }}
  >
    <line id="ground" x1={3.75} y1={38.75} x2={36.25} y2={38.75} />
    <g id="tree">
      <line x1={33.75} y1={35.5} x2={33.75} y2={38.75} />
      <circle cx={33.75} cy={33.5} r={2} />
      <circle cx={33.75} cy={29.5} r={1.5} />
    </g>
    <g id="tree-2" data-name="tree">
      <line x1={6.25} y1={35.5} x2={6.25} y2={38.75} />
      <circle cx={6.25} cy={33.5} r={2} />
      <circle cx={6.25} cy={29.5} r={1.5} />
    </g>
    <g id="right">
      <polyline
        id="bulding"
        points="30.25 38.75 30.25 26.75 20.25 26.75 20.25 38.75"
      />
      <path fill="none" id="door" d="M22.75,38.75v-5a2.5,2.5,0,0,1,5,0v5" />
      <polyline
        id="roof"
        points="28.25 26.75 28.25 14.75 20.25 19.75 20.25 26.75"
      />
      <g id="windows">
        <line x1={22.25} y1={24.25} x2={22.25} y2={22.25} />
        <line x1={24.25} y1={24.25} x2={24.25} y2={22.25} />
        <line x1={26.25} y1={24.25} x2={26.25} y2={22.25} />
      </g>
    </g>
    <g id="left">
      <polyline
        id="bulding-2"
        points="20.25 38.75 20.25 16.25 10.25 10.75 10.25 38.75"
      />
      <g id="windows-2">
        <line x1={12.75} y1={20.25} x2={12.75} y2={18.25} />
        <line x1={15.25} y1={20.25} x2={15.25} y2={18.25} />
        <line x1={17.75} y1={20.25} x2={17.75} y2={18.25} />
        <line x1={12.75} y1={27.25} x2={12.75} y2={25.25} />
        <line x1={15.25} y1={27.25} x2={15.25} y2={25.25} />
        <line x1={17.75} y1={27.25} x2={17.75} y2={25.25} />
        <line x1={12.75} y1={34.25} x2={12.75} y2={32.25} />
        <line x1={15.25} y1={34.25} x2={15.25} y2={32.25} />
        <line x1={17.75} y1={34.25} x2={17.75} y2={32.25} />
      </g>
      <polyline
        id="roof-2"
        points="18.75 15.25 18.75 2.25 10.25 7.25 10.25 10.75"
      />
    </g>
  </svg>
);

export default ArchitectureSvg;
