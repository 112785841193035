/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ReactNode } from 'react';
import {
  ShInputRadio,
  ShInputRadioSize,
  ShInputRadioSizes,
} from '../../../designSystem/primitives/input/ShInputRadio/ShInputRadio';
import { ShService } from '@shoootin/config';
import { inlineCheckboxLabelStyle } from '../ShInputUtils';
import { ShMediaQueries } from '@shoootin/design-tokens';

export type ShRadioFieldProps = {
  value: boolean;
  onChange?: (value: boolean) => void;
  label?: string | ReactNode;
  service?: ShService;
  bold?: boolean;
  size?: ShInputRadioSize;
  invert?: boolean;
};

export const ShRadioField = ({
  value,
  label,
  onChange,
  service,
  bold = false,
  size = 'm',
  invert = false,
}: ShRadioFieldProps) => {
  return (
    <label
      onClick={() => onChange && onChange(!value)}
      css={inlineCheckboxLabelStyle(bold)}
    >
      <div
        css={{
          marginRight: `${Math.trunc(ShInputRadioSizes[size] / 1.5)}px`,
          [ShMediaQueries.ShMobileOnly]: { marginRight: 0 },
        }}
      >
        <ShInputRadio
          value={value}
          service={service}
          size={size}
          invert={invert}
        />
      </div>
      <div
        css={{
          fontSize: `${Math.trunc(ShInputRadioSizes[size] / 1.875)}px`,
          [ShMediaQueries.ShMobileOnly]: { fontSize: 14 },
        }}
      >
        {label}
      </div>
    </label>
  );
};
