/** @jsx jsx */
import { CSSObject, jsx } from '@emotion/core';
import { ReactNode } from 'react';

import {
  ShInputCheckbox,
  ShInputCheckboxLabelSizes,
  ShInputCheckboxSize,
  ShInputCheckboxSizes,
} from '../../../designSystem/primitives/input/ShInputCheckbox/ShInputCheckbox';
import { ShService } from '@shoootin/config';
import { inlineCheckboxLabelStyle } from '../ShInputUtils';
import { ShMediaQueries } from '@shoootin/design-tokens';

export type ShCheckboxFieldProps = {
  value: boolean;
  onChange?: (value: boolean) => void;
  label?: string | ReactNode;
  service?: ShService;
  bold?: boolean;
  size?: ShInputCheckboxSize;
  style?: CSSObject;
};

export const ShCheckboxField = ({
  value = false,
  onChange,
  label,
  service,
  bold = false,
  size = 'm',
  style = undefined,
}: ShCheckboxFieldProps) => {
  return (
    <label
      onClick={() => onChange && onChange(!value)}
      css={{
        ...inlineCheckboxLabelStyle(bold),
        ...(!!style && style),
      }}
    >
      <div
        css={{
          marginRight: `${Math.trunc(ShInputCheckboxSizes[size] / 1.2)}px`,
          [ShMediaQueries.ShMobileOnly]: { marginRight: 0 },
        }}
      >
        <ShInputCheckbox value={value} service={service} size={size} />
      </div>
      <div
        className={'ShCheckBoxFieldLabel'}
        css={{
          fontSize: ShInputCheckboxLabelSizes[size],
          [ShMediaQueries.ShMobileOnly]: { fontSize: 14 },
        }}
      >
        {label}
      </div>
    </label>
  );
};
