import 'react-dates/initialize';
import {
  DayPickerSingleDateController,
  isInclusivelyAfterDay,
} from 'react-dates';
import './ShDatePickerStyles'; // shoootin react-dates styles

import React from 'react';
import { ShMoment, ShMomentType } from '@shoootin/utils';

import { ShSvgArrowNext, ShSvgArrowPrev } from '@shoootin/components-web';
import { Moment } from 'moment';
import { ShLocale } from '@shoootin/config';

const navPrev = (
  <span className="prev">
    <ShSvgArrowPrev />
  </span>
);
const navNext = (
  <span className="next">
    <ShSvgArrowNext />
  </span>
);

const renderMonthElement: React.ComponentProps<
  typeof DayPickerSingleDateController
>['renderMonthElement'] = (date) => {
  return (
    <div className="nav">
      <span className="title month">{date.month.format('MMMM')}</span>
      <span className="title year">{date.month.year()}</span>
    </div>
  );
};

export const ShDatePicker = ({
  date,
  onDateChange,
  isDayBlocked,
  firstAvailableDate,
}: {
  date: Moment | null;
  onDateChange: (date: Moment) => void;
  isDayBlocked?: (day: Moment) => boolean;
  firstAvailableDate?: Moment;
}) => {
  return (
    <div className="DayPickerSingle">
      <DayPickerSingleDateController
        // daySize={daySize} //removed as we use css to handle responsive
        isDayBlocked={(day) => (isDayBlocked ? isDayBlocked(day) : false)}
        numberOfMonths={1}
        firstDayOfWeek={1}
        date={date ? date : null}
        focused
        hideKeyboardShortcutsPanel
        isOutsideRange={(day) =>
          firstAvailableDate
            ? !isInclusivelyAfterDay(day, firstAvailableDate)
            : false
        }
        onDateChange={(newDate) => newDate && onDateChange(newDate)}
        onFocusChange={() => {}}
        noBorder={true}
        navPrev={navPrev}
        navNext={navNext}
        renderMonthElement={renderMonthElement}
      />
    </div>
  );
};
