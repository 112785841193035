/** @jsx jsx */
import { jsx } from '@emotion/core';

import React from 'react';

import { SvgProps } from '../SvgUtils';
import { ShColors } from '@shoootin/design-tokens';

const PortraitSvg = ({ width = 40, color = 'black' }: SvgProps) => (
  <svg
    viewBox="0 0 480 480"
    height={width}
    width={width}
    fill={ShColors[color]}
    strokeLinecap="round"
    strokeLinejoin="round"
    stroke={ShColors[color]}
  >
    <path d="M158.801,223.903c2.594,9.058,10.898,32.088,31.182,46.224l-0.3,15.949c-19.329,6.437-74.239,25.182-97.5,37.935
				c-2.087,1.144-3.697,2.995-4.541,5.22c-0.788,2.075-19.2,51.253-11.157,88.098c1.003,4.593,5.069,7.867,9.77,7.867h307.266
				c4.699,0,8.764-3.271,9.769-7.861c8.04-36.721-8.996-85.777-9.725-87.847c-0.817-2.32-2.461-4.258-4.617-5.443
				c-23.061-12.669-74.724-31.526-93.284-38.151v-16.052c14.154-14.143,23.933-37.174,27.071-45.224
				c6.943-5.642,25.121-22.62,25.121-45.726c0-16.123-7.03-24.187-15.279-26.985c1.671-18.179,1.728-54.269-19.256-79.817
				c-14.985-18.245-37.465-27.496-66.813-27.496H234.16c-29.349,0-51.804,9.25-66.743,27.493
				c-20.82,25.426-20.763,61.295-19.061,79.569c-8.02,2.698-14.887,10.619-14.887,26.563
				C133.47,200.891,151.386,217.854,158.801,223.903z M318.323,315.551c19.813,7.506,43.223,16.854,57.553,24.199
				c3.205,10.293,11.674,40.538,9.114,65.445H94.817c-2.393-24.93,6.88-55.266,10.302-65.393
				c12.283-6.221,31.848-13.86,50.242-20.576c8.363,8.724,36.747,35.034,76.216,36.632c1.262,0.051,2.522,0.077,3.78,0.077
				c28.445,0,55.919-13.06,81.718-38.861C317.549,316.599,317.964,316.088,318.323,315.551z M154.843,170.583
				c0.075-0.013,0.197-0.027,0.375-0.027c0.449,0,0.889,0.089,1.115,0.147c3.387,1.435,7.058,0.793,9.931-1.52
				c2.905-2.34,4.023-6.202,3.288-9.859c-0.094-0.468-9.151-47.101,13.341-74.568c10.956-13.38,28.206-20.164,51.269-20.164h12.346
				c23.042,0,40.305,6.773,51.31,20.132c22.517,27.332,13.704,74.174,13.615,74.629c-0.709,3.579,0.34,7.35,3.135,9.694
				c2.763,2.317,6.315,3.052,9.681,1.793c0.183-0.039,0.578-0.11,1.043-0.11c0.182,0,0.791,0.015,0.976,0.204
				c0.59,0.604,1.589,2.958,1.589,7.956c0,17.479-19.057,31.241-19.222,31.359c-1.697,1.19-2.985,2.885-3.68,4.838
				c-2.975,8.357-13.4,32.326-25.739,42.739c-2.251,1.9-3.55,4.696-3.55,7.642v27.492c0,4.253,2.69,8.041,6.705,9.442
				c0.204,0.071,6.25,2.186,15.363,5.503c-21.168,19.408-43.061,28.827-65.175,27.974c-25.05-0.956-45.262-14.919-56.019-24.192
				c14.874-5.17,25.816-8.734,26.1-8.827c4.055-1.317,6.829-5.06,6.909-9.323l0.539-28.675c0.069-3.679-1.888-7.099-5.094-8.903
				c-21.355-12.015-27.524-39.679-27.578-39.927c-0.518-2.481-1.985-4.692-4.057-6.151c-0.199-0.14-19.885-14.206-19.885-31.666
				C153.47,172.78,154.578,170.943,154.843,170.583z"/>
    <path d="M39.993,69.998c5.523,0,10-4.477,10-10V20h40.006c5.523,0,10-4.477,10-10s-4.477-10-10-10H39.993
				c-5.523,0-10,4.477-10,10v49.998C29.993,65.521,34.47,69.998,39.993,69.998z"/>
    <path d="M440.007,0h-50.006c-5.523,0-10,4.477-10,10s4.477,10,10,10h40.006v39.998c0,5.523,4.477,10,10,10s10-4.477,10-10V10
				C450.007,4.477,445.53,0,440.007,0z"/>
    <path d="M89.999,460H49.993v-39.998c0-5.523-4.477-10-10-10c-5.523,0-10,4.477-10,10V470c0,5.523,4.477,10,10,10h50.006
				c5.523,0,10-4.477,10-10S95.522,460,89.999,460z"/>
    <path d="M440.007,410.002c-5.523,0-10,4.477-10,10V460h-40.006c-5.523,0-10,4.477-10,10s4.477,10,10,10h50.006
				c5.523,0,10-4.477,10-10v-49.998C450.007,414.479,445.53,410.002,440.007,410.002z"/>
  </svg>
);

export default PortraitSvg;
