/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FocusEventHandler, ReactNode } from 'react';
import { darken } from 'polished';
import { clamp } from 'lodash';

import {
  ShColor,
  ShColors,
  ShFontWeights,
  ShRadius,
  ShServiceColorPalettes,
} from '@shoootin/design-tokens';
import { ShStyles } from '../../../constants/ShStyles';
import RemoveSvg from '../../../../components/svg/picto/RemoveSvg';
import AddSvg from '../../../../components/svg/picto/AddSvg';
import { ShService } from '@shoootin/config';

export type ShInputNumberRangeProps = {
  onChange?: (value: number) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  value: number;
  min?: number;
  max?: number;
  step?: number;
  error?: ReactNode;
  service?: ShService;
};

export const ShInputNumberRange = ({
  onChange,
  onBlur,
  value = 0,
  min = 0,
  max = 100,
  step = 1,
  error,
  service,
}: ShInputNumberRangeProps) => {
  const serviceColor: ShColor = service
    ? ShServiceColorPalettes[service].dark1
    : 'base';

  const emitChange = (newValue: number) => {
    const clampedValue = clamp(newValue, min, max);
    if (!isNaN(clampedValue) && clampedValue !== value) {
      onChange && onChange(clampedValue);
    }
  };

  return (
    <div
      css={{
        fontSize: '16px',
        position: 'relative',
        textAlign: 'left',
        userSelect: 'none',
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ShQuantityButton
          onClick={() => emitChange(value - step)}
          newValue={value - step}
          positive={false}
          serviceColor={serviceColor}
        />
        <input
          onChange={(e) => {
            const stringValue = e.target.value;
            if (!stringValue) {
              emitChange(min || 0);
            } else {
              emitChange(parseInt(stringValue));
            }
          }}
          onBlur={onBlur}
          type="number"
          min={min}
          max={max}
          value={`${value}`}
          css={{
            textAlign: 'center',
            display: 'block',
            width: '87.5px',
            padding: '18px 20px',
            border: 'none',
            borderRadius: ShRadius.m,
            backgroundColor: ShColors.whiteD,
            borderColor: ShColors.whiteD,
            boxSizing: 'border-box',
            color: ShColors.black,
            fontFamily: 'Montserrat, Verdana, sans-serif',
            fontSize: '14px',
            marginRight: '20px',
            marginLeft: '20px',
            lineHeight: 1.2,

            '&:focus': {
              outline: 'none',
            },

            '&::placeholder': {
              color: ShColors.placeholder,
            },

            '&::-webkit-inner-spin-button,  &::-webkit-outer-spin-button': {
              WebkitShearance: 'none',
              MozShearance: 'none',
              Shearance: 'none',
              margin: 0,
            },
            // [ShMediaQueries.ShMobileOnly]: { flexGrow: 0.5 },
          }}
        />
        <ShQuantityButton
          onClick={() => emitChange(value + step)}
          newValue={value + step}
          positive={true}
          serviceColor={serviceColor}
        />
        {error && (
          <small
            css={{
              margin: 0,
              fontSize: '12px',
              fontFamily: 'Montserrat,Verdana,sans-serif',
              fontWeight: ShFontWeights.m,
              maxWidth: '432px',
              color: ShColors.error,
              lineHeight: 1.5,
              marginLeft: '20px',
              display: 'block',
            }}
          >
            {error}
          </small>
        )}
      </div>
    </div>
  );
};

type ShQuantityButtonProps = {
  onClick: (value: number) => void;
  newValue: number;
  positive: boolean;
  serviceColor: ShColor;
};

export const ShQuantityButton = ({
  onClick,
  newValue,
  positive,
  serviceColor,
}: ShQuantityButtonProps) => {
  return (
    <div
      onClick={() => onClick(newValue)}
      css={{
        ...ShStyles.centered,
        position: 'relative',
        height: '32px',
        width: '32px',
        borderRadius: '16px',
        backgroundColor: ShColors[serviceColor],
        strokeWidth: 2,
        cursor: 'pointer',
        content: '""',
        opacity: 1,
        transition: `stroke-dasharray 100ms cubic-bezier(0.4, 0.0, 0.2, 1),
                        stroke-dashoffset 100ms cubic-bezier(0.4, 0.0, 0.2, 1),
                        background-color 100ms cubic-bezier(0.4, 0.0, 0.2, 1)`,

        '&:active': {
          backgroundColor: darken(0.03, ShColors[serviceColor]),
        },

        '&:hover': {
          opacity: 0.75,
        },
      }}
    >
      {positive ? <AddSvg color="white" /> : <RemoveSvg color="white" />}
    </div>
  );
};
