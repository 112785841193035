import { injectGlobal } from 'emotion';
import { ShColors, ShMediaQueries } from '@shoootin/design-tokens';

// const Breakpoints = [375,767,992, 1200];

export const ShStylesFunc = {
  rem: (value: number) => `${value / 16}rem`,
  spacing: (ratio: number = 1) => `${1.5 * ratio}rem`,
  fontSize: (size: number) => `${size / 16}rem`,
};

export const ShStyles = {
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    pointerEvents: 'none',
  },
  //TODO need to overwrite old client account css
  label: {
    display: 'block',
    marginBottom: 10,
    textTransform: 'unset',
    fontSize: 16,
    color: ShColors.black,
    fontWeight: 'unset',
    [ShMediaQueries.ShMobileOnly]: {
      fontSize: 14,
    },
  },

  //TODO: inject font from locals files > /src/fonts/*
  injectFonts: () => injectGlobal`
    @font-face {
      font-family: "League Spartan";
      src: url("//db.onlinewebfonts.com/t/7cc8cbbd7a330c0d1e7c08c6d62711e4.eot");
      src: url("//db.onlinewebfonts.com/t/7cc8cbbd7a330c0d1e7c08c6d62711e4.eot?#iefix") format("embedded-opentype"),
      url("//db.onlinewebfonts.com/t/7cc8cbbd7a330c0d1e7c08c6d62711e4.woff2") format("woff2"),
      url("//db.onlinewebfonts.com/t/7cc8cbbd7a330c0d1e7c08c6d62711e4.woff") format("woff"),
      url("//db.onlinewebfonts.com/t/7cc8cbbd7a330c0d1e7c08c6d62711e4.ttf") format("truetype"),
      url("//db.onlinewebfonts.com/t/7cc8cbbd7a330c0d1e7c08c6d62711e4.svg#League Spartan") format("svg"); 
    }
    
    @font-face {
      font-family: "Montserrat";
      src: url("//db.onlinewebfonts.com/t/5fc6f4fd75e944a52a342ab2c7f100f1.eot");
      src: url("//db.onlinewebfonts.com/t/5fc6f4fd75e944a52a342ab2c7f100f1.eot?#iefix") format("embedded-opentype"),
      url("//db.onlinewebfonts.com/t/5fc6f4fd75e944a52a342ab2c7f100f1.woff2") format("woff2"),
      url("//db.onlinewebfonts.com/t/5fc6f4fd75e944a52a342ab2c7f100f1.woff") format("woff"),
      url("//db.onlinewebfonts.com/t/5fc6f4fd75e944a52a342ab2c7f100f1.ttf") format("truetype"),
      url("//db.onlinewebfonts.com/t/5fc6f4fd75e944a52a342ab2c7f100f1.svg#Montserrat") format("svg");
    }`,
} as const;
