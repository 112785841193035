/** @jsx jsx */
import { jsx } from '@emotion/core';
import { keyframes } from '@emotion/core';
import { ShColor, ShColors, ShRadius } from '@shoootin/design-tokens';
import { ShStyles } from '../../../../designSystem/constants/ShStyles';

// TODO this is a quick and dirty copy of https://github.com/dmitrymorozoff/react-spinners-kit/blob/master/src/components/swap/index.js
// Cleanup this file when time permits

const animationLeftPostions = (props: any): any => {
  switch (props.index) {
    case 0:
      return {
        x: props.size - props.size / 4,
        y: props.y,
      };
    case 1:
      return {
        x: props.x,
        y: props.y - props.size / 2 + props.size / 8,
      };
    case 2:
      return {
        x: 0,
        y: props.y,
      };
  }
};

const motion = (props: any) => keyframes`
    0% {
        top: ${props.y}px;
        left: ${props.x}px;
    }
    50%{
        top: ${animationLeftPostions(props).y}px;
        left: ${animationLeftPostions(props).x}px;
    }
    100% {
        top: ${props.y}px;
        left: ${props.x}px;
    }
`;

type getBallsType = {
  countBalls: number;
  color: ShColor;
  size: 30 | 40 | 60 | 90 | 120;
  sizeUnit: string;
};

const getBalls = ({ countBalls, color, size, sizeUnit }: getBallsType) => {
  const balls = [];
  const ballColor = ShColors[color];

  let y = size / 2 - size / 8;
  let x;
  for (let index = 0; index < countBalls; index++) {
    x = index * (size / 4 + size / 8);
    balls.push(
      <div
        key={index.toString()}
        css={{
          position: 'absolute',
          top: `${y}${sizeUnit}`,
          left: `${x}${sizeUnit}`,
          width: `${size / 4}${sizeUnit}`,
          height: `${size / 4}${sizeUnit}`,
          borderRadius: ShRadius.half,
          backgroundColor: ballColor,
          animation:
            motion({
              x: x,
              y: y,
              index: index,
              size: size,
            }) + ' 1.5s cubic-bezier(0.165, 0.84, 0.44, 1) infinite',
          nthOfType: {
            animationTimingFunction: 'cubic-bezier(1, 0, 0, 1)',
            animationDuration: '1.5s',
          },
        }}
      />,
    );
  }
  return balls;
};

export const SpinnerSize = {
  xs: 30,
  s: 40,
  m: 60,
  //l: 90,
  //xl: 120,
} as const;

export type ShSpinnerSize = keyof typeof SpinnerSize;
export const ShSpinnerSizeList = Object.keys(SpinnerSize) as ShSpinnerSize[];

export type ShSpinnerProps = {
  size?: ShSpinnerSize;
  color?: ShColor;
};
export const ShSpinner = ({ size = 'm', color = 'base' }: ShSpinnerProps) => {
  const countBalls = 3;
  const sizeProps = {
    size: SpinnerSize[size],
    sizeUnit: 'px',
  };
  return (
    <div
      css={{
        position: 'relative',
        margin: '0 auto',
        width: `${sizeProps.size}${sizeProps.sizeUnit}`,
        height: `${sizeProps.size / 2 + sizeProps.size / 8}${
          sizeProps.sizeUnit
        }`,
        ...ShStyles.centered,
      }}
    >
      {getBalls({ countBalls, color, ...sizeProps })}
    </div>
  );
};
