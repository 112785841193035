/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ReactNode } from 'react';

import {
  ShButtonVariant,
  getShButtonStyle,
  ShButtonSize,
  useShButtonVariant,
  useShButtonSize,
  ShButtonVariantConfigs,
} from '../ShButton/ShButton';
import { ShSpinnerOverlay } from '../../spinner/ShSpinnerOverlay/ShSpinnerOverlay';

export type ShButtonLinkProps = {
  href: string;
  children?: ReactNode;
  theme?: ShButtonVariant;
  size?: ShButtonSize;
  disabled?: boolean;
  loading?: boolean;
  target?: '_blank';
};

export const ShButtonLink = ({
  href,
  children,
  theme: themeProp,
  size: sizeProp,
  disabled = false,
  loading = false,
  target,
}: ShButtonLinkProps) => {
  const theme = useShButtonVariant(themeProp);
  const size = useShButtonSize(sizeProp);
  return (
    <a
      href={href}
      target={target}
      css={{
        textDecoration: 'none',
        display: 'inline-block',
        ...(disabled && {
          pointerEvents: 'none',
          cursor: 'default',
        }),
      }}
    >
      <div css={getShButtonStyle({ theme, size, disabled, loading })}>
        <ShSpinnerOverlay
          visible={loading ? loading : false}
          size="xs"
          color={ShButtonVariantConfigs[theme].spinnerColor}
        />
        {children}
      </div>
    </a>
  );
};
