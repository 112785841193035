/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import { SvgProps } from '../../../components/svg/SvgUtils';
import { ShColors } from '@shoootin/design-tokens';

const RestaurantSvg = ({ width = 40, color = 'black' }: SvgProps) => (
  <svg
    viewBox="0 0 40 40"
    height={width}
    width={width}
    fill={ShColors[color]}
    strokeLinecap="round"
    strokeLinejoin="round"
    stroke={ShColors[color]}
  >
    <path d="M6.2256,36.8292h4.9792a.4345.4345,0,0,0,.4568-.4633V32.8085a.4592.4592,0,0,0-.4568-.4614H6.2256a.4341.4341,0,0,0-.4578.4614v3.5574A.4352.4352,0,0,0,6.2256,36.8292ZM6.6808,33.27h4.068v2.6347H6.6808Zm6.26,3.5588h4.9818a.4338.4338,0,0,0,.4552-.4633V32.8085a.5377.5377,0,0,0-.5022-.4614H12.9406a.4324.4324,0,0,0-.4551.4614v3.5574A.4336.4336,0,0,0,12.9406,36.8292Zm.4118-3.5588h4.0659v2.6347H13.3524Zm6.2138,3.5588h4.98a.4344.4344,0,0,0,.4561-.4633V32.8085a.492.492,0,0,0-.4561-.4614h-4.98a.4334.4334,0,0,0-.4571.4614v3.5574A.4346.4346,0,0,0,19.5662,36.8292ZM20.024,33.27h4.0649v2.6347H20.024Zm16.31-26.5236V4.2528a.4346.4346,0,0,0-.456-.4631H4.1221a.4329.4329,0,0,0-.4549.4631v2.494L2.7983,15.296a.527.527,0,0,0,.1371.369.4874.4874,0,0,0,.3189.1394h.4129V38.5387A.4324.4324,0,0,0,4.1221,39H35.8779a.4341.4341,0,0,0,.456-.4613V15.7579h.411a.3567.3567,0,0,0,.32-.1389.9136.9136,0,0,0,.1363-.3689ZM4.5792,4.7136H35.4659V6.239H4.5792ZM26.1907,38.03H4.5792V31.1459H26.1907Zm0-7.8089H20.936V28.2346h2.3312a.4626.4626,0,0,0,0-.9251H17.6929a.4626.4626,0,0,0,0,.9251H20.024v1.9862H10.5645V28.2346h2.33a.4626.4626,0,0,0,0-.9251H7.3231a.4626.4626,0,0,0,0,.9251H9.6522v1.9862H4.5792V15.7579H26.1907Zm9.23,7.8089H27.1046V15.7579h8.3162ZM3.7573,14.8328,4.535,7.1623H35.4659l.7768,7.6705ZM28.9318,28.28a.5082.5082,0,1,0-.5022-.5084A.5053.5053,0,0,0,28.9318,28.28Z" />
  </svg>
);

export default RestaurantSvg;
