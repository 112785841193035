import { Optional } from 'utility-types';

import { ShBillingEntityDTO } from '@shoootin/api';

export type ShBillingEntityFormValues = Omit<
  Optional<ShBillingEntityDTO, 'id'>,
  'complete' | 'companyValue' | 'users'
>;

export const ShBillingEntityFormInitialValues: ShBillingEntityFormValues = {
  id: undefined,
  name: '',
  defaultEntity: false,
  company: '',
  department: '',
  address: '',
  companyId: '',
  taxNumber: '',
  extraInfos: '',
  countryCode: 'FR',
  emails: [''],
  paymentDelay: 7,
  specialInvoicing: false,
};
