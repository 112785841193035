/** @jsx jsx */
import { jsx } from '@emotion/core';

import React from 'react';
import { SvgProps } from '../../../components/svg/SvgUtils';
import { ShColors } from '@shoootin/design-tokens';

const RemoveSvg = ({ width = 24, color = 'black' }: SvgProps) => (
  <svg
    viewBox="0 0 19 19"
    height={width * (2 / 3)}
    width={width}
    fill="none"
    stroke={ShColors[color]}
  >
    <line x1={4.5} y1={9.5} x2={15.5} y2={9.5} />
  </svg>
);

export default RemoveSvg;
