import Swal from 'sweetalert2';
// import 'sweetalert2/dist/sweetalert2.min.css';

import { ShColors } from '@shoootin/design-tokens';

type BaseFeedbackProps = {
  title?: string;
  message?: string;
};

const showSuccess = (
  { title, message }: BaseFeedbackProps = {
    title: 'Operation succeeded',
    message: 'Everything went fine!',
  },
) => {
  return Swal.fire(title, message, 'success');
};

const showError = (
  { title, message }: BaseFeedbackProps = {
    title: 'An error occured',
    message: 'Oups, an unknown error occured during this operation',
  },
) => {
  return Swal.fire(title, message, 'error');
};

const askForConfirmation = (
  { title, text, cancelButtonText, confirmButtonText } = {
    title: 'Are you sure ?',
    text: "You won't be able to revert this !",
    cancelButtonText: 'No',
    confirmButtonText: 'Yes',
  },
): Promise<boolean> => {
  return Swal.fire({
    title,
    text,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: ShColors.base,
    cancelButtonColor: ShColors.error,
    confirmButtonText,
    cancelButtonText,
  }).then((result) => {
    return !!result.value;
  });
};

export const ShSweetAlert = {
  showSuccess,
  showError,
  askForConfirmation,
};
