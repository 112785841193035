/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useMemo, FocusEventHandler, useState } from 'react';
import {
  ShInputAutocomplete,
  ShInputAutocompleteOption,
} from './../../designSystem/primitives/input/ShInputAutocomplete/ShInputAutocomplete';
import { useAsync } from 'react-async-hook';
import { ShCountry } from '@shoootin/config';
import {
  ShFormikFieldError,
  useShFormikField,
} from '../../components/formik/ShFormikComponents';
import { getGoogleMapsPredictions } from '@shoootin/utils';
import { ShInputSize } from '../../designSystem/primitives/input/ShInputTheme';

type GetSuggestionsParams = {
  text: string;
  country: ShCountry;
};

const getGoogleMapsSDKSuggestions = async (
  params: GetSuggestionsParams,
): Promise<ShAddressAutocompleteSuggestion[]> => {
  const predictions = await getGoogleMapsPredictions(params);
  return predictions.map((prediction) => ({
    placeId: prediction.place_id,
    text: prediction.description,
    mainText: prediction.structured_formatting.main_text,
    secondaryText: prediction.structured_formatting.secondary_text,
  }));
};

export type ShAddressAutocompleteSuggestion = {
  placeId: string;
  text: string;
  mainText: string;
  secondaryText: string;
};

const AddressSuggestion = ({
  suggestion,
}: {
  suggestion: ShAddressAutocompleteSuggestion;
}) => (
  <div>
    <div
      css={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {suggestion.mainText}
    </div>
    <div
      css={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {suggestion.secondaryText}
    </div>
  </div>
);

export type ShAddressAutocompleteOption = ShInputAutocompleteOption & {
  suggestion: ShAddressAutocompleteSuggestion;
};

export type ShAddressAutocompleteProps = {
  allowFreeText?: boolean;
  className?: string;
  value: string;
  onChange: (inputValue: string, option?: ShAddressAutocompleteOption) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  name?: string;
  required?: boolean;
  placeholder?: string;
  minLengthForSuggestions?: number;
  country: ShCountry;
  getSuggestions?: (
    params: GetSuggestionsParams,
  ) => Promise<ShAddressAutocompleteSuggestion[]>;
  themeSize?: ShInputSize;
  error?: string | null;
};

export const ShAddressAutocomplete = ({
  // allowFreeText=true: by default we don't want user to be blocked if suggestions do not show his address
  allowFreeText = true,
  className,
  value,
  onChange,
  onBlur,
  name,
  required,
  placeholder,
  country,
  minLengthForSuggestions = 1,
  themeSize,
  error,
  // TODO from now we default to getting suggestions from the SDK
  // but this SDK is quite annoying to load...
  // if we could just call an API without having to load the SDK in the future it would be more convenient/portable!
  // Unfortunately the API seems to protect against calls with missing CORS headers
  // see https://stackoverflow.com/questions/42180788/how-to-use-cors-to-implement-javascript-google-places-api-request
  // maybe we should make all autocomplete requests go through the backend?
  getSuggestions = getGoogleMapsSDKSuggestions,
}: ShAddressAutocompleteProps) => {
  const isTooSmallForSuggestions = (t: string) =>
    t.length < minLengthForSuggestions;

  const suggestionsAsync = useAsync(
    async () => {
      if (isTooSmallForSuggestions(value)) {
        return;
      } else {
        return getSuggestions({ text: value, country });
      }
    },
    [value],
    {
      // preserve previous result when typing
      setLoading: (state) => ({ ...state, loading: true, status: 'loading' }),
    },
  );

  const options: ShAddressAutocompleteOption[] | undefined = useMemo(() => {
    return suggestionsAsync.result?.map((suggestion) => {
      return {
        value: suggestion.text,
        content: <AddressSuggestion suggestion={suggestion} />,
        suggestion,
      };
    });
  }, [suggestionsAsync.result]);

  return (
    <ShInputAutocomplete<ShAddressAutocompleteOption>
      allowFreeText={allowFreeText}
      className={className}
      value={value}
      onChange={(inputValue, option) => {
        onChange(inputValue, option);
      }}
      options={options}
      onBlur={onBlur}
      name={name}
      required={required}
      placeholder={placeholder}
      isLoading={suggestionsAsync.loading}
      themeSize={themeSize}
      error={error}
    />
  );
};

export const ShAddressAutocompleteFormik = <
  V,
  FieldType extends string = string
>({
  country,
  fieldName,
  label,
  placeholder,
  required = false,
}: {
  country: ShCountry;
  fieldName: keyof V;
  label?: string;
  placeholder?: string;
  required?: boolean;
}) => {
  const [field, meta, helpers] = useShFormikField<V, FieldType>(fieldName);
  return (
    <div css={{ width: '100%' }}>
      {label && <label>{label}</label>}
      <ShAddressAutocomplete
        country={country}
        name={field.name}
        value={field.value}
        onBlur={field.onBlur}
        onChange={(newValue) => helpers.setValue(newValue as FieldType)}
        required={required}
        placeholder={placeholder}
      />
      <ShFormikFieldError meta={meta} />
    </div>
  );
};
