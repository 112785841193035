import {
  ShColors,
  ShEasings,
  ShMediaQueries,
  ShRadius,
} from '@shoootin/design-tokens';
import { transparentize } from 'polished';
import { InterpolationWithTheme } from '@emotion/core';
import { ShNumberRangeField } from '../../input/ShNumberRangeField/ShNumberRangeField';

const rem = (value: number) => `${value / 16}rem`;
const spacing = (ratio: number = 1) => `${1.5 * ratio}rem`;
const fontSize = (size: number) => `${size / 16}rem`;

const mainStyle: InterpolationWithTheme<any> = {
  borderRadius: ShRadius.m,
  position: 'relative',
  zIndex: 0,
  marginTop: '1.25rem',
};

const header: InterpolationWithTheme<any> = {
  position: 'relative',
  zIndex: 0,
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  padding: spacing(0.75),
  borderRadius: ShRadius.m,
  transition: `color 150ms ${ShEasings.ease}`,
  backgroundColor: ShColors.white,
  [ShMediaQueries.ShMobileOnly]: {
    flexDirection: 'row-reverse',
  },
  [ShMediaQueries.ShDesktopsOnly]: {
    padding: `${rem(26)} ${spacing()}`,
  },
};

const headerOpened: InterpolationWithTheme<any> = {
  borderRadius: `${ShRadius.s}px ${ShRadius.s}px 0px 0px`,
};

const panel: InterpolationWithTheme<any> = {
  backgroundColor: ShColors.white,
  overflow: 'hidden',
  transition: `height .25s ${ShEasings.easeIn}`,
  '> *:last-child': {
    paddingBottom: spacing(0.75),
    [ShMediaQueries.ShDesktopsOnly]: {
      paddingBottom: spacing(1.125),
    },
  },
  // '> .ShNumberRangeField': {
  //   borderTop: `1px solid ${ShColors.whiteD}`,
  //   padding: spacing(),
  // },
  // '> .field, > div > .field': {
  //   borderTop: `1px solid ${ShColors.whiteD}`,
  //   padding: spacing(),
  //   '> .field-textarea': {
  //     minHeight: rem(90),
  //   },
  //   // '&.number': {
  //   //   '.field-control, .field-legend': {
  //   //     [ShMediaQueries.ShMobileOnly]: {
  //   //       fontSize: fontSize(14),
  //   //     },
  //   //   },
  //   //   '.field-wrapper': {
  //   //     flexWrap: 'wrap',
  //   //   },
  //   // },
  // },
};

// &__footer {
//   padding: spacing();
//   border-top: 1px solid @c-light-darker;
//
// .field-row > *:last-child {
//     text-align: right;
//   }
//
// .field-row {
//     margin-top: 0
//   }
// }
const footer: InterpolationWithTheme<any> = {
  padding: spacing(),
  borderTop: `1px solid ${ShColors.whiteD}`,
  '.field-row > *:last-child': {
    textAlign: 'right',
  },
  '.field-row': {
    marginTop: 0,
  },
};

export const switchBoxStyles = {
  main: mainStyle,
  mainOpened: { borderRadius: ShRadius.s },
  beforeDiv: {
    borderRadius: 'inherit',
    boxShadow: `0 0 16px 0 ${transparentize(0.8, ShColors.black)}`,
    opacity: 0,
    transition: `opacity 300ms ${ShEasings.easeIn}`,
  },
  beforeDivOpened: {
    opacity: 1,
    transition: `opacity 350ms ${ShEasings.easeOut}`,
  },
  afterDiv: {
    transition: `opacity 300ms ${ShEasings.easeOut}`,
    boxShadow: `0 0 0 2px ${ShColors.pattern}`,
    borderRadius: 'inherit',
  },
  afterDivOpened: {
    opacity: 0,
    transition: `opacity 350ms ${ShEasings.easeOut}`,
  },
  header: header,
  headerOpened: headerOpened,
  beforeDivHeader: {
    content: '""',
    zIndex: -1,
    transform: 'translateX(calc(-100% + 3.5px))',
    transition: `transform 250ms ${ShEasings.easeIn}`,
    overflow: 'hidden',
  },
  beforeDivHeaderOpened: {
    transform: 'translateX(0)',
    transition: `transform 200ms ${ShEasings.easeOut}`,
  },
  panel: panel,
  panelOpened: {
    transition: `height .2s ${ShEasings.easeOut}`,
  },
  footer,
};
