/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  ShBackendAssets,
  ShCountries,
  ShCountry,
  ShCountryConfigs,
} from '@shoootin/config';
import { ShColors, ShFonts } from '@shoootin/design-tokens';
import { ShInputSelectComponents } from '@shoootin/components-web';
import { ShInputSelect } from '../../designSystem/primitives/input/ShInputSelect/ShInputSelect';
import { ShInputSize } from '../../designSystem/primitives/input/ShInputTheme';

const SelectComponents: ShInputSelectComponents<ShCountry | undefined> = {
  Option: (props) => {
    // __DEV__ && console.debug('Option props', props);
    const { label, innerRef, innerProps, isSelected, isFocused } = props;
    const value = (props as any).value; // TODO why is this not available in types???
    return (
      <div
        ref={innerRef}
        {...innerProps}
        css={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: isSelected ? ShColors.whiteD : ShColors.white,
          fontFamily:
            isSelected || isFocused ? ShFonts.secondary : ShFonts.primary,
          ':hover': {
            backgroundColor: ShColors.whiteD,
          },
        }}
      >
        <div
          css={{
            marginLeft: 15,
            flex: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={ShBackendAssets.countryFlag(value)}
            css={{ width: 25, objectFit: 'contain' }}
          />
        </div>
        <span
          css={{
            flex: 1,
            display: 'block',
            position: 'relative',
            cursor: 'pointer',
            padding: 15,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {label}
        </span>
      </div>
    );
  },

  SingleValue: (props) => {
    const { children, innerProps, selectProps } = props;
    // __DEV__ && console.debug('SingleValue props', props);
    return selectProps.menuIsOpen ? (
      <div
        {...innerProps}
        css={{
          display: 'flex',
          flexDirection: 'row',
          maxWidth: 'calc(100% - 8px)',
          overflow: 'hidden',
          position: 'absolute',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {selectProps.placeholder}
      </div>
    ) : (
      <div
        {...innerProps}
        css={{
          display: 'flex',
          flexDirection: 'row',
          maxWidth: 'calc(100% - 8px)',
          overflow: 'hidden',
          position: 'absolute',
        }}
      >
        <div
          css={{
            flex: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {props.data.value && (
            <img
              src={ShBackendAssets.countryFlag(props.data.value)}
              css={{ width: 25, objectFit: 'contain' }}
            />
          )}
        </div>
        <span
          css={{
            flex: 1,
            display: 'block',
            position: 'relative',
            cursor: 'pointer',
            padding: 15,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {children}
        </span>
      </div>
    );
  },
};

export type ShCountrySelectFieldProps = {
  value: ShCountry | undefined;
  placeholder?: string;
  countries?: ReadonlyArray<ShCountry>;
  onChange: (value: ShCountry | undefined) => void;
  size?: ShInputSize;
};
export const ShCountrySelect = ({
  value,
  placeholder = 'Select a country',
  onChange,
  countries = ShCountries,
  size = 'l',
}: ShCountrySelectFieldProps) => {
  const options = countries.map((value) => ({
    value,
    label: ShCountryConfigs[value].label,
  }));

  return (
    <ShInputSelect<ShCountry | undefined>
      value={value}
      placeholder={placeholder}
      options={options}
      components={SelectComponents}
      onChange={onChange}
      size={size}
    />
  );
};
