/** @jsx jsx */
import React, { ReactNode } from 'react';
import { jsx } from '@emotion/core';
import {
  ShColors,
  ShEasings,
  ShFonts,
  ShFontWeights,
  ShRadius,
} from '@shoootin/design-tokens';
import {
  ShButtonSize,
  ShButtonSizeConfigs,
  useShButtonSize,
} from '../ShButton/ShButton';

export type ShButtonGroupItem = {
  className?: string;
  size?: ShButtonSize;
  selected?: boolean;
  children: ReactNode;
  onClick: (e :any) => void;
  disabled?: boolean;
  tooltip?: { for?: string; tip?: string };
};

const DisabledOption = {
  pointerEvents: 'none',
  backgroundColor: ShColors.disabled,
  color: ShColors.white,
  border: 'none',
  //padding: '10.6px 20px 7.4px',
  transition: `background-color 150ms ${ShEasings.easeOut},
                      color 150ms ${ShEasings.easeOut}`,
} as const;

const ShButtonGroupVariantConfigs = {
  default: {
    spinnerColor: 'black',
    buttonCss: {
      color: ShColors.black,
      backgroundColor: ShColors.white,
      '&:hover': {
        color: ShColors.white,
        backgroundColor: ShColors.baseL,
        transition: `transition: background-color .15s ${ShEasings.easeOut},
                      color .15s ${ShEasings.easeOut}`,
      },
      '&.active': {
        color: ShColors.white,
        backgroundColor: ShColors.base,
        transition: `background-color .1s ${ShEasings.ease},
                      color .1s ${ShEasings.ease}`,
      },
    },
  },
};

const ShButtonGroupItem = ({
  children,
  size = 'm',
  onClick,
  selected,
  disabled,
  isFirst,
  isLast,
  tooltip,
}: ShButtonGroupItem & { isFirst: boolean; isLast: boolean }) => (
  <span data-for={tooltip?.for} data-tip={tooltip?.tip}>
    <div
      //disabled={disabled}
      onClick={!disabled ? onClick : undefined}
      css={{
        position: 'relative',
        padding: '10.6px 20px 7.4px',
        borderRadius: 0,
        ...(isFirst && {
          borderTopLeftRadius: ShRadius.m,
          borderBottomLeftRadius: ShRadius.m,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          borderLeft: 'none',
        }),
        ...(isLast && {
          borderRight: 'none',
          borderTopRightRadius: ShRadius.m,
          borderBottomRightRadius: ShRadius.m,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }),
        ...(isFirst &&
          isLast && {
            borderTopRightRadius: ShRadius.m,
            borderBottomRightRadius: ShRadius.m,
            borderTopLeftRadius: ShRadius.m,
            borderBottomLeftRadius: ShRadius.m,
          }),
        outline: 'none',
        border: 'none',
        cursor: 'pointer',
        textAlign: 'center',
        textDecoration: 'none',
        whiteSpace: 'nowrap',
        fontWeight: ShFontWeights.m,
        fontFamily: ShFonts.secondary,
        fontSize: 12,
        lineHeight: 1.1,
        transition: `background-color .1s ${ShEasings.easeIn},
                    color .1s ${ShEasings.easeIn}`,
        ...ShButtonSizeConfigs[size].buttonCss,
        ...(disabled && DisabledOption),
        ...ShButtonGroupVariantConfigs.default.buttonCss,
      }}
      className={selected ? 'active' : ''}
    >
      {children}
    </div>
  </span>
);

export type ShButtonGroupProps = {
  items: ShButtonGroupItem[];
  disabled?: boolean;
  size?: ShButtonSize;
};
export const ShButtonGroup = ({
  items,
  disabled,
  size: sizeProp,
}: ShButtonGroupProps) => {
  const size = useShButtonSize(sizeProp);
  return (
    <div
      css={{
        display: 'inline-flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        listStyleType: 'none',
        margin: 0,
        padding: 0,
        border: `solid thin ${ShColors.whiteD}`,
        borderRadius: ShRadius.m,
        lineHeight: 1.1,
        //background: ShColors.whiteD,
      }}
    >
      {items.map((item, i) => {
        return (
          <div key={i}>
            <ShButtonGroupItem
              {...item}
              size={size}
              disabled={item.disabled ?? disabled}
              isFirst={i === 0}
              isLast={i === items.length - 1}
            />
          </div>
        );
      })}
    </div>
  );
};
