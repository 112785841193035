/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import { ShColors } from '@shoootin/design-tokens';
import {
  SvgVideoMuted,
  SvgVideoPause,
  SvgVideoPlay,
  SvgVideoVolume,
} from './ShVideoIcons';
import { secondsToDuration } from './ShVideoReviewUtils';
import FullscreenSvg from '../../components/svg/icon/FullscreenSvg';
import { FullScreenHandle } from 'react-full-screen';

export const VideoDuration = ({
  currentTime,
  duration,
}: {
  currentTime: string;
  duration: string;
}) => {
  return (
    <div
      css={{
        fontSize: 14,
        justifyContent: 'center',
      }}
    >
      {currentTime} / {duration}
    </div>
  );
};

export const getPosition = (e: React.MouseEvent<HTMLElement>) => {
  const bounds = e.currentTarget.getBoundingClientRect();
  return Math.max(0, Math.min((e.clientX - bounds.left) / bounds.width, 1));
};

export const Volume = ({
  volume,
  setVolume,
  muted,
  setMuted,
}: {
  volume: number;
  setVolume: (v: number) => void;
  muted: boolean;
  setMuted: (m: boolean) => void;
}) => {
  const [hover, setHover] = useState<boolean>(false);

  const height = 6;
  const offset = 2;

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        onClick={() => {
          setMuted(!muted);
        }}
      >
        {!muted ? <SvgVideoVolume /> : <SvgVideoMuted />}
      </div>
      <div
        css={{
          marginLeft: 10,
          width: hover ? 80 : 0,
          height: height,
          backgroundColor: ShColors.blackL,
          transition: 'all 0.2s ease-in-out',
          position: 'relative',
        }}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          setVolume(getPosition(e));
          setMuted(false);
        }}
      >
        <div
          style={{
            width: muted ? 0 : `${Math.min(volume * 100, 100)}%`,
          }}
          css={{
            position: 'absolute',
            height: height,
            backgroundColor: ShColors.base,
            transition: 'all 0.1s ease-in-out',
          }}
        />
        <div
          style={{
            left: muted
              ? 0
              : hover
              ? `calc(${Math.min(volume * 100, 100)}% - ${
                  (height + offset) / 2
                }px)`
              : undefined,
          }}
          css={{
            position: 'absolute',
            left: 0,
            backgroundColor: hover ? ShColors.white : 'transparent',
            height: height + offset,
            width: height + offset,
            borderRadius: '50%',
            top: -offset / 2,
            transition: 'all 0.1s ease-in-out',
          }}
        />
      </div>
    </div>
  );
};

export const VideoActions = ({
  videoPlaying,
  pauseVideo,
  playVideo,
  volume,
  setVolume,
  muted,
  setMuted,
  duration,
  playedSeconds,
  handle,
}: {
  videoPlaying: boolean;
  pauseVideo: () => void;
  playVideo: () => void;
  volume: number;
  setVolume: (volume: number) => void;
  muted: boolean;
  setMuted: (muted: boolean) => void;
  duration: number;
  playedSeconds: number;
  handle: FullScreenHandle;
}) => {
  return (
    <div
      css={{
        padding: 10,
        display: 'flex',
        justifyContent: 'space-between',
        borderTop: `1px solid ${ShColors.blackL}`,
        alignItems: 'center',
        backgroundColor: ShColors.blackLL,
      }}
    >
      <div
        css={{
          display: 'flex',
        }}
      >
        <div
          css={{
            cursor: 'pointer',
            marginRight: 20,
          }}
        >
          {videoPlaying ? (
            <div onClick={pauseVideo}>
              <SvgVideoPause />
            </div>
          ) : (
            <div onClick={playVideo}>
              <SvgVideoPlay />
            </div>
          )}
        </div>
        <Volume
          volume={volume}
          setVolume={setVolume}
          muted={muted}
          setMuted={setMuted}
        />
      </div>
      {duration !== 0 && (
        <VideoDuration
          duration={secondsToDuration(duration)}
          currentTime={secondsToDuration(playedSeconds)}
        />
      )}
      <div>
        <div
          onClick={handle.active ? handle.exit : handle.enter}
          css={{
            cursor: 'pointer',
          }}
        >
          <FullscreenSvg width={20} color={'white'} />
        </div>
      </div>
    </div>
  );
};
