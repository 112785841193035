import { useEffect, useState } from 'react';
import { memoize } from 'lodash';

type WindowStripe = (stripeKey: string) => stripe.Stripe;

const stripeScriptId = `stripe-script-id`;

// We should not attempt to load stripe lib once during the lifetime of the app
const ensureStripeScriptIsAdded = memoize(() => {
  const script = document.createElement('script');
  script.id = stripeScriptId;
  script.src = 'https://js.stripe.com/v3/';
  script.async = true;
  document.body.appendChild(script);
});

const tryGetStripe = (): WindowStripe | undefined => {
  // @ts-ignore
  return window.Stripe;
};

const loadWindowStripeAsync = (): Promise<WindowStripe> => {
  ensureStripeScriptIsAdded();
  const stripe = tryGetStripe();
  if (stripe) {
    console.debug("Stripe already loaded");
    return Promise.resolve(stripe);
  } else {
    console.debug("Loading new Stripe");
    return new Promise(resolve => {
      document
        .querySelector(`#${stripeScriptId}`)!
        .addEventListener('load', () => {
          resolve(tryGetStripe()!);
        });
    });
  }
};

// TODO refactor with useLazyScriptLoader ?
export const useStripeLoader = (stripeKey: string): stripe.Stripe | undefined => {
  const [stripe, setStripe] = useState<stripe.Stripe>();
  useEffect(() => {
    console.log("useEffect useStripeLoader");
    loadWindowStripeAsync().then(windowStripe => {
      setStripe(windowStripe(stripeKey));
    });
  }, []);
  return stripe;
};

