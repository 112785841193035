/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

export const SvgVideoPlay = () => {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.168 9.178l-10.01 6.435A1.4 1.4 0 0 1 0 14.436V1.564A1.4 1.4 0 0 1 2.157.387l10.011 6.435a1.4 1.4 0 0 1 0 2.356z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

export const SvgVideoPause = () => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor">
        <rect width="4" height="14" rx="1"></rect>
        <rect x="8" width="4" height="14" rx="1"></rect>
      </g>
    </svg>
  );
};

export const SvgVideoVolume = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor">
        <path
          d="M9.243 1.754v.165a.77.77 0 0 0 .494.704 5.795 5.795 0 0 1 3.618 5.379 5.795 5.795 0 0 1-3.618 5.378.757.757 0 0 0-.494.704v.165c0 .521.518.886.995.704A7.429 7.429 0 0 0 15 8.002a7.429 7.429 0 0 0-4.762-6.952c-.477-.19-.995.183-.995.704z"
          fillRule="nonzero"
        ></path>
        <path d="M9.928 5.363C10.843 5.997 11.3 6.876 11.3 8s-.457 2.003-1.372 2.637a.4.4 0 0 1-.628-.329V5.692a.4.4 0 0 1 .628-.33z"></path>
        <path
          d="M1 6.346v3.31c0 .456.37.828.822.828H3.42l2.705 3.026c.519.521 1.407.146 1.407-.59V3c0-.736-.888-1.031-1.407-.51L3.42 5.519H1.822A.827.827 0 0 0 1 6.346z"
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};

export const SvgVideoMuted = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.254 13.141l1.204 1.21a7.36 7.36 0 0 1-1.22.602c-.445.17-.926-.136-.988-.602l-.007-.102v-.165c0-.315.198-.588.494-.704.177-.07.35-.15.517-.239zm-.016-12.09A7.429 7.429 0 0 1 15 8a7.436 7.436 0 0 1-1.089 3.89l.737.74c.34.342.34.896 0 1.238a.868.868 0 0 1-1.103.106L2.457 2.831l-.082-.073a.869.869 0 0 0-.046-.033l-.232-.231a.878.878 0 0 1 0-1.238.868.868 0 0 1 1.15-.073l.081.073L5.382 3.32l.742-.83c.493-.495 1.319-.254 1.4.403L7.531 3 7.53 5.478l1.8 1.809V5.581a.4.4 0 0 1 .647-.315c.846.663 1.269 1.574 1.269 2.734 0 .385-.047.742-.14 1.072l1.598 1.606a5.79 5.79 0 0 0 .646-2.426l.005-.25a5.795 5.795 0 0 0-3.618-5.38.778.778 0 0 1-.486-.6l-.008-.103v-.165c0-.521.518-.894.995-.704zM2.67 5.517l4.861 4.885v2.517c0 .7-.8 1.073-1.325.662l-.08-.072-2.706-3.026H1.822a.827.827 0 0 1-.816-.724L1 9.657v-3.31c0-.42.315-.77.72-.822l.102-.006.847-.001z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

export const SvgReply = () => {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.3 9.193V10.5h1.307l7.072-7.07-1.31-1.31L2.3 9.194zM9.37 0l3.43 3.43L4.23 12H.8V8.57L9.37 0z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

export const SvgDelete = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 16 16">
      <path
        d="M11.333 6H4.667v8h6.666V6zM4 4V2.54C4 1.137 5.107 0 6.476 0h3.048C10.894 0 12 1.137 12 2.54V4h3.024c.539 0 .976.448.976 1s-.437 1-.976 1h-1.69v8.5c0 .827-.683 1.5-1.525 1.5H4.191c-.84 0-1.524-.672-1.524-1.5V6H.976C.437 6 0 5.552 0 5s.437-1 .976-1H4zm1.907 0h4.186V2.54a.577.577 0 0 0-.569-.587H6.476a.578.578 0 0 0-.57.587V4z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

export const SvgPrivate = () => {
  return (
    <svg viewBox="0 0 14 14" width="14" height="14">
      <path
        d="M8 5V3.77c-.125-.853-.688-.924-1-.924-.313 0-.875.071-1 .923V5h2zM4 5V3.77C4 1.922 5.25 1 7 1s3 .923 3 2.77V5c1.111 0 2 .865 2 1.946v4.108C12 12.135 11.111 13 10 13H4c-1.111 0-2-.865-2-1.946V6.946C2 5.865 2.889 5 4 5zm4 5.19V8.502c0-.375-.5-.656-1-.656s-1 .281-1 .656v1.688c0 .375.5.656 1 .656s1-.281 1-.656z"
        fill="currentColor"
        fillRule="evenodd"
      ></path>
    </svg>
  );
};

export const SvgPublic = () => {
  return (
    <svg viewBox="0 0 16 16" width="14" height="14">
      <path
        d="M8 14.5a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13zM8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zM4 4c.271 1.41.938 2.077 2 2 .995.14 1.982.103 2 0 .628.127.07 1.31 1 2-.021-.113-.195-1.31 1-1 .268.007.268.34 0 1a6.823 6.823 0 0 1-2 3c-1.085.65-.711-1.238-1 0-.539 1.177-1.527 1.016-1 2-.282.687-.282 1.354 0 2 1.856-.408 2.856-.742 3-1 .132.029 1.132-.971 3-3-.932-.429-1.265-.762-1-1-.233-.535.1-1.535 1-3l1-1-1-2c-1.044-1.84-2.044-2.84-3-3-.956-.16-1.956.507-3 2L4 4z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

export const SvgTimeCode = () => {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15">
      <path
        d="M5 1.29V1a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v.29a7 7 0 1 1-4 0zM7 13.5a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zm.833-5.963v.387L9.289 9.38a.75.75 0 1 1-1.06 1.06L6.332 8.546V5.344a.75.75 0 0 1 1.5 0v2.193z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

export const SvgFirstFrame = ({
  height = 15,
  color = 'white',
}: {
  height: number;
  color: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 0 200 142.9"
    >
      <path
        fill={color}
        d="M0 0h28.6v142.9H0zm85.713-.042v28.6h-28.6v-28.6zm.043 57.143v28.6h-28.6v-28.6zM200-.029v28.6h-28.6v-28.6zm.043 57.144v28.6h-28.6v-28.6zM114.3 0h28.6v142.9h-28.6z"
      ></path>
    </svg>
  );
};
