import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { injectGlobal } from 'emotion';
import { ShColors, ShRadius, ShFonts } from '@shoootin/design-tokens';
import { darken } from 'polished';

injectGlobal`
.SingleDatePicker {
    width : 100%;
    
}
.SingleDatePicker .DateInput {
    width: calc(100% - 24px);
}



.SingleDatePickerInput, .DateRangePickerInput {
    width : 100%;
    background-color: ${ShColors.whiteD};
    border-color: ${ShColors.whiteD};
    border-radius: ${ShRadius.m}px;
    color: ${ShColors.black};
    font-family: ${ShFonts.primary};
}

.SingleDatePickerInput_calendarIcon,
.DateRangePickerInput_calendarIcon {
    padding : 0;
    margin: 0 0 0 10px;
}
.DateInput {
    background-color: ${ShColors.transparent};
    width: 120px;
}
.DateInput_input {
    background-color: ${ShColors.transparent};
    font-size: 16px;
    line-height: 1.2;
    padding: 13px 5px 9px;
    text-align: center;
    color : ${ShColors.black};
}

.DateInput_input::placeholder {
    color: ${ShColors.placeholder};
}


.DateInput_input__focused {
  border-bottom: 2px solid ${ShColors.base};
}

.DateRangePickerInput_arrow_svg {
    height: 20px;
    width: 20px;
}


.SingleDatePickerInput__showClearDate
.DateRangePickerInput__showClearDates {
    padding-right: 20px;
}
.SingleDatePickerInput_clearDate,
.DateRangePickerInput_clearDates {
    margin : 0px;
}
.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover,
.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
    background: #dbdbdb;
    border-radius: 0%;
}

.CalendarDay {
    border: solid 1px ${ShColors.whiteD} !important;
}
.CalendarDay__default:hover {
    background: ${ShColors.baseL};
    color: ${ShColors.white};
}
.CalendarDay__selected {
    background: ${ShColors.base};
    color: ${ShColors.white};
}
.CalendarDay__selected:hover {
    background: ${darken(0.1, ShColors.base)};
    color: ${ShColors.white};
}


.CalendarDay__hovered_span {    
    background: ${ShColors.baseL};
    color: ${ShColors.white};
}
.CalendarDay__hovered_span:hover {
    background: ${darken(0.1, ShColors.baseL)};
    color: ${ShColors.white};
}

.CalendarDay__selected_span {
    background: ${ShColors.baseL};
    color: ${ShColors.white};
}
.CalendarDay__selected_span:hover {
    background: ${ShColors.base};
    color: ${ShColors.white};
}
.CalendarMonth_table tr {
    background : transparent !important;
}
.CalendarMonth_table tr td {
    border : 0px;
}

`;
