/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import { ShColors } from '@shoootin/design-tokens';

import {
  ShVideoComment,
  ShVideoCommentReply,
  ShVideoCommentReplyForm,
} from '@shoootin/api';
import { ShButton } from '../../designSystem/primitives/button/ShButton/ShButton';

import { ShInputTextarea } from '../../designSystem/primitives/input/ShInputTextarea/ShInputTextarea';
import { SvgDelete, SvgReply } from './ShVideoIcons';
import { ShButtonAsync } from '../../designSystem/primitives/button/ShButton/ShButtonAsync';
import { lighten } from 'polished';
import { CommentEditForm } from './ShVideoReviewCommentForm';
import { CommentNameAndDate } from './ShVideoReviewCommentElements';

export const CommentReply = ({
  comment,
  reply,
  seekTo,
  addReply,
  deleteReply,
  color,
}: {
  comment: ShVideoComment;
  reply: ShVideoCommentReply;
  seekTo: (s: number) => void;
  addReply: (id: string, reply: ShVideoCommentReplyForm) => void;
  deleteReply: (id: string, replyId: string) => void;
  color: string;
}) => {
  const [edit, setEdit] = useState<boolean>(false);

  const [hovered, setHovered] = useState<boolean>(false);
  return (
    <div
      css={{
        padding: 10,
        paddingLeft: 20,
        marginTop: 2,
        fontSize: 14,
        //borderTop: `5px solid ${ShColors.blackLL}`,
        backgroundColor: lighten(0.05, ShColors.blackLL),
      }}
    >
      <CommentNameAndDate
        userName={reply.userName}
        date={reply.date}
        userType={reply.userType}
        color={color}
      />

      {edit ? (
        <CommentEditForm
          value={reply.comment}
          closeEdit={() => setEdit(false)}
          saveComment={(value: string) =>
            addReply(comment.id, { ...reply, comment: value })
          }
        />
      ) : (
        <div
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onMouseOver={() => !hovered && setHovered(true)}
        >
          <div
            onClick={() => {
              seekTo(comment.currentTime ?? 0);
            }}
            css={{
              cursor: 'pointer',
              marginBottom: 5,
              paddingBottom: 5,
              paddingTop: 5,
            }}
          >
            <span>{reply.comment}</span>
          </div>
          <div css={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>&nbsp;</div>
            <div>
              <span
                css={{
                  opacity: hovered ? 1 : 0,
                  transition: 'all 0.2s ease-in-out',
                }}
              >
                <span
                  onClick={() => setEdit(true)}
                  css={{ padding: 5, cursor: 'pointer' }}
                >
                  <SvgReply />
                </span>
                <span
                  onClick={() => deleteReply(comment.id, reply.id)}
                  css={{ padding: 5, cursor: 'pointer' }}
                >
                  <SvgDelete />
                </span>
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const CommentReplies = ({
  comment,
  seekTo,
  addReply,
  deleteReply,
  color,
}: {
  comment: ShVideoComment;
  seekTo: (s: number) => void;
  addReply: (id: string, reply: ShVideoCommentReplyForm) => void;
  deleteReply: (id: string, replyId: string) => void;
  color: string;
}) => {
  if (comment.replies.length === 0) return null;

  return (
    <div
      css={{
        backgroundColor: ShColors.blackLL,
        marginTop: 5,
      }}
    >
      {comment.replies.map((reply: ShVideoCommentReply) => (
        <CommentReply
          reply={reply}
          comment={comment}
          seekTo={seekTo}
          key={reply.id}
          addReply={addReply}
          deleteReply={deleteReply}
          color={color}
        />
      ))}
    </div>
  );
};

export const CommentReplyForm = ({
  commentId,
  addReply,
  closeReply,
}: {
  commentId: string;
  addReply: (id: string, reply: ShVideoCommentReplyForm) => void;
  closeReply: () => void;
}) => {
  const [replyValue, setReplyValue] = useState<string>('');
  return (
    <div css={{ marginTop: 5 }}>
      <div css={{ paddingBottom: 10 }}>
        <ShInputTextarea
          themeSize={'s'}
          rows={2}
          value={replyValue}
          placeholder={'Type your reply here'}
          onChange={(e) => {
            setReplyValue(e.target.value);
          }}
        />
      </div>
      <div css={{ display: 'flex', justifyContent: 'space-between' }}>
        <div css={{ paddingRight: 10 }}>
          <ShButtonAsync
            size={'s'}
            variant={'glass'}
            onClick={() => {
              const reply = {
                comment: replyValue,
              };
              addReply(commentId, reply);
              setReplyValue('');
              closeReply();
              return;
            }}
          >
            Save
          </ShButtonAsync>
        </div>
        <div>
          <ShButton
            variant={'glass'}
            size={'s'}
            onClick={() => {
              setReplyValue('');
              closeReply();
            }}
          >
            Cancel
          </ShButton>
        </div>
      </div>
    </div>
  );
};
