import React from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';

export const ShVideoPlayer = ({
  url,
  playing,
  muted,
  controls,
}: Pick<
  ReactPlayerProps,
  'url' | 'playing' | 'muted' | 'controls' | 'onProgress' | 'progressInterval'
>) => {
  return (
    <ReactPlayer
      url={url}
      playing={playing}
      muted={muted}
      controls={controls}
      width={'100%'}
      height={'100%'}
    />
  );
};

export const ShVideoPlayerReview = React.forwardRef(
  (
    {
      url,
      playing,
      muted,
      controls,
      onProgress,
      progressInterval,
      height,
      onReady,
      onPlay,
      onPause,
      volume,
    }: //onSeek,
    Pick<
      ReactPlayerProps,
      | 'url'
      | 'playing'
      | 'muted'
      | 'controls'
      | 'onProgress'
      | 'progressInterval'
      | 'height'
      | 'onReady'
      | 'onPlay'
      | 'onPause'
      | 'volume'
      //| 'onSeek'
    >,
    ref: any,
  ) => {
    // const seekToPoint = () => {
    //   player && player.current && player.current.seekTo(30); // this does not work
    //   console.log('seekToPoint');
    // };

    return (
      <ReactPlayer
        ref={ref}
        url={url}
        playing={playing}
        muted={muted}
        volume={volume}
        controls={controls}
        width={'100%'}
        height={height}
        onProgress={onProgress}
        progressInterval={progressInterval}
        onReady={onReady}
        onPlay={onPlay}
        onPause={onPause}
        //onSeek={onSeek}
      />
    );
  },
);
