import { injectGlobal } from 'emotion';
import {
  ShColors,
  ShFonts,
  ShMediaQueries,
  ShEasings,
  ShFontWeights,
} from '@shoootin/design-tokens';

injectGlobal`

.intercom-articles {
    
    line-height: 1.53;
    color : ${ShColors.blackL};
    
    img {
        max-width : 100%;
        margin-bottom : 34px;
    }

    i {
        font-family : ${ShFonts.primary} !important;
        font-style : italic;
    }
    
    h1 {
        font-size : 22px;
        margin-bottom : 17px;
        line-height: 1.24;
    }
    
    h2 {
        font-size: 18px;
        margin: 0 0 15px;
        font-weight: 600;
        line-height: 1.24;
    }
    
    .no-margin {
        margin : 0px;
        min-height: 1.53em;
    }
    
    ul {
        display: block;
        list-style-type: disc !important;
        margin-block-start: 1em !important;
        margin-block-end: 1em !important;
        margin-inline-start: 0px !important;
        margin-inline-end: 0px !important;
        padding-inline-start: 40px !important;
        padding-left: 16px !important;
    }
    
    li {
        list-style-type: disc;
        margin-left: 15px;
        line-height: 1.53;
    }
    
    a {
        display: initial;
        position: relative;
        color: ${ShColors.black};
        font-family:  ${ShFonts.primary};
        font-weight:  ${ShFontWeights.s};
        text-decoration: none;
        cursor: pointer;
        transition: color 200ms ${ShEasings.easeIn};
         &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0px;
            height: 1px;
            background-color: currentColor;
            transform: scaleX(0);
            transform-origin: right center;
            transition: transform 200ms ${ShEasings.easeIn};
        }
        &:hover, &:active, &:focus {
          color: ${ShColors.blackLL};
          transition: color 250ms ${ShEasings.easeOut};
        
        &::before  {
          transform: scaleX(1);
          transform-origin: left center;
          transition: transform 250ms ${ShEasings.easeOut};
          }
        }
    }
    .intercom-h2b-video {
      margin-bottom: 20px;
      iframe {
          width : 100%;
          aspect-ratio: 16/9;
      }
    }
}
`;
