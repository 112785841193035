/** @jsx jsx */
import { jsx } from '@emotion/core';

import { SvgProps } from '../../../components/svg/SvgUtils';
import { ShColors } from '@shoootin/design-tokens';

const TickSvg = ({ width = 24, color = 'black', strokeWidth }: SvgProps) => (
  <svg
    viewBox="0 0 24 24"
    height={width}
    width={width}
    fill="none"
    stroke={ShColors[color]}
    strokeWidth={strokeWidth}
    css={{
      fill: 'none !important',
    }}
  >
    <path d="M3.5,10.5l6,6l11-9.5" />
  </svg>
);

export default TickSvg;
