/** @jsx jsx */
import { ReactNode, useEffect, useRef } from 'react';
import { jsx } from '@emotion/core';
import { isString } from 'lodash';
import { ShColors, ShFonts } from '@shoootin/design-tokens';
import { useShIntl } from '@shoootin/translations';

export type ShInputErrorProps = {
  className?: string;
  children: string | ReactNode;
  scrollIntoViewKey?: string | number;
};

export const ShInputError = ({
  className,
  children: rawChildren,
  scrollIntoViewKey,
}: ShInputErrorProps) => {
  const ref = useRef<HTMLParagraphElement>(null);

  const { isMessage, translate } = useShIntl();

  const children =
    isString(rawChildren) && isMessage(rawChildren)
      ? translate({ id: rawChildren })
      : rawChildren;

  // When an error mounts, we should make it scroll into view
  useEffect(() => {
    if (ref.current && scrollIntoViewKey) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [scrollIntoViewKey]);

  return (
    <p
      ref={ref}
      className={className}
      css={{
        margin: 0,
        fontSize: '16px',
        fontFamily: ShFonts.primary,
        color: ShColors.error,
        lineHeight: 1.5,
      }}
    >
      {children}
    </p>
  );
};
