/** @jsx jsx */
import { jsx } from '@emotion/core';
import { darken } from 'polished';

import {
  ShColor,
  ShColors,
  ShEasings,
  ShServiceColorPalettes,
  ShRadius,
} from '@shoootin/design-tokens';
import { ShStyles } from '../../../../designSystem/constants/ShStyles';
import { ShService } from '@shoootin/config';

export const ShInputRadioSizes = {
  s: 24,
  m: 30,
  l: 40,
  xl: 50,
} as const;

export type ShInputRadioSize = keyof typeof ShInputRadioSizes;
export const ShInputRadioSizeList = Object.keys(
  ShInputRadioSizes,
) as ShInputRadioSize[];

export type ShInputRadioProps = {
  value: boolean;
  onChange?: (value: boolean) => void;
  service?: ShService;
  size?: ShInputRadioSize;
  invert?: boolean;
};

export const ShInputRadio = ({
  value,
  onChange,
  service,
  size = 'm',
  invert = false,
}: ShInputRadioProps) => {
  const radioColor: ShColor = service
    ? ShServiceColorPalettes[service].dark1
    : 'base';

  return (
    <span
      onClick={() => onChange && onChange(!value)}
      css={{
        ...ShStyles.centered,
        borderRadius: ShRadius.half,
        width: `${ShInputRadioSizes[size]}px`,
        height: `${ShInputRadioSizes[size]}px`,
        cursor: 'pointer',
        backgroundColor: invert ? ShColors.white : ShColors.whiteD,
        transition: `background-color 100ms ${ShEasings.ease}`,

        '&:hover': {
          backgroundColor: darken(0.025, ShColors.whiteD),
        },

        '&:active': {
          backgroundColor: darken(0.05, ShColors.whiteD),
        },
      }}
    >
      <div
        css={{
          borderRadius: ShRadius.half,
          width: `${ShInputRadioSizes[size] / 2}px`,
          height: `${ShInputRadioSizes[size] / 2}px`,
          backgroundColor: ShColors[radioColor],
          opacity: 0,
          transform: 'scale(.9)',
          transition: `transform 100ms ${ShEasings.easeIn},
           opacity 100ms ${ShEasings.easeIn}`,

          ...(value && ActivedRadioStyle),
        }}
      />
    </span>
  );
};

const ActivedRadioStyle = {
  opacity: 1,
  transform: 'scale(1)',
  transition: `transform 100ms ${ShEasings.easeOut},
                opacity 100ms ${ShEasings.easeIn}`,
};
