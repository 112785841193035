/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import { ShVideoComment } from '@shoootin/api';
import { getTimelinePosition, TIMELINE_LOGO_WIDTH } from './ShVideoReviewUtils';
import { ShColors } from '@shoootin/design-tokens';

export const VideoTimeLineComment = ({
  comment,
  duration,
  seekTo,
  width,
  setHovered,
  closeHover,
}: {
  comment: ShVideoComment;
  duration: number;
  seekTo: (s: number) => void;
  width: number;
  setHovered: (comment: ShVideoComment) => void;
  closeHover: () => void;
}) => {
  const position = comment.currentTime
    ? comment.currentTime / duration
    : undefined;

  return (
    <div
      style={{
        left: getTimelinePosition(width, position),
      }}
      css={{
        position: 'absolute',
        height: 20,
        width: 20,
      }}
      onClick={() => seekTo(comment.currentTime ?? 0)}
      onMouseEnter={() => setHovered(comment)}
      onMouseLeave={() => closeHover()}
    >
      <img
        src={'https://app.shoootin.com/assets/images/favicon_bo.png?v2'}
        width={TIMELINE_LOGO_WIDTH}
      />
    </div>
  );
};

export const VideoTimelineComments = ({
  comments,
  duration,
  seekTo,
  setHovered,
  closeHover,
  width,
}: {
  comments: ShVideoComment[];
  duration: number;
  seekTo: (s: number) => void;
  setHovered: (comment: ShVideoComment) => void;
  closeHover: () => void;
  width: number;
}) => {
  return (
    <div
      css={{
        height: 36,
        position: 'relative',
        paddingTop: 8,
        paddingBottom: 8,
        backgroundColor: ShColors.blackLL,
      }}
    >
      {comments &&
        comments.map((comment: any, index: number) => (
          <VideoTimeLineComment
            key={index}
            comment={comment}
            duration={duration}
            seekTo={seekTo}
            width={width}
            setHovered={setHovered}
            closeHover={closeHover}
          />
        ))}
    </div>
  );
};
