/* eslint-disable */
// copied from https://github.com/reach/reach-ui/blob/master/packages/auto-id/src/index.js

import { useState, useEffect } from 'react';
let id = 0;
const genId = () => ++id;

// This is useful to assign an id to an element,
// so that it can be referenced in "htmlFor"
// Note the element is only assigned an ID after mount
const useHtmlForId = (): string | undefined => {
  const [id, setId] = useState<number | undefined>(undefined);
  useEffect(() => setId(genId()), []);
  return id ? `${id}` : undefined;
};

export default useHtmlForId;
