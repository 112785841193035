/** @jsx jsx */
import { jsx } from '@emotion/core';

import React, { ReactNode, useContext } from 'react';
import { useField, useFormikContext, getIn, FieldMetaProps } from 'formik';
import {
  ShInputText,
  ShInputError,
  ShInputTextarea,
  ShCheckboxField,
  ShInputSelect,
  ShRadioField,
  ShInputNumber,
  ShInputSelectProps,
} from '@shoootin/components-web';
import { ShInputCheckboxSize } from '../../designSystem/primitives/input/ShInputCheckbox/ShInputCheckbox';

const ShFormikPrefixContext = React.createContext<string | undefined>(
  undefined,
);

const applyPrefix = (prefix: string | undefined, path: string): string => {
  return prefix ? `${prefix}.${path}` : path;
};

const useFormikPrefix = () => useContext(ShFormikPrefixContext);
const useFormikPrefixedPath = (path: string) =>
  applyPrefix(useFormikPrefix(), path);

// In case of form slices being nested into each others,
// it's possible to use a "prefix provider"
export const ShFormikPrefixProvider = <T extends any>({
  prefix,
  children,
}: {
  prefix: keyof T;
  children: ReactNode;
}) => {
  // Is multiple prefix providers are nested into each others,
  // we add the prefix to the existing parent prefix
  const value = applyPrefix(useFormikPrefix(), prefix as string);
  return (
    <ShFormikPrefixContext.Provider value={value}>
      {children}
    </ShFormikPrefixContext.Provider>
  );
};

// more typesafe than useField which accept any string
export const useShFormikField = <V, FieldType extends any = string>(
  fieldName: keyof V,
) => useField<FieldType>(useFormikPrefixedPath(fieldName as string));

export const useShFormikFieldValue = <V, FieldType extends any = string>(
  fieldName: keyof V,
) => {
  const [{ value }] = useShFormikField<V, FieldType>(fieldName);
  return value;
};

export const parseShFormikFieldObjectErrors = <T extends any>(
  meta: FieldMetaProps<T[]>,
) => {
  // When the error is on the array validation (ex, array must have size 1
  const arrayError: string | undefined =
    typeof meta.error === 'string' ? meta.error : undefined;

  // When the error is nested on a specific array item, we get an object like [{itemKey: "error message"}]
  type ArrayItemError = { [key in keyof T]?: string };
  const arrayItemErrors: ArrayItemError[] =
    typeof meta.error === 'object' ? meta.error : [];

  return {
    arrayError,
    arrayItemErrors,
  };
};

export const useShFormikValues = <V extends any>(): V => {
  const prefix = useFormikPrefix();
  const context = useFormikContext();
  const values = prefix ? getIn(context.values, prefix) : context.values;
  return values;
};

export const ShFormikFieldError = ({ meta }: { meta: FieldMetaProps<any> }) => {
  // not very efficient way to ensure the error scroll into view on each submit attempt
  // not sure there's an easy way to listen for submitCount without subscribing to whole Formik context
  const scrollIntoViewKey = useFormikContext().submitCount;
  return (
    <React.Fragment>
      {meta.touched && meta.error && (
        <ShInputError scrollIntoViewKey={scrollIntoViewKey}>
          {meta.error}
        </ShInputError>
      )}
    </React.Fragment>
  );
};

export const ShFormikTextField = <V, FieldType extends string = string>({
  fieldName,
  label,
  placeholder,
  type = 'text',
  required = false,
}: {
  fieldName: keyof V;
  label?: string;
  type?: 'text' | 'password';
  placeholder?: string;
  required?: boolean;
}) => {
  const [field, meta, helpers] = useShFormikField<V, FieldType>(fieldName);
  return (
    <div css={{ width: '100%' }}>
      {label && <label>{label}</label>}
      <ShInputText
        {...field}
        type={type}
        required={required}
        placeholder={placeholder}
        onChange={(e) => helpers.setValue(e.target.value as any)}
      />
      <ShFormikFieldError meta={meta} />
    </div>
  );
};

export const ShFormikNumberField = <V, FieldType extends number = number>({
  fieldName,
  label,
  placeholder,
  required = false,
}: {
  fieldName: keyof V;
  label?: string;
  placeholder?: string;
  required?: boolean;
}) => {
  const [field, meta, helpers] = useShFormikField<V, FieldType>(fieldName);
  return (
    <div css={{ width: '100%' }}>
      {label && <label>{label}</label>}
      <ShInputNumber
        {...field}
        required={required}
        placeholder={placeholder}
        onChange={(e) => helpers.setValue(e as any)}
      />
      <ShFormikFieldError meta={meta} />
    </div>
  );
};

export const ShFormikTextAreaField = <V, FieldType extends string = string>({
  fieldName,
  label,
  required = false,
  rows = 3,
  placeholder,
}: {
  fieldName: keyof V;
  label: string;
  required?: boolean;
  rows?: number;
  placeholder?: string;
}) => {
  const [field, meta, helpers] = useShFormikField<V, FieldType>(fieldName);
  return (
    <div css={{ width: '100%' }}>
      {label !== '' && <label>{label}</label>}
      <ShInputTextarea
        {...field}
        rows={rows}
        required={required}
        placeholder={placeholder}
        onChange={(e) => helpers.setValue(e.target.value as any)}
      />
      <ShFormikFieldError meta={meta} />
    </div>
  );
};

export const ShFormikCheckBoxField = <V, FieldType extends boolean = boolean>({
  fieldName,
  label,
  bold = false,
  showError = true,
  size,
}: {
  fieldName: keyof V;
  label: string | ReactNode;
  bold?: boolean;
  showError?: boolean;
  size?: ShInputCheckboxSize;
}) => {
  const [field, meta, helpers] = useShFormikField<V, FieldType>(fieldName);
  return (
    <React.Fragment>
      <ShCheckboxField
        {...field}
        bold={bold}
        label={label}
        size={size}
        onChange={(value) => {
          helpers.setValue(value as any);
          helpers.setTouched(true);
        }}
      />
      {showError && <ShFormikFieldError meta={meta} />}
    </React.Fragment>
  );
};

export const ShFormikRadioField = <V, FieldType extends string = string>({
  fieldName,
  label,
  value,
  bold = false,
  showError = true,
}: {
  fieldName: keyof V;
  label: string | ReactNode;
  value: string;

  bold?: boolean;
  showError?: boolean;
}) => {
  const [field, meta, helpers] = useShFormikField<V, FieldType>(fieldName);
  return (
    <React.Fragment>
      <ShRadioField
        {...field}
        bold={bold}
        label={label}
        value={field.value === value}
        onChange={(_value) => {
          helpers.setValue(value as any);
          helpers.setTouched(true);
        }}
      />
      {showError && <ShFormikFieldError meta={meta} />}
    </React.Fragment>
  );
};

export type ShFormikSelectFieldProps<
  V,
  FieldType extends string | undefined = string
> = {
  fieldName: keyof V;
  showError?: boolean;
} & Omit<ShInputSelectProps<FieldType>, 'value' | 'onChange'>;

export const ShFormikSelectField = <
  V,
  FieldType extends string | undefined = string
>({
  fieldName,
  showError = true,
  ...props
}: ShFormikSelectFieldProps<V, FieldType>) => {
  const [field, meta, helpers] = useShFormikField<V, FieldType>(fieldName);
  return (
    <React.Fragment>
      <ShInputSelect<FieldType>
        {...props}
        value={field.value}
        onChange={(value) => {
          helpers.setValue(value);
          helpers.setTouched(true);
        }}
      />
      {showError && <ShFormikFieldError meta={meta} />}
    </React.Fragment>
  );
};
