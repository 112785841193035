import React from 'react';
import { SvgProps } from '../../../components/svg/SvgUtils';
import { ShColors } from '@shoootin/design-tokens';

const CloseSvg = ({ width = 24, color = 'black' }: SvgProps) => (
  <svg
    viewBox="0 0 19 19"
    height={width * (2 / 3)}
    width={width}
    fill="none"
    stroke={ShColors[color]}
  >
    <line x1={2.5} y1={2.5} x2={16.5} y2={16.5} />
    <line x1={16.5} y1={2.5} x2={2.5} y2={16.5} />
  </svg>
);

export default CloseSvg;
