/** @jsx jsx */
import { jsx } from '@emotion/core';

// TODO make it in css-in-js if useful
export const ShClearfix = () => <div className={'clearfix'}> </div>;

export const ShHr = ({ mini = false }: { mini?: boolean }) => (
  <hr
    css={
      mini
        ? {
            marginTop: 12,
            marginBottom: 12,
            border: 0,
            borderTop: '1px solid #eee',
          }
        : {}
    }
  />
);
