import { injectGlobal } from 'emotion';
import { ShColors, ShFonts, ShMediaQueries } from '@shoootin/design-tokens';

injectGlobal`

.DayPickerSingle {

  .DayPicker,
  .DayPicker > div,
  .DayPicker > div > div,
  .DayPicker_transitionContainer,
  .CalendarMonth_table {
    width: 100% !important;
  }

  .CalendarMonthGrid {
    width: 1000% !important;
  }

  .CalendarMonthGrid_month__horizontal {
    width: calc(10% - 18px) !important;
  }

  .DayPicker_weekHeader {
    width: calc(100% - 9px) !important;
  }

  .CalendarDay,
  .DayPicker_weekHeader_li {
    width: calc((100% /*- 26px*/) / 7) !important; //14.285714286% !important;
    min-width: auto;
  }
  
  .chevron-prev,
  .chevron-next {
    stroke-width: 1px;
    stroke: ${ShColors.black};
    height: 16px;
    width: 16px;
    transition: transform 125ms ease-in;
  }
  .nav { 
    display: flex;
    align-items: center;

    .year {
      margin-left: auto;
    }
  }

  .picker-date {
    table-layout: fixed;
    width: 100%;
    .prev,
    .next {
      cursor: pointer;
    }
    .prev {
      margin-right: 1em;
      &:hover {
        .chevron-prev {
          transform: translateX(-25%);
          transition-timing-function: ease-out;
        }
      }
    }
    .next {
      margin-left: 1em;
      &:hover {
        .chevron-next {
          transform: translateX(25%);
          transition-timing-function: ease-out;
        }
      }
    }
  }


.CalendarDay {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
}
.CalendarDay:active {
  outline: 0;
}
.CalendarDay__defaultCursor {
  cursor: default;
}
.CalendarDay__default {
  position: relative;
  z-index: 0;
  min-height: 52px;
  //min-width: 52px;
  min-width: unset;
  padding: .8em 0 .5em;
  @media ${ShMediaQueries.ShMobileOnly} {
    min-width: 34px;
    min-height: 34px;
    padding: .6em 0 .0em;
  }
  box-sizing: border-box;
  color: ${ShColors.black};
  font-family: ${ShFonts.secondary};
  text-align: center;
  cursor: pointer;
  transition: color 100ms cubic-bezier(0.4, 0.0, 1, 1);
  font-size : font();

}
.CalendarDay__default:hover {

}
.CalendarDay__hovered_offset {
  background: #f4f5f5;
  border: 1px double #e4e7e7;
  color: inherit;
}
.CalendarDay__outside {
  border: 0;
  background: #fff;
  color: #484848;
}
.CalendarDay__outside:hover {
  border: 0;
}

.CalendarDay__highlighted_calendar {
  background: #ffe8bc;
  color: #484848;
}
.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
  background: #ffce71;
  color: #484848;
}
.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  color: #DDDDDD;
  pointer-events: none;
}
.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  color: #DDDDDD;
  pointer-events: none;
}
.CalendarDay__hovered_start_first_possible_end {
  background: #eceeee;
  border: 1px double #eceeee;
}
.CalendarDay__hovered_start_blocked_min_nights {
  background: #eceeee;
  border: 1px double #e4e7e7;
}
.CalendarMonth {
  background: #fff;
  text-align: center;
  vertical-align: top;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.CalendarMonth_table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100% !important;
}
.CalendarMonth_verticalSpacing {
  border-collapse: separate;
}

.CalendarMonth_caption__verticalScrollable {
  padding-top: 12px;
  padding-bottom: 7px;
}
.CalendarMonthGrid {
  background: #fff;
  text-align: left;
  z-index: 0;
}
.CalendarMonthGrid__animating {
  z-index: 1;
}
.CalendarMonthGrid__horizontal {
  position: absolute;
  left: 9px;
}
.CalendarMonthGrid__vertical {
  margin: 0 auto;
}
.CalendarMonthGrid__vertical_scrollable {
  margin: 0 auto;
  overflow-y: scroll;
}
.CalendarMonthGrid_month__horizontal {
  display: inline-block;
  vertical-align: top;
  min-height: 100%;
}
.CalendarMonthGrid_month__hideForAnimation {
  position: absolute;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}
.CalendarMonthGrid_month__hidden {
  visibility: hidden;
}
.DayPickerNavigation {
  position: relative;
  z-index: 2;
}
.DayPickerNavigation__horizontal {
  height: 0;
}

.DayPicker {
  background: #fff;
  position: relative;
  text-align: left;
  width: 100% !important;
}
.DayPicker__horizontal {
  background: #fff;
}
.DayPicker__verticalScrollable {
  height: 100%;
}
.DayPicker__hidden {
  visibility: hidden;
}
.DayPicker__withBorder {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
  border-radius: 3px;
}
.DayPicker_portal__horizontal {
  box-shadow: none;
  position: absolute;
  left: 50%;
  top: 50%;
}
.DayPicker_portal__vertical {
  position: initial;
}
.DayPicker_focusRegion {
  outline: 0;
}
.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
  display: inline-block;
  vertical-align: top;
}
.DayPicker_weekHeaders {
  position: relative;
}
.DayPicker_weekHeaders__horizontal {
  margin-left: 9px;
}
.DayPicker_weekHeader {
  //color: #757575;
  position: absolute;
  top: 70px;
  z-index: 2;
  text-align: left;
}

.DayPicker_weekHeader_ul {
  list-style: none;
  margin: 1px 0;
  padding-left: 0;
  padding-right: 0;
}
.DayPicker_weekHeader_li {
  display: inline-block;
  text-align: center;
  padding: 0em;
  //.font-size(16);
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: -0.02em;
}
.DayPicker_transitionContainer {
  position: relative;
  overflow: hidden;
  border-radius: 3px;

}
.DayPicker_transitionContainer__horizontal {
  -webkit-transition: height 0.2s ease-in-out;
  -moz-transition: height 0.2s ease-in-out;
  transition: height 0.2s ease-in-out;
  padding-bottom: 10px;
}
.DayPicker_transitionContainer__vertical {
  width: 100%;
}
.DayPicker_transitionContainer__verticalScrollable {
  padding-top: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll;
}
.DateInput {
  margin: 0;
  padding: 0;
  background: #fff;
  position: relative;
  display: inline-block;
  width: 130px;
  vertical-align: middle;
}
.DateInput__small {
  width: 97px;
}
.DateInput__block {
  width: 100%;
}
.DateInput__disabled {
  background: #f2f2f2;
  color: #dbdbdb;
}
.DateInput_input {
  font-weight: 200;
  font-size: 19px;
  line-height: 24px;
  color: #484848;
  background-color: #fff;
  width: 100%;
  padding: 11px 11px 9px;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid transparent;
  border-left: 0;
  border-radius: 0;
}
.DateInput_input__small {
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.2px;
  padding: 7px 7px 5px;
}
.DateInput_input__regular {
  font-weight: auto;
}
.DateInput_input__readOnly {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.DateInput_input__focused {
  outline: 0;
  background: #fff;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid #008489;
  border-left: 0;
}
.DateInput_input__disabled {
  background: #f2f2f2;
  font-style: italic;
}
.DateInput_screenReaderMessage {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.DateInput_fang {
  position: absolute;
  width: 20px;
  height: 10px;
  left: 22px;
  z-index: 2;
}
.DateInput_fangShape {
  fill: #fff;
}
.DateInput_fangStroke {
  stroke: #dbdbdb;
  fill: transparent;
}
.DateRangePickerInput {
  background-color: #fff;
  display: inline-block;
}
.DateRangePickerInput__disabled {
  background: #f2f2f2;
}
.DateRangePickerInput__withBorder {
  border-radius: 2px;
  border: 1px solid #dbdbdb;
}
.DateRangePickerInput__rtl {
  direction: rtl;
}
.DateRangePickerInput__block {
  display: block;
}
.DateRangePickerInput__showClearDates {
  padding-right: 30px;
}
.DateRangePickerInput_arrow {
  display: inline-block;
  vertical-align: middle;
  color: #484848;
}
.DateRangePickerInput_arrow_svg {
  vertical-align: middle;
  fill: #484848;
  height: 24px;
  width: 24px;
}
.DateRangePickerInput_clearDates {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.DateRangePickerInput_clearDates__small {
  padding: 6px;
}
.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
  background: #dbdbdb;
  border-radius: 50%;
}
.DateRangePickerInput_clearDates__hide {
  visibility: hidden;
}
.DateRangePickerInput_clearDates_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle;
}
.DateRangePickerInput_clearDates_svg__small {
  height: 9px;
}
.DateRangePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px;
}
.DateRangePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle;
}
.DateRangePicker {
  position: relative;
  display: inline-block;
}
.DateRangePicker__block {
  display: block;
}
.DateRangePicker_picker {
  z-index: 1;
  background-color: #fff;
  position: absolute;
}
.DateRangePicker_picker__rtl {
  direction: rtl;
}
.DateRangePicker_picker__directionLeft {
  left: 0;
}
.DateRangePicker_picker__directionRight {
  right: 0;
}
.DateRangePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.DateRangePicker_picker__fullScreenPortal {
  background-color: #fff;
}
.DateRangePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2;
}
.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
  color: darken(#cacccd, 10%);
  text-decoration: none;
}
.DateRangePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd;
}

.DayPicker {
  .chevron-prev,
  .chevron-next {
    stroke-width: 1;
    stroke:${ShColors.black};
    height: 1rem;
    width: 1rem;
    transition: transform 125ms ease-in;
  }
  .DayPickerNavigation_button {
    .prev,
    .next {
      cursor: pointer;
      position: absolute;
      top: 18px;
      line-height: 0.78;
      border-radius: 3px;
      padding: 6px 9px;
    }
    .prev {
      left: 22px;
      margin-right: 1em;
      &:hover {
        .chevron-prev {
          transform: translateX(-25%);
          transition-timing-function: ease-out;
        }
      }
    }
    .next {
      right: 22px;
      margin-left: 1em;
      &:hover {
        .chevron-next {
          transform: translateX(25%);
          transition-timing-function: ease-out;
        }
      }
    }
  }
  .CalendarMonth_caption {
    padding-top: 22px;
    padding-bottom: 57px;
    color: ${ShColors.black};
    font-family: ${ShFonts.secondary};
    font-weight: 400;
    font-size: 16px;
    margin-left: 40px;
    margin-right: 40px;
  }
}
}
`;
