/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import { ShColors, ShFonts } from '@shoootin/design-tokens';

import { ShVideoComment, ShVideoCommentReplyForm } from '@shoootin/api';
import { ShButton } from '../../designSystem/primitives/button/ShButton/ShButton';
import { SvgDelete, SvgPrivate, SvgPublic, SvgReply } from './ShVideoIcons';
import { secondsToDuration } from './ShVideoReviewUtils';
import { CommentEditForm } from './ShVideoReviewCommentForm';
import {
  CommentReplies,
  CommentReplyForm,
} from './ShVideoReviewCommentReplies';
import {
  CommentNameAndDate,
  CommentCompleted,
} from './ShVideoReviewCommentElements';
import { ShAutoLink } from '../../components/autoLink/autoLink';

export const Comment = ({
  comment,
  seekTo,
  removeComment,
  saveComment,
  addReply,
  deleteReply,
  toggleComplete,
  toggleInternal,
  isHovered,
}: {
  comment: ShVideoComment;
  seekTo: (s: number) => void;
  removeComment: (id: string) => void;
  saveComment: (newComment: ShVideoComment) => void;
  addReply: (id: string, reply: ShVideoCommentReplyForm) => void;
  deleteReply: (id: string, replyId: string) => void;
  toggleComplete: (id: string) => void;
  toggleInternal: (id: string) => void;
  isHovered: boolean;
}) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [reply, setReply] = useState<boolean>(false);
  const closeReply = () => setReply(false);

  const [hovered, setHovered] = useState<boolean>(false);

  const color = comment.internal ? ShColors.baseL : ShColors.white;
  return (
    <div
      css={{
        //borderBottom: `1px solid ${ShColors.black}`,
        marginBottom: 10,
        backgroundColor: ShColors.blackLL,
        fontSize: 14,
        color,
        border: isHovered
          ? `1px solid ${ShColors.baseL}`
          : `1px solid ${ShColors.blackLL}`,
        transition: 'border-color 0.2s ease-in-out',
      }}
    >
      <div css={{ padding: 10 }}>
        <div css={{ display: 'flex', justifyContent: 'space-between' }}>
          <CommentNameAndDate
            userName={comment.userName}
            date={comment.date}
            userType={comment.userType}
            color={color}
          />
          <CommentCompleted comment={comment} toggleComplete={toggleComplete} />
        </div>
        {edit ? (
          <CommentEditForm
            value={comment.comment}
            closeEdit={() => setEdit(false)}
            saveComment={(value: string) => {
              console.log({ ...comment, comment: value });
              saveComment({ ...comment, comment: value });
            }}
          />
        ) : (
          <div>
            <div
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              onMouseOver={() => !hovered && setHovered(true)}
            >
              <div
                onClick={() => seekTo(comment.currentTime)}
                css={{
                  cursor: 'pointer',
                  marginBottom: 5,
                  paddingBottom: 5,
                  paddingTop: 5,
                }}
              >
                {comment.currentTime !== 0 && (
                  <small
                    css={{ fontFamily: ShFonts.secondary, paddingRight: 10 }}
                  >
                    {secondsToDuration(comment.currentTime)}
                  </small>
                )}
                {comment.comment.split('\n').map((line, i) => (
                  <span key={i}>
                    <ShAutoLink
                      text={line}
                      withBlankIcon={true}
                      trim={20}
                      color={comment.internal ? 'baseL' : 'white'}
                    />
                    <br />
                  </span>
                ))}
              </div>
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div>
                  {!reply && (
                    <ShButton
                      size={'s'}
                      variant={
                        comment.internal ? 'blackLLWithBaseLColor' : 'blackLL'
                      }
                      onClick={() => setReply(true)}
                    >
                      Reply
                    </ShButton>
                  )}
                </div>
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span
                    css={{
                      opacity: hovered ? 1 : 0,
                      transform: hovered
                        ? 'translateX(0px)'
                        : 'translateX(10px)',
                      transition: 'all 0.2s ease-in-out',
                    }}
                  >
                    <span
                      data-for="comments"
                      data-tip={'Edit'}
                      onClick={() => setEdit(true)}
                      css={{ padding: 5, cursor: 'pointer' }}
                    >
                      <SvgReply />
                    </span>
                    <span
                      data-for="comments"
                      data-tip={'Remove'}
                      onClick={() => removeComment(comment.id)}
                      css={{ padding: 5, cursor: 'pointer' }}
                    >
                      <SvgDelete />
                    </span>
                  </span>

                  <span
                    data-for="comments"
                    data-tip={
                      comment.internal
                        ? 'Make comment public'
                        : 'Make comment private'
                    }
                    onClick={() => toggleInternal(comment.id)}
                    css={{ padding: 5, cursor: 'pointer' }}
                  >
                    {comment.internal ? <SvgPrivate /> : <SvgPublic />}
                  </span>
                </div>
              </div>
            </div>
            {reply && (
              <CommentReplyForm
                commentId={comment.id}
                addReply={addReply}
                closeReply={closeReply}
              />
            )}
          </div>
        )}
      </div>

      <CommentReplies
        comment={comment}
        seekTo={seekTo}
        addReply={addReply}
        deleteReply={deleteReply}
        color={color}
      />
    </div>
  );
};
