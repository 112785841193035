/** @jsx jsx */
import { jsx } from '@emotion/core';

import React, { ReactNode, useCallback, useState } from 'react';
import { pickImage, loadImageBlob } from '../../utils/ShFileUtilsDOM';
import {
  ShButtonAsync,
  ShButtonAsyncProps,
} from '../../designSystem/primitives/button/ShButton/ShButtonAsync';
import { ShImageCropModal } from './ShImageCropModal';
import {
  ShButton,
  ShButtonProps,
} from '../../designSystem/primitives/button/ShButton/ShButton';

// Add missing infos of the blob to look like the original FS file
// ie we try to preserve the file name
function blobToFile(blob: Blob, originalFile: File): File {
  // @ts-ignore
  blob.lastModified = originalFile.lastModified;
  // @ts-ignores
  blob.name = originalFile.name;
  return blob as File;
}

export type ShImagePickerButtonProps = Omit<ShButtonProps, 'onClick'> & {
  cropper?: boolean;
  onComplete: ({ imageFile }: { imageFile: File }) => void;
  title: ReactNode;
};

export const ShImagePickerButton = ({
  cropper = true,
  onComplete,
  title,
  ...props
}: ShImagePickerButtonProps) => {
  const [cropImage, setCropImage] = useState<{
    file: File;
    image: HTMLImageElement;
  } | null>(null);

  const closeCropModal = useCallback(() => {
    setCropImage(null);
  }, [setCropImage]);

  return (
    <React.Fragment>
      <ShButton
        {...props}
        onClick={async () => {
          const result = await pickImage();
          if (!result.cancelled) {
            if (cropper) {
              const image = await loadImageBlob(result.imageFile);
              setCropImage({
                file: result.imageFile,
                image,
              });
            } else {
              await onComplete({ imageFile: result.imageFile });
            }
          }
        }}
      />
      {!!cropImage && (
        <ShImageCropModal
          size="l"
          title={title}
          src={cropImage.image.src}
          onClose={closeCropModal}
          onComplete={async ({ croppedImage }) => {
            const imageFile = blobToFile(croppedImage, cropImage.file);
            try {
              await onComplete({ imageFile });
              closeCropModal();
            } catch (e) {
              // TODO handle errors
              console.error(e);
            }
          }}
        />
      )}
    </React.Fragment>
  );
};
