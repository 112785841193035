/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useRef, useState } from 'react';
import {
  ShPartialVideoReviewUser,
  ShPostprodVideoReviewApi,
  ShVideoClientDTO,
  ShVideoComment,
  ShVideoProgress,
} from '@shoootin/api';
import { ShColors } from '@shoootin/design-tokens';
import { ShVideoPlayerReview } from '../../components/video/VideoPlayer';

import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Comment } from './ShVideoReviewComments';
import { useVideoReviewState } from './ShVideoReviewState';
import { VideoActions } from './ShVideoReviewPlayerUtils';
import { Header } from './ShVideoReviewHeader';
import { VideoTimelineComments } from './ShVideoReviewCommentsTimeLine';
import { AddCommentForm } from './ShVideoReviewCommentForm';
import { Tooltips } from './ShVideoReviewTooltips';
import { CommentHeader, HoveredComment } from './ShVideoReviewCommentElements';
import { ProgressBar } from './ShVideoReviewPlayerProgressBar';
import { ShVideoReviewUser } from '@shoootin/config';
import { ShoootinActions } from './ShVideoReviewActions';
import { useAsync } from 'react-async-hook';
import { ShSpinner } from '../../designSystem/primitives/spinner/ShSpinner/ShSpinner';
import { ShSpinnerOverlay } from '../../designSystem/primitives/spinner/ShSpinnerOverlay/ShSpinnerOverlay';

const useCommentHover = () => {
  const [hovered, setHovered] = useState<ShVideoComment | undefined>(undefined);

  const [timerId, setTimerId] = useState<any>(undefined);

  const closeHover = () => {
    const timerId = setTimeout(() => setHovered(undefined), 1000);
    setTimerId(timerId);
  };

  const clearHoverTimeout = () => clearTimeout(timerId);
  const setCommentHovered = (comment: ShVideoComment) => {
    console.log('setCommentHovered');
    clearHoverTimeout();
    setHovered(comment);
  };

  return {
    hovered,
    closeHover,
    setCommentHovered,
  };
};

const useVideoLayout = (isReady: boolean) => {
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  const [commentDisplay, setCommentDisplay] = useState<boolean>(true);
  const toggleCommentDisplay = () => setCommentDisplay((s) => !s);

  useEffect(() => {
    const { width, height } = ref!.current!.getBoundingClientRect();
    setWidth(width);
    setHeight(height);
    console.log('useVideoLayout', { height, width });
  }, [width, height, commentDisplay, isReady]);

  return {
    ref,
    width,
    commentDisplay,
    toggleCommentDisplay,
  };
};

const COMMENT_WIDTH = 400;

export const ShVideoReview = ({
  videoId,
  userType,
}: {
  videoId: string;
  userType: ShPartialVideoReviewUser;
}) => {
  const player = useRef<any>();
  const {
    video,
    actions,
    videoPlayer: { videoPlayerState, setVideoPlayerState },
    comments: { comments, openCommentsNb, allCommentsNb, loading },
    filter: { filterCompleted, toggleFilterCompleted },
    commentsApi,
    videoApi,
  } = useVideoReviewState(videoId, userType, player);

  const {
    isReady,
    setReady,
    duration,
    getCurrentTime,
    seekTo,
    videoPlaying,
    playVideo,
    pauseVideo,
    volume,
    setVolume,
    muted,
    setMuted,
  } = actions;

  const handle = useFullScreenHandle();

  const { hovered, closeHover, setCommentHovered } = useCommentHover();

  const { ref, width, commentDisplay, toggleCommentDisplay } = useVideoLayout(
    isReady,
  );

  return (
    <div
      css={{
        backgroundColor: ShColors.blackL,
        color: ShColors.white,
        minHeight: '100vh',
      }}
    >
      <Tooltips />
      <div>
        <Header
          video={video}
          openCommentsNb={openCommentsNb}
          allCommentsNb={allCommentsNb}
          toggleCommentDisplay={toggleCommentDisplay}
          commentDisplay={commentDisplay}
          userType={userType}
        />

        <div css={{ display: 'flex' }}>
          <div
            css={{
              width: commentDisplay
                ? `calc(100% - ${COMMENT_WIDTH}px)`
                : '100%',
              transition: 'width 0.2s ease-in-out',
              padding: 10,
              display: 'flex',
              flexDirection: 'column',
              paddingTop: 0,
            }}
          >
            <div
              css={{
                display: 'flex',
                // flexGrow: 1,
                // alignItems: 'center',
                justifyContent: 'center',
                marginBottom: 10,
              }}
            >
              <FullScreen handle={handle} css={{ width: '100%' }}>
                <div
                  ref={ref}
                  css={{
                    position: 'relative',
                    width: '100%',
                  }}
                  onClick={() => {
                    if (videoPlaying) {
                      pauseVideo();
                    } else {
                      playVideo();
                    }
                  }}
                >
                  {video && (
                    <ShVideoPlayerReview
                      ref={player}
                      height={'auto'}
                      url={video.url}
                      playing={videoPlaying}
                      controls={handle.active} //TODO remove
                      volume={volume}
                      muted={muted}
                      onProgress={setVideoPlayerState}
                      progressInterval={200}
                      onReady={() => setReady(true)}
                      onPause={() => pauseVideo()}
                      onPlay={() => playVideo()}
                    />
                  )}
                  {!video && (
                    <div
                      css={{
                        width: '100%',
                        padding: 100,
                      }}
                    >
                      <ShSpinner size={'s'} color={'whiteD'} />
                    </div>
                  )}
                  <div
                    css={{
                      position: 'absolute',
                      bottom: 0,
                      width: '100%',
                    }}
                  >
                    {hovered && (
                      <HoveredComment
                        width={width}
                        seekTo={seekTo}
                        duration={duration}
                        closeHover={closeHover}
                        hovered={hovered}
                        setCommentHovered={setCommentHovered}
                      />
                    )}
                  </div>
                </div>

                <div
                  css={{
                    borderTop: 0,
                    marginTop: -17,
                  }}
                >
                  {video && (
                    <ProgressBar
                      loaded={videoPlayerState.loaded}
                      played={videoPlayerState.played}
                      duration={duration}
                      seekTo={seekTo}
                      sprite={video.sprite}
                      width={width}
                    />
                  )}
                  {comments && (
                    <VideoTimelineComments
                      comments={comments}
                      duration={duration}
                      seekTo={seekTo}
                      setHovered={setCommentHovered}
                      closeHover={closeHover}
                      width={width}
                    />
                  )}
                  <VideoActions
                    videoPlaying={videoPlaying}
                    duration={duration}
                    handle={handle}
                    muted={muted}
                    pauseVideo={pauseVideo}
                    playedSeconds={videoPlayerState.playedSeconds}
                    playVideo={playVideo}
                    setMuted={setMuted}
                    setVolume={setVolume}
                    volume={volume}
                  />
                </div>
              </FullScreen>
            </div>

            <AddCommentForm
              addComment={commentsApi.addComment}
              pauseVideo={pauseVideo}
              playVideo={playVideo}
              videoPlaying={videoPlaying}
              getCurrentTime={getCurrentTime}
            />
          </div>
          <div
            css={{
              width: commentDisplay ? COMMENT_WIDTH : 0,
              transition: 'width 0.2s ease-in-out',
            }}
          >
            <div>
              {video && (
                <div>
                  {userType === 'shoootin' && (
                    <ShoootinActions
                      stuckVideo={videoApi.stuckVideo}
                      changesVideo={videoApi.changesVideo}
                    />
                  )}
                </div>
              )}

              <CommentHeader
                filterCompleted={filterCompleted}
                toggleFilterCompleted={toggleFilterCompleted}
                allCommentsNb={allCommentsNb}
                openCommentsNb={openCommentsNb}
              />

              <div
                css={{
                  maxHeight: 'calc(100vh - 110px)',
                  overflow: 'scroll',
                  position: 'relative',
                }}
              >
                {comments.map((comment: ShVideoComment) => (
                  <Comment
                    key={comment.id}
                    isHovered={hovered?.id === comment.id}
                    comment={comment}
                    seekTo={seekTo}
                    removeComment={commentsApi.deleteComment}
                    saveComment={commentsApi.addComment}
                    addReply={commentsApi.addReply}
                    deleteReply={commentsApi.deleteReply}
                    toggleComplete={commentsApi.toggleComplete}
                    toggleInternal={commentsApi.toggleInternal}
                  />
                ))}
                <ShSpinnerOverlay
                  visible={loading}
                  size={'xs'}
                  color={'whiteD'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
