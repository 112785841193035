import {
  ShIntlMessageDefinition,
  getShMessage,
  getShMessageUnsafe,
} from '@shoootin/translations';
import {
  ShOrderHelpModalName,
  ShOrderHelpModalNames,
  ShPhotographerAvailabilities,
  ShPhotographerAvailability,
  ShPhotographerTransportations,
  ShPhotographerTransportation,
} from '@shoootin/config';

type EnumDefinition<EnumKey extends string> = {
  list: Readonly<EnumKey[]>;
  messages: { [key in EnumKey]: ShIntlMessageDefinition };
};

const getEnumDefinition = (
  prefix: string,
  key: string,
): ShIntlMessageDefinition => {
  const id = `${prefix}_${key}`;
  return getShMessageUnsafe(id);
};

const createEnumDefinition = <EnumKey extends string>(
  prefix: string,
  list: Readonly<EnumKey[]>,
): EnumDefinition<EnumKey> => {
  const messages: any = {};
  list.forEach((key) => {
    messages[key] = getEnumDefinition(prefix, key);
  });
  return { list, messages };
};

export const ShEnumPhotographerAvailability: EnumDefinition<ShPhotographerAvailability> = createEnumDefinition(
  'enums_photographerAvailabilities',
  ShPhotographerAvailabilities,
);

export const ShEnumPhotographerTransportation: EnumDefinition<ShPhotographerTransportation> = createEnumDefinition(
  'enums_photographerTransportation',
  ShPhotographerTransportations,
);

export const ShEnumOrderHelpModalTitle: EnumDefinition<ShOrderHelpModalName> = createEnumDefinition(
  'enums_OrderHelpModalName_title',
  ShOrderHelpModalNames,
);

export const ShEnumOrderHelpModalContent: EnumDefinition<ShOrderHelpModalName> = createEnumDefinition(
  'enums_OrderHelpModalName',
  ShOrderHelpModalNames,
);
