/** @jsx jsx */
import { jsx } from '@emotion/core';
import ReactTooltip from 'react-tooltip';
import React from 'react';
import { ShColors } from '@shoootin/design-tokens';

type Offset = {
  top?: number;
  right?: number;
  left?: number;
  bottom?: number;
};

export const ShTooltip = ({
  id,
  place = 'top',
  offset,
  html = false,
  delayShow,
  backgroundColor = ShColors.base,
}: {
  id: string;
  place?: 'top' | 'right' | 'bottom' | 'left';
  offset?: Offset;
  html?: boolean;
  delayShow?: number;
  backgroundColor?: string;
}) => {
  return (
    <ReactTooltip
      id={id}
      place={place}
      textColor={'white'}
      backgroundColor={backgroundColor}
      offset={offset}
      type="info"
      effect="solid"
      getContent={(dataTip: string) => dataTip}
      html={html}
      multiline={true}
      delayShow={delayShow}
    />
  );
};
