/** @jsx jsx */
import { jsx } from '@emotion/core';
import './ShIntercomArticlesStyles';
import { ShColors } from '@shoootin/design-tokens';
import {
  ShModal,
  ShModalSize,
} from '../../designSystem/primitives/modal/ShModal';
import { ShHr } from '../../designSystem/primitives/ShHtmlElements/ShHtmlElements';
import { ShIntercomArticleDTO, ShIntercomArticlesAPI } from '@shoootin/api';
import { useShIntlLanguage } from '@shoootin/translations';
import { ShSpinner } from '../../designSystem/primitives/spinner/ShSpinner/ShSpinner';
import { ReactNode, useState } from 'react';

const useIntercomArticle = (id: number | string, lang: string) => {
  const [opened, setOpened] = useState<boolean>(false);
  const [article, setArticle] = useState<ShIntercomArticleDTO | undefined>(
    undefined,
  );

  const loadArticle = async () => {
    setOpened(true);
    if (!article) {
      try {
        const result = await ShIntercomArticlesAPI.getArticle(id, lang);
        setArticle(result.article);
      } catch (e) {
        setArticle(undefined);
        setOpened(false);
      }
    }
  };

  const closeModal = () => {
    setOpened(false);
  };

  return {
    state: { opened, article },
    api: { loadArticle, closeModal },
  };
};

export const ShIntercomArticles = ({
  id,
  children,
  size = 'l',
}: {
  id: number | string;
  children: ReactNode;
  size?: ShModalSize;
}) => {
  const lang = useShIntlLanguage();

  const {
    state: { opened, article },
    api: { loadArticle, closeModal },
  } = useIntercomArticle(id, lang);

  return (
    <div onClick={loadArticle}>
      {children}
      {opened && (
        <ShModal
          size={size}
          title={article ? article.title : ''}
          content={
            article ? (
              <ShIntercomModalContent article={article} />
            ) : (
              <ShSpinner size={'s'} />
            )
          }
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export const ShIntercomModalContent = ({
  article,
}: {
  article: ShIntercomArticleDTO;
}) => {
  return (
    <div css={{ marginTop: 15 }}>
      {article.description && (
        <div>
          <div
            css={{ color: ShColors.blackLL, paddingBottom: 10, fontSize: 16 }}
          >
            {article.description}
          </div>
          <ShHr mini />
        </div>
      )}
      <div className={'intercom-articles'}>
        <div dangerouslySetInnerHTML={{ __html: article.body }} />
      </div>
    </div>
  );
};
