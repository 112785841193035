/** @jsx jsx */
import { jsx } from '@emotion/core';
import { transparentize } from 'polished';
import { ShColors, ShEasings } from '@shoootin/design-tokens';
import useHtmlForId from '../../../utils/hooks/useHtmlForId';

const rem = (value: number) => `${value / 16}rem`;
const trackHeight = rem(20);
const trackWidth = rem(40);
const thumbSize = rem(24);
const thumbOffset = rem((20 - 24) / 2);

export const ShToggle = ({
  value = false,
  onChange,
}: {
  value?: boolean;
  onChange?: (value: boolean) => void;
}) => {
  const id = useHtmlForId();
  return (
    <div css={{ position: 'relative', display: 'flex' }}>
      <input
        type="checkbox"
        id={`checkbox${id}`}
        checked={value}
        readOnly
        onClick={() => {
          onChange && onChange(!value);
        }}
        css={{
          height: 0,
          width: 0,
          visibility: 'hidden',
        }}
      />
      <div>
        <label
          htmlFor={`checkbox${id}`}
          css={{
            display: 'block',
            position: 'relative',
            // top: -10,
            // left: '5px',
            height: trackHeight,
            width: trackWidth,
            cursor: 'pointer',
            backgroundColor: transparentize(0.93, ShColors.black),
            borderRadius: rem(10),
            transition: `background-color 100ms ${ShEasings.ease}`,
            WebkitTapHighlightColor: ShColors.transparent,

            ...(value && {
              backgroundColor: transparentize(0.8, ShColors.black),
            }),
          }}
        />
        <div
          onClick={() => {
            onChange && onChange(!value);
          }}
          css={{
            position: 'absolute',
            opacity: 1,
            top: thumbOffset,
            left: thumbOffset,
            height: thumbSize,
            width: thumbSize,
            cursor: 'pointer',
            background: ShColors.white,
            borderRadius: rem(12),
            boxShadow: '0 0 1rem 0 ' + transparentize(0.75, ShColors.black),
            transition: `transform 100ms ${ShEasings.ease}`,
            ...(value && {
              transform: `translateX(${rem(18)})`,
            }),
          }}
        />
      </div>
    </div>
  );
};
