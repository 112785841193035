/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import { ShText, ShTextHTML, useShTranslate } from '@shoootin/translations';
import { ShModal } from '@shoootin/components-web';
import { ShColors, ShFonts } from '@shoootin/design-tokens';
import { ShOrderHelpModalName } from '@shoootin/config';
import {
  ShEnumOrderHelpModalContent,
  ShEnumOrderHelpModalTitle,
} from '../../utils/enums/ShEnums';

export const ShHelpModalDiv = ({ onClick }: { onClick?: () => void }) => {
  return (
    <div
      css={{
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: ShFonts.primary,
        fontSize: 12,
        height: 23,
        width: 23,
        paddingTop: 1.5,
        border: `1px solid ${ShColors.pattern}`,
        borderRadius: '50%',
        boxSizing: 'border-box',
        cursor: 'pointer',
        transition: 'border-color .1s cubic-bezier(.4,0,.2,1)',
      }}
      onClick={onClick}
    >
      ?
    </div>
  );
};

export const ShHelpModalButton = ({
  modalName,
}: {
  modalName: ShOrderHelpModalName;
}) => {
  const title = ShEnumOrderHelpModalTitle.messages[modalName];
  const content = ShEnumOrderHelpModalContent.messages[modalName];

  const [opened, setOpened] = useState<boolean>(false);
  if (false) {
    //TODO put back when translations are ready
    return <React.Fragment />;
  } else {
    return (
      <React.Fragment>
        <ShHelpModalDiv onClick={() => setOpened(true)} />

        {opened && (
          <ShModal
            size={'m'}
            onClose={() => {
              console.log({ opened });
              setOpened(false);
              console.log({ opened });
            }}
            title={<ShText message={title} />}
            content={
              <div css={{ paddingTop: 20 }}>
                <ShTextHTML message={content} />
              </div>
            }
          />
        )}
      </React.Fragment>
    );
  }
};
