/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import { ShColors } from '@shoootin/design-tokens';
import { ShButtonAsync } from '../../designSystem/primitives/button/ShButton/ShButtonAsync';
import { ShButton } from '../../designSystem/primitives/button/ShButton/ShButton';
import { ShInputTextarea } from '../../designSystem/primitives/input/ShInputTextarea/ShInputTextarea';

export const ShoootinActions = ({
  stuckVideo,
  changesVideo,
}: {
  stuckVideo: (message: string) => void;
  changesVideo: (message: string) => void;
}) => {
  const [action, setAction] = useState<string | undefined>(undefined);

  const cancel = () => setAction(undefined);
  return (
    <div
      css={{
        marginBottom: 10,
        backgroundColor: ShColors.blackLL,
        padding: 10,
        fontSize: 14,
      }}
    >
      <div css={{ marginBottom: 10 }}>
        <b>Shoootin actions : </b>
        {action === 'stuck' && <span>Stuck this video</span>}
        {action === 'changes' && <span>Send to changes</span>}
      </div>
      {!action && (
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {/*<ShButton onClick={() => setAction('validate')}>
            Validate comments
          </ShButton>*/}
          <ShButton variant={'changes'} onClick={() => setAction('changes')}>
            Changes
          </ShButton>
          <ShButton variant={'stuck'} onClick={() => setAction('stuck')}>
            Stuck
          </ShButton>
        </div>
      )}
      {action === 'stuck' && (
        <ShoootinActionStuck stuckVideo={stuckVideo} cancel={cancel} />
      )}
      {action === 'changes' && (
        <ShoootinActionChanges changesVideo={changesVideo} cancel={cancel} />
      )}
    </div>
  );
};

const ShoootinActionStuck = ({
  stuckVideo,
  cancel,
}: {
  stuckVideo: (message: string) => void;
  cancel: () => void;
}) => {
  const [value, setValue] = useState<string>('');
  return (
    <div>
      <div css={{ marginBottom: 10 }}>
        <ShInputTextarea
          themeSize={'s'}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          placeholder={'Why is the video stuck ?'}
          rows={2}
        />
      </div>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <ShButton variant={'red'} onClick={cancel}>
          Cancel
        </ShButton>
        <ShButtonAsync
          disabled={value === ''}
          onClick={() => {
            stuckVideo(value);
            cancel();
          }}
          css={{ marginRight: 10 }}
        >
          Validate
        </ShButtonAsync>
      </div>
    </div>
  );
};

const ShoootinActionChanges = ({
  changesVideo,
  cancel,
}: {
  changesVideo: (message: string) => void;
  cancel: () => void;
}) => {
  const [value, setValue] = useState<string>('');
  return (
    <div>
      <div css={{ marginBottom: 10 }}>
        <ShInputTextarea
          themeSize={'s'}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          placeholder={'General comment'}
          rows={2}
        />
      </div>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <ShButton variant={'red'} onClick={cancel}>
          Cancel
        </ShButton>
        <ShButtonAsync
          onClick={() => {
            changesVideo(value);
            cancel();
          }}
          css={{ marginRight: 10 }}
        >
          Validate
        </ShButtonAsync>
      </div>
    </div>
  );
};
