/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ReactNode } from 'react';

export type ShOverlayProps = {
  zIndex?: number; // Try to avoid z-index when possible
  visible: boolean;
  children: () => ReactNode;
  popDuration?: number;
  opacity?: number;
};
export const ShOverlay = ({
  zIndex,
  visible,
  children,
  popDuration = 0.6,
  opacity = 0.3,
}: ShOverlayProps) => (
  <div
    css={{
      position: 'absolute',
      zIndex,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity: 0,
      transition: `opacity ${popDuration}s`,
      backgroundColor: `rgba(0,0,0,${opacity})`,
      pointerEvents: 'none',
      ...(visible && {
        opacity: 1,
        pointerEvents: 'auto',
      }),
    }}
  >
    {children()}
  </div>
);
