import React from 'react';
import { ShVideoSpriteClientDTO } from '@shoootin/api';

export const secondsToDuration = (seconds: number): string => {
  if (seconds > 3600) {
    return new Date(seconds * 1000).toISOString().substr(11, 8);
  } else {
    return new Date(seconds * 1000).toISOString().substr(14, 5);
  }
};

export const truncateString = (str: string, num: number) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
};

export const TIMELINE_LOGO_WIDTH = 20;
export const getTimelinePosition = (
  width: number,
  position?: number,
): string => {
  if (position) {
    if (position * width < TIMELINE_LOGO_WIDTH / 2) {
      return `${position * width}px`;
    } else if (position * width > width - TIMELINE_LOGO_WIDTH) {
      return `${width - TIMELINE_LOGO_WIDTH}px`;
    } else {
      return `calc(${position * 100}% - 10px)`;
    }
  } else {
    return '0px';
  }
};

export const TIMELINE_TOOLTIP_WIDTH = 220;
export const getTooltipPosition = (
  width: number,
  position?: number,
): string => {
  if (position) {
    if (position * width < TIMELINE_TOOLTIP_WIDTH/2) {
      return `${position * width}px`;
    } else if (position * width > width - TIMELINE_TOOLTIP_WIDTH) {
      return `${width - TIMELINE_TOOLTIP_WIDTH}px`;
    } else {
      return `calc(${position * 100}% - 100px)`;
    }
  } else {
    return '0px';
  }
};

export const getProgressBarIndicatorPosition = (
  width: number,
  position: number,
): string => {
  if (position * width < 2) {
    return `${position * width}px`;
  } else if (position * width > width - 4) {
    return `${width - 4}px`;
  } else {
    return `calc(${position * 100}% - 2px)`;
  }
};
// if 100 stripe for 180 seconds, it means 1 stripe per 1,8 seconds
// so at seconds 18 => seconds/(180/100)* each sprite height

//TODO depends on the number of thumnbails
export const getProgressBarSpriteYPosition = (
  seconds: number,
  duration: number,
  sprite: ShVideoSpriteClientDTO,
) => {
  return (
    (Math.floor(seconds / (duration / sprite.nb)) * sprite.height) / sprite.nb
  );
};

export const PROGRESSBAR_SPRITE_WIDTH = 180;
export const getProgressBarSpriteTooltipPosition = (
  width: number,
  position: number,
): string => {
  if (position * width < PROGRESSBAR_SPRITE_WIDTH / 2) {
    return `0px`;
  } else if (position * width > width - PROGRESSBAR_SPRITE_WIDTH / 2) {
    return `${width - PROGRESSBAR_SPRITE_WIDTH}px`;
  } else {
    return `calc(${position * 100}% - ${PROGRESSBAR_SPRITE_WIDTH / 2}px)`;
  }
};

export const PROGRESSBAR_CURRENT_TIME_WIDTH = 40;
export const getProgressBarCurrentTimeTooltipPosition = (
    width: number,
    position: number,
): string => {
  if (position * width < PROGRESSBAR_CURRENT_TIME_WIDTH / 2) {
    return `0px`;
  } else if (position * width > width - PROGRESSBAR_CURRENT_TIME_WIDTH / 2) {
    return `${width - PROGRESSBAR_CURRENT_TIME_WIDTH}px`;
  } else {
    return `calc(${position * 100}% - ${PROGRESSBAR_CURRENT_TIME_WIDTH / 2}px)`;
  }
};
