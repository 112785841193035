import React from 'react';
import { SvgProps } from './SvgUtils';
import { ShColors } from '@shoootin/design-tokens';

export const LogoSvg = ({ width = 437, color = 'black' }: SvgProps) => (
  <svg
    height={width * 0.1968}
    width={width}
    viewBox="0 0 437 86"
    stroke={ShColors[color]}
    fill={ShColors[color]}
  >
    <path d="M216.824 84.947c-16.958 0-30.859-13.803-30.859-30.859 0-16.958 13.803-30.859 30.859-30.859 16.958 0 30.859 13.803 30.859 30.859-.099 16.958-13.901 30.859-30.859 30.859zm0-48.112c-9.465 0-17.254 7.69-17.254 17.254 0 9.465 7.69 17.254 17.254 17.254 9.465 0 17.254-7.69 17.254-17.254-.099-9.466-7.789-17.254-17.254-17.254zm65.761 48.112c-16.958 0-30.859-13.803-30.859-30.859 0-16.958 13.803-30.859 30.859-30.859 16.958 0 30.859 13.803 30.859 30.859-.099 16.958-13.902 30.859-30.859 30.859zm0-48.112c-9.465 0-17.254 7.69-17.254 17.254 0 9.465 7.69 17.254 17.254 17.254 9.465 0 17.254-7.69 17.254-17.254-.1-9.466-7.79-17.254-17.254-17.254zM151.063 84.947c-16.958 0-30.859-13.803-30.859-30.859 0-16.958 13.803-30.859 30.859-30.859s30.859 13.803 30.859 30.859c.001 16.958-13.901 30.859-30.859 30.859zm0-48.112c-9.465 0-17.254 7.69-17.254 17.254 0 9.465 7.69 17.254 17.254 17.254 9.465 0 17.254-7.69 17.254-17.254 0-9.466-7.789-17.254-17.254-17.254zM19.049 50.539C16.979 63.553 11.162 71.342.908 71.44v13.507c18.831 0 26.915-9.761 29.972-28.099 2.169-13.211 8.676-20.014 17.944-20.014V23.328c-17.352 0-26.817 8.972-29.775 27.211zm68.324-27.211c-6.211 0-13.408 2.169-18.634 7.592V4.004H55.43v80.254h13.31V55.666c0-12.423 7.69-19.127 16.859-19.127 9.268 0 16.859 5.718 16.859 18.634v28.986h13.31V53.99c0-19.916-11.733-30.662-28.395-30.662zm244.902 12.817h16.859V23.919h-16.859V4.004h-13.31v51.169c0 18.239 10.944 29.676 30.169 29.676V72.525c-11.535 0-16.859-8.085-16.859-19.521V36.145zm23.464-12.226h13.31v60.338h-13.31V23.919zm20.015 60.338h13.31V56.356c0-12.915 7.592-20.408 16.859-20.408s16.859 7.493 16.859 20.408v27.901h13.31V54.088c0-20.014-13.507-30.859-30.169-30.859s-30.169 10.746-30.169 30.859v30.169zM362.444.947a8.183 8.183 0 1 1 0 16.367 8.183 8.183 0 0 1 0-16.367z" />
  </svg>
);

export default LogoSvg;
