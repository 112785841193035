/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ReactNode } from 'react';
import {
  ShStyles,
  ShStylesFunc,
} from '../../../designSystem/constants/ShStyles';
import {
  ShColors,
  ShEasings,
  ShFonts,
  ShFontWeights,
  ShMediaQueries,
  ShRadius,
} from '@shoootin/design-tokens';

export type ShTileProps = {
  label: string | ReactNode;
  icon?: ReactNode;
  active?: boolean;
  onTilePress: () => void;
};

export const ShTile = ({ label, icon, active, onTilePress }: ShTileProps) => {
  return (
    <li
      onClick={onTilePress}
      css={{
        ...ShStyles.centered,
        display: 'inline-flex',
        flexDirection: 'column',
        position: 'relative',
        padding: ShStylesFunc.spacing(0.5),
        margin: `${0.625 * 1.25}rem ${ShStylesFunc.spacing(
          0.2,
        )} ${ShStylesFunc.spacing(0.2)}`,
        // paddingTop: '14px',
        // paddingBottom: '10px',
        // paddingLeft: '10px',
        // paddingRight: '10px',
        backgroundColor: ShColors.white,
        borderRadius: ShRadius.m,
        boxShadow: '0 0 1rem 0 rgba(0,0,0,.1)',
        boxSizing: 'border-box',
        cursor: 'pointer',
        userSelect: 'none',
        fontSize: 16,
        minWidth: 140,
        fontFamily: ShFonts.secondary,
        fontWeight: ShFontWeights.m,
        transition: `background-color 100ms ${ShEasings.easeIn},
                    color 100ms ${ShEasings.easeIn}`,
        [ShMediaQueries.ShMobileOnly]: {
          fontSize: 10,
          minWidth: 90,
        },
        [ShMediaQueries.ShDesktopsOnly]: {
          padding: ShStylesFunc.spacing(0.7),
        },
        svg: SvgStyle,

        '&:active': ActivedTileOption,

        ...(active && ActivedTileOption),

        ...(!icon && TextOnlyOption),
      }}
    >
      <div
        css={{
          ...ShStyles.overlay,
          pointerEvents: 'initial',
          opacity: 0,
          boxShadow: '0 0 2rem 0 rgba(0,0,0,.25)',
          borderRadius: 'inherit',
          transition: ` opacity 200ms ${ShEasings.easeIn}`,

          '&:hover': {
            opacity: active ? 0 : 0.5,
            transition: ` opacity 200ms ${ShEasings.easeOut}`,
          },
        }}
      />
      {icon}
      {label}
    </li>
  );
};

const SvgStyle = {
  width: ShStylesFunc.spacing(2),
  height: ShStylesFunc.spacing(2),
  margin: '0.25em 0 1em',
  transition: `stroke 100ms ${ShEasings.easeIn},
              fill 150ms ${ShEasings.easeOut}`,
  strokeWidth: 1,
  [ShMediaQueries.ShMobileOnly]: {
    width: ShStylesFunc.spacing(1.5),
    height: ShStylesFunc.spacing(1.5),
  },
};

const ActivedTileOption = {
  color: ShColors.white,
  backgroundColor: ShColors.base,
  transition: `background-color 150ms ${ShEasings.easeOut},
                  color 150ms ${ShEasings.easeOut}`,
  svg: {
    ...SvgStyle,
    stroke: ShColors.white,
    fill: ShColors.white,
  },
} as const;

const TextOnlyOption = {
  whiteSpace: 'nowrap',
  paddingRight: '30px',
  paddingLeft: '30px',
} as const;
