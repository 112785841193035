import React from 'react';
import { FormattedNumber } from 'react-intl';
import { ShCurrency } from '@shoootin/config';

export const ShPrice = ({
  price,
  currency,
}: {
  price: number;
  currency: ShCurrency;
}) => {
  return (
    <FormattedNumber
      value={price / 100.0}
      // eslint-disable-next-line react/style-prop-object
      style="currency"
      currency={currency as string}
      minimumIntegerDigits={1}
      minimumFractionDigits={0}
      maximumFractionDigits={2}
    />
  );
};
