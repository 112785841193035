import React from 'react';

const AmexSvg = ({ width = 66.316 }: { width?: number }) => (
  <svg viewBox="0 0 66.316 45.79" width={width} height={width * 0.6905}>
    <g>
      <g>
        <path
          fill="#5EC1EC"
          d="M64,37.792c0,2.75-2.25,5-5,5H9c-2.75,0-5-2.25-5-5v-30c0-2.75,2.25-5,5-5h50c2.75,0,5,2.25,5,5V37.792z"
        />
        <g>
          <path
            fill="#FFFFFF"
            d="M21.555,27.713l-0.457-1.74h-3.015l-0.47,1.74h-2.755l3.027-9.318h3.346l3.064,9.318H21.555z
					 M20.578,23.917l-0.4-1.523c-0.094-0.338-0.206-0.775-0.34-1.313c-0.133-0.538-0.221-0.923-0.264-1.155
					c-0.037,0.216-0.113,0.571-0.226,1.066c-0.111,0.495-0.36,1.471-0.746,2.925H20.578L20.578,23.917z"
          />
          <path
            fill="#FFFFFF"
            d="M29.344,27.713l-1.897-6.686h-0.058c0.089,1.138,0.133,2.022,0.133,2.654v4.03h-2.221v-9.28h3.338
					l1.938,6.591h0.051l1.897-6.591h3.346v9.28h-2.305v-4.069c0-0.211,0.004-0.444,0.01-0.703c0.006-0.259,0.035-0.892,0.086-1.898
					h-0.058l-1.872,6.672H29.344L29.344,27.713z"
          />
          <path
            fill="#FFFFFF"
            d="M43.119,27.713h-5.51v-9.281h5.51v2.013h-3.004v1.46h2.781v2.012h-2.781v1.752h3.004V27.713z"
          />
          <path
            fill="#FFFFFF"
            d="M53.103,27.713h-2.898l-1.812-2.9l-1.789,2.9h-2.838l3.078-4.749l-2.895-4.533h2.781l1.676,2.869
					l1.611-2.869h2.863l-2.939,4.729L53.103,27.713z"
          />
        </g>
      </g>
      <g>
        <path
          fill="#9BCEE0"
          d="M59,3.791c2.207,0,4,1.794,4,4v30c0,2.205-1.793,4-4,4H9c-2.206,0-4-1.795-4-4v-30c0-2.206,1.794-4,4-4
				H59 M59,2.791H9c-2.75,0-5,2.25-5,5v30c0,2.75,2.25,5,5,5h50c2.75,0,5-2.25,5-5v-30C64,5.041,61.75,2.791,59,2.791L59,2.791z"
        />
      </g>
    </g>
  </svg>
);

export default AmexSvg;
