/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ComponentType } from 'react';

import { SvgProps } from '../../../../../components/svg/SvgUtils';

export const ShInputIcon = ({
  component: Component,
}: {
  component: ComponentType<SvgProps>;
}) => <Component width={24} color={'disabledText'} />;
