/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { ComponentType, ReactHTML } from 'react';
import { ShAutoLink } from '../../../../components/autoLink/autoLink';

export const ShMultiline = ({
  text,
  component: Component = 'div',
  autoLink = false,
}: {
  text: string;
  component?: ComponentType<any> | keyof ReactHTML;
  autoLink?: boolean;
}) => {
  return (
    <React.Fragment>
      {text &&
        text.split('\n').map((each, index) =>
          autoLink ? (
            <Component key={each + '' + index}>
              <ShAutoLink text={each} />
            </Component>
          ) : (
            <Component key={each + '' + index}>{each}</Component>
          ),
        )}
    </React.Fragment>
  );
};
