/** @jsx jsx */
import React from 'react';
import { CSSObject, jsx } from '@emotion/core';
import { useShTheme } from '../../ShTheme';

export const ShInputSizes = ['xxs', 'xs', 's', 'm', 'l'] as const;
export type ShInputSize = typeof ShInputSizes[number];
type ShInputSizeConfig = {
  inputCss: CSSObject;
  dateRangePickerInputCss: CSSObject;
  selectCss: {
    control: CSSObject;
    singleValue: CSSObject;
  };
  textAreaCSS: CSSObject;
};

export const ShInputSizeConfigs: {
  [key in ShInputSize]: ShInputSizeConfig;
} = {
  xxs: {
    inputCss: {
      padding: '3px 4px',
      paddingRight: 0,
      fontSize: 14,
    },
    dateRangePickerInputCss: {
      padding: '5px 3px 4px',
    },
    selectCss: {
      control: { padding: '4px 7px' },
      singleValue: { padding: 3 },
    },
    textAreaCSS: {
      fontSize: 12,
    },
  },
  xs: {
    inputCss: {
      padding: '5px 7px',
    },
    dateRangePickerInputCss: {
      padding: '5px 3px 4px',
    },
    selectCss: {
      control: { padding: '4px 7px' },
      singleValue: { padding: 3 },
    },
    textAreaCSS: {
      fontSize: 12,
    },
  },
  s: {
    inputCss: {
      padding: '9px 10px',
    },
    dateRangePickerInputCss: {
      padding: '9px 5px 7px',
    },
    selectCss: {
      control: { padding: '8px 10px' },
      singleValue: { padding: 3 },
    },
    textAreaCSS: {
      fontSize: 14,
    },
  },
  m: {
    inputCss: {
      padding: '12px 10px',
    },
    dateRangePickerInputCss: {
      padding: '12px 5px 10px',
    },
    selectCss: {
      control: { padding: '11.5px 10px' },
      singleValue: { padding: '3px 6px' },
    },
    textAreaCSS: {
      fontSize: 16,
    },
  },
  l: {
    inputCss: {
      padding: '18px 14px',
    },
    dateRangePickerInputCss: {
      padding: '18px 5px 16px',
    },
    selectCss: {
      control: { padding: '17.5px 14px' },
      singleValue: { padding: '3px 14px' },
    },
    textAreaCSS: {
      fontSize: 16,
    },
  },
};

export const useShInputSize = (sizeProp?: ShInputSize): ShInputSize => {
  const defaultSize = useShTheme().input.size;
  return sizeProp ? sizeProp : defaultSize;
};
