import React from 'react';

const BadgeGooglePlaySvgEN = ({ height = 64 }: { height?: number }) => (
  <svg viewBox="0 0 135 40" height={height}>
    <defs id="defs316">
      <linearGradient
        id="a"
        x1="31.799999"
        y1="183.28999"
        x2="15.02"
        y2="166.50999"
        gradientTransform="matrix(1,0,0,-1,-10,192)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#00a0ff" id="stop278" />
        <stop offset=".01" stopColor="#00a1ff" id="stop280" />
        <stop offset=".26" stopColor="#00beff" id="stop282" />
        <stop offset=".51" stopColor="#00d2ff" id="stop284" />
        <stop offset=".76" stopColor="#00dfff" id="stop286" />
        <stop offset="1" stopColor="#00e3ff" id="stop288" />
      </linearGradient>
      <linearGradient
        id="b"
        x1="43.830002"
        y1="172"
        x2="19.639999"
        y2="172"
        gradientTransform="matrix(1,0,0,-1,-10,192)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#ffe000" id="stop291" />
        <stop offset=".41" stopColor="#ffbd00" id="stop293" />
        <stop offset=".78" stopColor="orange" id="stop295" />
        <stop offset="1" stopColor="#ff9c00" id="stop297" />
      </linearGradient>
      <linearGradient
        id="c"
        x1="34.830002"
        y1="169.7"
        x2="12.07"
        y2="146.95"
        gradientTransform="matrix(1,0,0,-1,-10,192)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#ff3a44" id="stop300" />
        <stop offset="1" stopColor="#c31162" id="stop302" />
      </linearGradient>
      <linearGradient
        id="d"
        x1="17.299999"
        y1="191.82001"
        x2="27.459999"
        y2="181.66"
        gradientTransform="matrix(1,0,0,-1,-10,192)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#32a071" id="stop305" />
        <stop offset=".07" stopColor="#2da771" id="stop307" />
        <stop offset=".48" stopColor="#15cf74" id="stop309" />
        <stop offset=".8" stopColor="#06e775" id="stop311" />
        <stop offset="1" stopColor="#00f076" id="stop313" />
      </linearGradient>
    </defs>
    <title id="title318">fil_get</title>
    <path fill="none" d="M -10,-10 H 145 V 50 H -10 Z" id="path320" />
    <rect x="0" y="0" width="135" height="40" rx="5" ry="5" id="rect322" />
    <path
      d="M 130,0.8 A 4.2,4.2 0 0 1 134.2,5 V 35 A 4.2,4.2 0 0 1 130,39.2 H 5 A 4.2,4.2 0 0 1 0.8,35 V 5 A 4.2,4.2 0 0 1 5,0.8 H 130 M 130,0 H 5 A 5,5 0 0 0 0,5 v 30 a 5,5 0 0 0 5,5 h 125 a 5,5 0 0 0 5,-5 V 5 a 5,5 0 0 0 -5,-5 z"
      fill="#a6a6a6"
      id="path324"
    />
    <path
      d="m 47.42,10.24 a 2.71,2.71 0 0 1 -0.75,2 2.91,2.91 0 0 1 -2.2,0.89 3.15,3.15 0 0 1 -2.21,-5.37 3,3 0 0 1 2.21,-0.9 3.1,3.1 0 0 1 1.23,0.25 2.47,2.47 0 0 1 0.94,0.67 L 46.11,8.31 A 2,2 0 0 0 44.47,7.6 2.32,2.32 0 0 0 42.14,10 a 2.36,2.36 0 0 0 4,1.73 1.89,1.89 0 0 0 0.5,-1.22 H 44.47 V 9.79 h 2.91 a 2.54,2.54 0 0 1 0.04,0.45 z M 52,7.74 h -2.7 v 1.9 h 2.46 v 0.72 H 49.3 v 1.9 H 52 V 13 H 48.5 V 7 H 52 Z M 55.28,13 H 54.51 V 7.74 H 52.83 V 7 H 57 v 0.74 h -1.72 z m 4.66,0 V 7 h 0.77 v 6 z m 4.19,0 H 63.36 V 7.74 H 61.68 V 7 h 4.12 v 0.74 h -1.67 z m 9.48,-0.78 a 3.12,3.12 0 0 1 -4.4,0 3.24,3.24 0 0 1 0,-4.45 3.1,3.1 0 0 1 4.4,0 3.23,3.23 0 0 1 0,4.45 z m -3.83,-0.5 a 2.31,2.31 0 0 0 3.26,0 2.56,2.56 0 0 0 0,-3.44 2.31,2.31 0 0 0 -3.26,0 2.56,2.56 0 0 0 0,3.44 z M 75.58,13 V 7 h 0.94 l 2.92,4.67 V 7 h 0.77 v 6 h -0.8 L 76.36,8.11 V 13 Z"
      fill="#ffffff"
      stroke="#ffffff"
      strokeMiterlimit="10"
      strokeWidth="0.2"
      id="path326"
    />
    <path
      d="M 68.14,21.75 A 4.25,4.25 0 1 0 72.41,26 4.19,4.19 0 0 0 68.14,21.75 Z m 0,6.83 a 2.58,2.58 0 1 1 2.4,-2.58 2.46,2.46 0 0 1 -2.4,2.58 z M 58.83,21.75 A 4.25,4.25 0 1 0 63.09,26 4.19,4.19 0 0 0 58.82,21.75 Z m 0,6.83 A 2.58,2.58 0 1 1 61.22,26 2.46,2.46 0 0 1 58.82,28.58 Z M 47.74,23.06 v 1.8 h 4.32 a 3.77,3.77 0 0 1 -1,2.27 4.42,4.42 0 0 1 -3.33,1.32 4.8,4.8 0 0 1 0,-9.6 4.6,4.6 0 0 1 3.27,1.29 l 1.27,-1.27 A 6.29,6.29 0 0 0 47.74,17 a 6.61,6.61 0 1 0 0,13.21 6,6 0 0 0 4.61,-1.85 6,6 0 0 0 1.56,-4.22 5.87,5.87 0 0 0 -0.1,-1.13 z m 45.31,1.4 a 4,4 0 0 0 -3.64,-2.71 4,4 0 0 0 -4,4.25 4.16,4.16 0 0 0 4.22,4.25 4.23,4.23 0 0 0 3.54,-1.88 l -1.45,-1 a 2.43,2.43 0 0 1 -2.09,1.18 2.16,2.16 0 0 1 -2.06,-1.29 l 5.69,-2.35 z m -5.8,1.42 a 2.33,2.33 0 0 1 2.22,-2.48 1.65,1.65 0 0 1 1.58,0.9 z M 82.63,30 H 84.5 V 17.5 H 82.63 Z M 79.57,22.7 H 79.5 a 3,3 0 0 0 -2.24,-1 4.26,4.26 0 0 0 0,8.51 2.9,2.9 0 0 0 2.24,-1 h 0.06 v 0.61 c 0,1.63 -0.87,2.5 -2.27,2.5 a 2.35,2.35 0 0 1 -2.14,-1.51 l -1.63,0.68 A 4.05,4.05 0 0 0 77.29,34 c 2.19,0 4,-1.29 4,-4.43 V 22 h -1.72 z m -2.14,5.88 a 2.59,2.59 0 0 1 0,-5.16 2.4,2.4 0 0 1 2.27,2.58 2.38,2.38 0 0 1 -2.28,2.58 z M 101.81,17.5 H 97.34 V 30 h 1.87 v -4.74 h 2.61 a 3.89,3.89 0 1 0 0,-7.76 z m 0,6 H 99.2 v -4.26 h 2.65 a 2.145,2.145 0 1 1 0,4.29 z m 11.53,-1.8 a 3.5,3.5 0 0 0 -3.33,1.91 l 1.66,0.69 a 1.77,1.77 0 0 1 1.7,-0.92 1.8,1.8 0 0 1 2,1.61 v 0.13 a 4.13,4.13 0 0 0 -1.95,-0.48 c -1.79,0 -3.6,1 -3.6,2.81 a 2.89,2.89 0 0 0 3.1,2.75 2.63,2.63 0 0 0 2.4,-1.2 h 0.06 v 1 h 1.8 v -4.81 c 0,-2.19 -1.66,-3.46 -3.79,-3.46 z m -0.23,6.85 c -0.61,0 -1.46,-0.31 -1.46,-1.06 0,-1 1.06,-1.33 2,-1.33 a 3.32,3.32 0 0 1 1.7,0.42 2.26,2.26 0 0 1 -2.19,2 z M 123.74,22 121.6,27.42 h -0.06 L 119.32,22 h -2 l 3.33,7.58 -1.9,4.21 h 1.95 L 125.82,22 Z m -16.81,8 h 1.87 V 17.5 h -1.87 z"
      fill="#ffffff"
      id="path328"
    />
    <path
      d="m 10.44,7.54 a 2,2 0 0 0 -0.46,1.4 v 22.12 a 2,2 0 0 0 0.46,1.4 L 10.51,32.53 22.9,20.15 V 19.86 L 10.51,7.47 Z"
      fill="url(#a)"
      id="path330"
    />
    <path
      d="m 27,24.28 -4.1,-4.13 v -0.29 l 4.1,-4.14 0.09,0.05 4.91,2.79 c 1.4,0.79 1.4,2.09 0,2.89 l -4.89,2.78 z"
      fill="url(#b)"
      id="path332"
    />
    <path
      d="M 27.12,24.22 22.9,20 10.44,32.46 a 1.63,1.63 0 0 0 2.08,0.06 l 14.61,-8.3"
      fill="url(#c)"
      id="path334"
    />
    <path
      d="M 27.12,15.78 12.51,7.48 A 1.63,1.63 0 0 0 10.43,7.54 L 22.9,20 Z"
      fill="url(#d)"
      id="path336"
    />
    <path
      d="m 27,24.13 -14.49,8.25 a 1.67,1.67 0 0 1 -2,0 l -0.07,0.07 0.07,0.07 a 1.66,1.66 0 0 0 2,0 l 14.61,-8.3 z"
      //style="isolation:isolate"
      opacity="0.2"
      id="path338"
    />
    <path
      d="m 10.44,32.32 a 2,2 0 0 1 -0.46,-1.4 v 0.15 a 2,2 0 0 0 0.46,1.4 L 10.51,32.4 Z M 32,21.3 27,24.13 27.09,24.22 32,21.44 A 1.75,1.75 0 0 0 33,20 1.86,1.86 0 0 1 32,21.3 Z"
      //style="isolation:isolate"
      opacity="0.12"
      id="path340"
    />
    <path
      d="M 12.51,7.62 32,18.7 A 1.86,1.86 0 0 1 33,20 1.75,1.75 0 0 0 32,18.56 L 12.51,7.48 C 11.11,6.69 9.97,7.35 9.97,8.95 V 9.1 C 10,7.49 11.12,6.83 12.51,7.62 Z"
      //style="isolation:isolate"
      fill="#ffffff"
      opacity="0.25"
      id="path342"
    />
  </svg>
);

export default BadgeGooglePlaySvgEN;
