/** @jsx jsx */
import { jsx } from '@emotion/core';

import {
  ShColors,
  ShColor,
  ShFonts,
  ShFontWeights,
  ShEasings,
} from '@shoootin/design-tokens';
import {ComponentType, ReactHTML, ReactNode} from 'react';

export type ShLinkTextProps = {
  component?: ComponentType<any> | keyof ReactHTML;
  children: string | ReactNode;
  baseColor?: ShColor;
  hoverColor?: ShColor;
  active?: boolean;
  bold?: boolean;
};

export const ShLinkText = ({
  component: Component = 'span',
  children,
  baseColor = 'black',
  hoverColor = 'black',
  active = false,
  bold = true,
}: ShLinkTextProps) => {
  return (
    <Component
      css={{
        display: 'initial',
        position: 'relative',
        color: ShColors[baseColor],
        fontFamily: bold ? ShFonts.secondary : ShFonts.primary,
        fontWeight: bold ? ShFontWeights.m : ShFontWeights.s,
        textDecoration: 'none',
        cursor: 'pointer',
        transition: `color 200ms ${ShEasings.easeIn}`,
        ...(active && {
          color: ShColors[hoverColor],
          transition: `color 250ms ${ShEasings.easeOut}`,
        }),
        '&::before': {
          content: '""',
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: '0px',
          height: '1px',
          backgroundColor: 'currentColor',
          transform: 'scaleX(0)',
          transformOrigin: 'right center',
          transition: `transform 200ms ${ShEasings.easeIn}`,
          ...(active && {
            transform: 'scaleX(1)',
            transformOrigin: 'left center',
            transition: `transform 250ms ${ShEasings.easeOut}`,
          }),
        },
        '&:hover, &:active, &:focus': {
          color: ShColors[hoverColor],
          transition: `color 250ms ${ShEasings.easeOut}`,

          '&::before': {
            transform: 'scaleX(1)',
            transformOrigin: 'left center',
            transition: `transform 250ms ${ShEasings.easeOut}`,
          },
        },
      }}
    >
      {children}
    </Component>
  );
};
