/** @jsx jsx */
import { jsx } from '@emotion/core';

import { ShButton, ShButtonSize, ShButtonVariant } from './ShButton';
import { useAsyncCallback } from 'react-async-hook';
import { ReactNode } from 'react';

export type ShButtonAsyncProps = {
  children?: ReactNode;
  variant?: ShButtonVariant;
  size?: ShButtonSize;
  disabled?: boolean;
  onClick: (e ?: any) => void;
  width?: string | number;
};
export const ShButtonAsync = ({
  children,
  variant: themeProp,
  size: sizeProp,
  disabled = false,
  onClick,
  width,
}: ShButtonAsyncProps) => {
  const asyncOnClick = useAsyncCallback(onClick, {
    onError: (e) => console.error('ShButtonAsync catched error', e),
  });
  return (
    <ShButton
      disabled={disabled || asyncOnClick.loading}
      onClick={asyncOnClick.execute}
      variant={themeProp}
      size={sizeProp}
      loading={asyncOnClick.loading}
      width={width}
    >
      {children}
    </ShButton>
  );
};
