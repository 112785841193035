/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import { SvgProps } from '../../../components/svg/SvgUtils';
import { ShColors } from '@shoootin/design-tokens';

const EventSvg = ({ width = 1024, color = 'black' }: SvgProps) => {
  const fillColor = ShColors[color];
  return (
    <svg
      viewBox="0 0 1024 1024"
      height={width}
      width={width}
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={fillColor}
      fill={fillColor}
    >
      <path d="M17.067 1023.283h238.933c9.417 0 17.067-7.65 17.067-17.067v-68.267c0-9.425-7.65-17.067-17.067-17.067h-68.267v-341.333h34.133v-34.133h-34.133v-51.642c20.6-15.6 34.133-40.075 34.133-67.825 0-47.050-38.283-85.333-85.333-85.333s-85.333 38.283-85.333 85.333c0 27.767 13.533 52.242 34.133 67.825v51.642h-34.133v34.133h34.133v341.333h-68.267c-9.417 0-17.067 7.642-17.067 17.067v68.267c0 9.417 7.65 17.067 17.067 17.067zM136.533 374.75c28.225 0 51.2 22.975 51.2 51.2s-22.975 51.2-51.2 51.2-51.2-22.975-51.2-51.2c0-28.225 22.975-51.2 51.2-51.2zM119.467 509.558c5.517 1.125 11.233 1.725 17.067 1.725s11.55-0.6 17.067-1.725v35.858h-34.133zM119.467 579.55h34.133v341.333h-34.133zM34.133 955.017h204.8v34.133h-204.8z" />
      <path d="M1006.933 920.883h-68.267v-341.333h34.133v-34.133h-34.133v-51.642c20.6-15.6 34.133-40.075 34.133-67.825 0-47.050-38.283-85.333-85.333-85.333s-85.333 38.283-85.333 85.333c0 27.767 13.533 52.242 34.133 67.825v51.642h-34.133v34.133h34.133v341.333h-68.267c-9.417 0-17.067 7.642-17.067 17.067v68.267c0 9.417 7.65 17.067 17.067 17.067h238.933c9.417 0 17.067-7.65 17.067-17.067v-68.267c0-9.425-7.65-17.067-17.067-17.067zM887.467 374.75c28.225 0 51.2 22.975 51.2 51.2s-22.975 51.2-51.2 51.2-51.2-22.975-51.2-51.2c0-28.225 22.975-51.2 51.2-51.2zM870.4 509.558c5.517 1.125 11.233 1.725 17.067 1.725s11.55-0.6 17.067-1.725v35.858h-34.133zM870.4 579.55h34.133v341.333h-34.133zM989.867 989.15h-204.8v-34.133h204.8z" />
      <path d="M681.9 115.575c-1.967-6.333-7.425-10.942-14-11.825l-97.433-13.225-43.367-82.125c-5.917-11.2-24.267-11.2-30.183 0l-43.367 82.125-97.433 13.225c-6.575 0.883-12.033 5.492-14 11.825-1.975 6.333-0.1 13.225 4.8 17.7l69.783 63.592-16.417 89.475c-1.158 6.35 1.35 12.8 6.483 16.692 5.158 3.892 12.050 4.542 17.817 1.708l87.417-42.958 87.417 42.942c2.375 1.175 4.95 1.758 7.508 1.758 3.65 0 7.267-1.175 10.308-3.467 5.133-3.892 7.642-10.342 6.483-16.692l-16.417-89.475 69.783-63.592c4.9-4.458 6.775-11.35 4.817-17.683zM577.317 178.008c-4.358 3.958-6.367 9.892-5.292 15.7l12.017 65.45-64.5-31.692c-2.367-1.167-4.95-1.758-7.525-1.758s-5.15 0.575-7.525 1.758l-64.5 31.692 12.017-65.45c1.058-5.808-0.933-11.742-5.292-15.7l-49.783-45.367 69.925-9.492c5.458-0.733 10.225-4.058 12.8-8.942l32.342-61.3 32.375 61.3c2.575 4.883 7.342 8.192 12.8 8.942l69.925 9.492z" />
      <path d="M790.067 601.617c-23.242 23.225-48.875 42.517-75.858 58.708l-135.133-343.042-31.75 12.517 136.675 346.942c-108.325 52.617-235.692 52.617-344 0l136.675-346.942-31.75-12.517-135.133 343.025c-26.983-16.192-52.617-35.467-75.858-58.708l-24.133 24.133c26.658 26.658 56.017 49 87.158 67.125l-73.9 187.625 31.75 12.517 72.533-184.117c58.192 27.817 121.408 41.825 184.658 41.825s126.467-13.992 184.658-41.825l72.533 184.117 31.75-12.517-73.9-187.608c31.142-18.125 60.5-40.467 87.158-67.125z" />
    </svg>
  );
};

export default EventSvg;
