import { useCallback, useRef, useEffect, useLayoutEffect } from 'react';

// See https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85
const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'
    ? useLayoutEffect
    : useEffect;

export const useGetter = <S>(state: S): (() => S) => {
  const stateRef = useRef(state);
  useIsomorphicLayoutEffect(() => {
    stateRef.current = state;
  });
  return useCallback(() => stateRef.current, [stateRef]);
};
