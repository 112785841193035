/** @jsx jsx */
import { InterpolationWithTheme, jsx } from '@emotion/core';
import React, { ReactNode } from 'react';
import {
  ShColor,
  ShColors,
  ShFonts,
  ShMediaQueries,
  ShServiceColorPalettes,
} from '@shoootin/design-tokens';
import { ShStyles } from '../../../designSystem/constants/ShStyles';
import { ShOrderHelpModalName, ShService } from '@shoootin/config';
import { ShText } from '@shoootin/translations';
import { ShHelpModalButton } from '../../help/ShHelpModalButton';

/*

&__option {
  .flex-between;
  padding: spacing();
  @media @mobile-only {
    position: relative;
  }

  &:not(:first-child) {
    border-top: 1px solid @c-light-darker;
  }

  .field {
    @media @mobile-only {
      flex-grow: 1;
    }
    &:extend(.title);

    &-control {
      @media @mobile-only {
        .font-size(14);
      }
    }
  }

  .text-price {
    @media @mobile-only {
      position: absolute;
      top: spacing(1.125);
      right: spacing(2.5)
    }
  }

}
 */
const rem = (value: number) => `${value / 16}rem`;
const spacing = (ratio: number = 1) => `${1.5 * ratio}rem`;
const fontSize = (size: number) => `${size / 16}rem`;

const optionsStyle: InterpolationWithTheme<any> = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: spacing(),
  [ShMediaQueries.ShMobileOnly]: {
    position: 'relative',
  },
  '&:not(:first-child)': {
    borderTop: `1px solid ${ShColors.whiteD}`,
  },
  // '.field': {
  //   [ShMediaQueries.ShMobileOnly]: {
  //     flexGrow: 1,
  //   },
  //
  //   // '&-control': {
  //   //   [ShMediaQueries.ShMobileOnly]: {
  //   //     fontSize: fontSize(14), //TODO remove when input is refactored
  //   //   },
  //   // },
  // },
  // '.text-price': {
  //   [ShMediaQueries.ShMobileOnly]: {
  //     position: 'absolute',
  //     top: spacing(1.125),
  //     right: spacing(2.5),
  //   },
  // },
};

/*
.text-price {
  .font-size(14);
  @media @desktops-only {
    .font-size(20);
  }
}
 */
const priceStyle: InterpolationWithTheme<any> = {
  fontSize: fontSize(14),
  [ShMediaQueries.ShDesktopsOnly]: {
    fontSize: fontSize(20),
  },
  [ShMediaQueries.ShMobileOnly]: {
    position: 'absolute',
    top: spacing(1.125),
    right: spacing(2.5),
  },
};

const ShSwitchBoxElementOptionPrice = ({
  children,
}: {
  children: ReactNode;
}) => {
  return <div css={priceStyle}>{children}</div>;
};

export const ShSwitchBoxElementOption = ({
  option,
  price,
}: {
  option: ReactNode;
  price: ReactNode;
}) => {
  return (
    <div css={optionsStyle}>
      {option}
      <ShSwitchBoxElementOptionPrice>{price}</ShSwitchBoxElementOptionPrice>
    </div>
  );
};

export const ShSwitchBoxElementField = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <div
      css={{
        padding: spacing(),
        [ShMediaQueries.ShMobileOnly]: {
          position: 'relative',
        },
        '&:not(:first-child)': {
          borderTop: `1px solid ${ShColors.whiteD}`,
        },
      }}
    >
      {children}
    </div>
  );
};

export const ShSwitchBoxElementWrapper = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <div
      css={{
        paddingLeft: spacing(),
        paddingRight: spacing(),
      }}
    >
      {children}
    </div>
  );
};

export const ShSwitchBoxContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div
      css={{
        padding: spacing(),
      }}
    >
      {children}
    </div>
  );
};
/*
padding: spacing(0.75);
background-color: @c-light-darker;
.font-size(12);
@media @desktops-only {
  padding: spacing(0.5) spacing();
  .font-size(14);
}
 */

export const ShSwitchBoxElementPanelHeader = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <div
      css={{
        padding: spacing(0.75),
        backgroundColor: ShColors.whiteD,
        fontSize: fontSize(12),
        [ShMediaQueries.ShDesktopsOnly]: {
          padding: `${spacing(0.5)} ${spacing()}`,
          fontSize: fontSize(14),
        },
      }}
    >
      {children}
    </div>
  );
};
/*
&__preview {
  .flex-between;
  align-items: center;
  padding: spacing(0.75);
  border-top: 1px solid @c-light-darker;
  @media @desktops-only {
    padding: spacing();
  }
  .title {
    position: relative;
    margin: 0;
    padding: .25em 0 0 1.25em;
    @media @mobile-only {
      .font-size(14);
    }
    @media @desktops-only {
      margin: (@grid-gap / 2) 0;
      padding: .375em 0 .125em 1.5em;
    }
    &::before {
      .overlay;
      content: '';
      width: rem(3);
      background-color: @c-base;
      @media @desktops-only {
        width: rem(5);
      }
    }
  }
  &.photo .title::before { background-color: @c-photo-dark }
  &.video .title::before { background-color: @c-video-dark }
  &.matterport .title::before { background-color: @c-matterport-dark }
  &.drone .title::before { background-color: @c-drone-dark }
  &.floorplan .title::before { background-color: @c-base-light }


}
 */
export const ShSwitchBoxElementCustomComponentHeader = ({
  title,
  help,
  service,
}: {
  title: ReactNode;
  help?: ReactNode;
  service?: ShService;
}) => {
  const serviceColor: ShColor = service
    ? ShServiceColorPalettes[service].dark1
    : 'base';
  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: spacing(0.75),
        borderTop: `1px solid ${ShColors.whiteD}`,

        [ShMediaQueries.ShDesktopsOnly]: {
          padding: spacing(),
        },
      }}
    >
      <div
        css={{
          position: 'relative',
          padding: '.25em 0 0 1.25em',
          fontFamily: ShFonts.secondary,
          [ShMediaQueries.ShMobileOnly]: {
            fontSize: fontSize(14),
          },
          [ShMediaQueries.ShDesktopsOnly]: {
            margin: '0.625em 0',
            padding: '.375em 0 .125em 1.5em',
          },
        }}
      >
        <div
          css={{
            ...ShStyles.overlay,
            content: '""',
            width: rem(3),
            backgroundColor: ShColors[serviceColor],
            [ShMediaQueries.ShDesktopsOnly]: {
              width: rem(5),
            },
          }}
        ></div>
        {title}
      </div>
      {help && <span>{help}</span>}
    </div>
  );
};
/*
&__help {
  .flex-between;
  align-items: center;
  padding: spacing(0.75);
  border-top: 1px solid @c-light-darker;
  @media @mobile-only {
    .font-size(12);
  }
  @media @desktops-only {
    padding: spacing();
  }
}
 */

export const ShSwitchHelp = ({
  modalName,
  children,
}: {
  modalName: ShOrderHelpModalName;
  children?: ReactNode;
}) => (
  <div
    className="switchbox-panel__help"
    css={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: spacing(0.75),
      borderTop: `1px solid ${ShColors.whiteD}`,
      [ShMediaQueries.ShMobileOnly]: {
        fontSize: fontSize(12),
      },
      [ShMediaQueries.ShDesktopsOnly]: {
        padding: spacing(),
      },
    }}
  >
    {children || <ShText message={{ id: 'front_order_step_order_help' }} />}
    <ShHelpModalButton modalName={modalName} />
  </div>
);
