//import { defineMessages } from 'react-intl';
import {
  ShIntlMessageDefinition,
  FrontKeys,
  ClientKeys,
  getShMessage,
} from '@shoootin/translations';


export type ClientBillingEntityTargets = FrontKeys & ClientKeys;

export const getClientBillingEntityMessage = (
  key: ClientBillingEntityTargets,
  defaultMessage?: string,
): ShIntlMessageDefinition => getShMessage(key, defaultMessage);

// export const ShClientBillingEntityFormMessages = defineMessages({
//
//   companyPlaceholder: {
//     id: `${prefix}_company_placeholder`,
//     defaultMessage: 'Company',
//   },
//   countryPlaceholder: {
//     id: `${prefix}_country_placeholder`,
//     defaultMessage: 'Select a country',
//   },
//   addressPlaceholder: {
//     id: `${prefix}_address_placeholder`,
//     defaultMessage: 'Address',
//   },
//   siretPlaceholder: {
//     id: `${prefix}_siret_placeholder`,
//     defaultMessage: 'SIRET',
//   },
//   taxPlaceholder: {
//     id: `${prefix}_tax_placeholder`,
//     defaultMessage: 'Tax number',
//   },
// });
