/** @jsx jsx */
import React, {
  ComponentType,
  MouseEventHandler,
  ReactNode,
  ChangeEventHandler,
  FocusEventHandler,
} from 'react';
import { jsx } from '@emotion/core';
import { darken } from 'polished';

import {
  ShColors,
  ShEasings,
  ShFonts,
  ShRadius,
} from '@shoootin/design-tokens';
import { ShStyles } from '../../../../designSystem/constants/ShStyles';
import { SvgProps } from '../../../../components/svg/SvgUtils';
import { ShInputError } from '../utils/ShInputError/ShInputError';
import { ShInputRequiredMark } from '../utils/ShInputRequiredMark/ShInputRequiredMark';
import { ShInputIcon } from '../utils/ShInputIcon/ShInputIcon';
import {
  ShInputSize,
  ShInputSizeConfigs,
  useShInputSize,
} from '../ShInputTheme';

type ShInputTextType = 'text' | 'password' | 'number';

export type ShInputTextProps = {
  themeSize?: ShInputSize;
  type?: ShInputTextType;
  error?: string | null;
  icon?: {
    component: ComponentType<SvgProps>;
    onClick?: MouseEventHandler;
  };
  required?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
  placeholder?: string;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
  suffix?: ReactNode;
};

// These raw elements are extracted, because style is reused for AppInputAutocomplete...
export const ShInputTextRawElements = {
  Container: React.forwardRef<HTMLDivElement, React.ComponentProps<'div'>>(
    (props, ref) => (
      <div
        ref={ref}
        css={{
          fontSize: '16px',
          position: 'relative',
          textAlign: 'left',
          userSelect: 'none',
        }}
        {...props}
      />
    ),
  ),
  InputWrapper: React.forwardRef<HTMLDivElement, React.ComponentProps<'div'>>(
    (props, ref) => (
      <div
        ref={ref}
        css={{
          position: 'relative',
          display: 'flex',
          border: 'none',
          borderRadius: ShRadius.m,
          backgroundColor: ShColors.whiteD,
          borderColor: ShColors.whiteD,
          boxSizing: 'border-box',
          color: ShColors.black,
          fontFamily: ShFonts.primary,
          fontSize: '16px',
          lineHeight: 1.2,
        }}
        {...props}
      />
    ),
  ),
  Input: React.forwardRef<
    HTMLInputElement,
    React.ComponentProps<'input'> & { themeSize?: ShInputSize }
  >(({ themeSize, ...props }, ref) => {
    const size = useShInputSize(themeSize);
    return (
      <input
        ref={ref}
        css={{
          flex: 1,
          width: '100%',
          backgroundColor: 'transparent',
          border: 'none',
          '&::placeholder': {
            color: ShColors.placeholder,
          },
          '&:focus': {
            outline: 'none',
          },
          ...(props.type === 'number' && {
            '&::-webkit-inner-spin-button,  &::-webkit-outer-spin-button': {
              Shearance: 'none',
            },
          }),
          ...ShInputSizeConfigs[size].inputCss,
        }}
        {...props}
      />
    );
  }),
};

export const ShInputText = ({
  themeSize,
  type = 'text',
  error,
  icon,
  required = false,
  inputRef,
  placeholder,
  value,
  onChange,
  onFocus,
  onBlur,
  disabled = false,
  suffix,
}: ShInputTextProps) => {
  return (
    <ShInputTextRawElements.Container
      css={{
        fontSize: '16px',
        position: 'relative',
        textAlign: 'left',
        userSelect: 'none',
      }}
    >
      <ShInputTextRawElements.InputWrapper
        css={{
          position: 'relative',
          display: 'flex',
          border: 'none',
          borderRadius: ShRadius.m,
          backgroundColor: ShColors.whiteD,
          borderColor: ShColors.whiteD,
          boxSizing: 'border-box',
          color: ShColors.black,
          fontFamily: ShFonts.primary,
          fontSize: '16px',
          lineHeight: 1.2,
        }}
      >
        <ShInputTextRawElements.Input
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          value={value}
          ref={inputRef}
          type={type}
          placeholder={placeholder}
          required={required}
          themeSize={themeSize}
          disabled={disabled}
        />
        {required && (
          <ShInputItemContainer size={themeSize}>
            <ShInputRequiredMark />
          </ShInputItemContainer>
        )}
        {icon && (
          <ShInputItemContainer onClick={icon.onClick} size={themeSize}>
            <ShInputIcon component={icon.component} />
          </ShInputItemContainer>
        )}
        {suffix && (
          <ShInputItemContainer size={themeSize}>{suffix}</ShInputItemContainer>
        )}
      </ShInputTextRawElements.InputWrapper>
      {error && <ShInputError>{error}</ShInputError>}
    </ShInputTextRawElements.Container>
  );
};

export const ShInputItemContainer = ({
  size: sizeProp,
  className,
  children,
  onClick,
}: {
  size?: ShInputSize;
  className?: string;
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
}) => {
  const size = useShInputSize(sizeProp);
  return (
    <div
      onClick={onClick}
      className={className}
      css={{
        ...ShStyles.centered,
        padding: '12px 12px',
        borderRadius: `0px ${ShRadius.m}px ${ShRadius.m}px 0px`,
        transition: `background-color 150ms ${ShEasings.easeOut}`,
        ...(onClick && {
          cursor: 'pointer',
          '&:focus': {
            outline: 'none',
          },
          '&:hover': {
            backgroundColor: darken(0.05, ShColors.whiteD),
          },
          '&:active': {
            backgroundColor: darken(0.025, ShColors.whiteD),
          },
        }),

        ...ShInputSizeConfigs[size].inputCss,
      }}
    >
      {children}
    </div>
  );
};
