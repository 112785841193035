/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import Linkify from 'react-linkify';
import LinkifyIt, { Match } from 'linkify-it';
import LinkSvg from '../../components/svg/icon/LinkSvg';
import { ShLinkText } from '../../designSystem/primitives/text/ShLinkText/ShLinkText';
import { ShColor } from '@shoootin/design-tokens';

const linkify = new LinkifyIt();
linkify.set({
  fuzzyEmail: false,
  fuzzyLink: false,
});
const matchDecorator = (text: string): Match[] | null => {
  return linkify.match(text);
};

const truncate = (input: string, length: number) =>
  input.length > length ? `${input.substring(0, length)}...` : input;

export const ShAutoLink = ({
  text,
  trim = 40,
  color = 'inherit',
  withBlankIcon = true,
}: {
  text: string;
  trim?: number;
  color?: ShColor;
  withBlankIcon?: boolean;
}) => {
  return (
    <Linkify
      matchDecorator={matchDecorator}
      componentDecorator={(
        decoratedHref: string,
        decoratedText: string,
        key: number,
      ) => {
        return (
          <span key={key}>
            <a
              href={decoratedHref}
              target={!withBlankIcon ? '_blank' : undefined}
            >
              <ShLinkText bold={false} baseColor={color}>
                <b>{truncate(decoratedText, trim)}</b>
              </ShLinkText>
            </a>
            {withBlankIcon && (
              <a href={decoratedHref} target={'_blank'} css={{ marginLeft: 5 }}>
                <LinkSvg width={10} color={color} />
              </a>
            )}
          </span>
        );
      }}
    >
      {text}
    </Linkify>
  );
};
