/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ReactNode } from 'react';
import { ShInputNumberRange } from '../../../designSystem/primitives/input/ShInputNumberRange/ShInputNumberRange';
import { ShInputError } from '../../../designSystem/primitives/input/utils/ShInputError/ShInputError';
import { ShService } from '@shoootin/config';
import { ShFonts, ShMediaQueries } from '@shoootin/design-tokens';

type InputLabel = {
  label: ReactNode;
  legend?: ReactNode;
  error?: ReactNode;
  price?: ReactNode;
};

const fontSize = (size: number) => `${size / 16}rem`;
export const ShNumberRangeField = ({
  value,
  onChange,
  label,
  min = 0,
  max = 10000,
  step = 1,
  error,
  service,
}: {
  value: number;
  onChange: (value: number) => void;
  label: InputLabel;
  min?: number;
  max?: number;
  step?: number;
  error?: string | null;
  service?: ShService;
}) => {
  return (
    <div className={'ShNumberRangeField'}>
      <div>
        <label
          css={{
            fontFamily: ShFonts.secondary,
            [ShMediaQueries.ShMobileOnly]: {
              fontSize: fontSize(14),
            },
          }}
        >
          {label.label}
        </label>
        {label.legend && (
          <div
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '0.25em',
              [ShMediaQueries.ShMobileOnly]: {
                fontSize: fontSize(14),
              },
            }}
          >
            {label.legend}
            {label.price && (
              <span
                css={{
                  paddingLeft: 20,
                  fontSize: fontSize(14),
                  [ShMediaQueries.ShDesktopsOnly]: {
                    fontSize: fontSize(20),
                  },
                }}
              >
                {label.price}
              </span>
            )}
          </div>
        )}
        <div css={{ marginTop: '1.25rem' }}>
          <ShInputNumberRange
            value={value}
            onChange={onChange}
            min={min}
            max={max}
            step={step}
            error={label.error}
            service={service}
          />
        </div>

        {error && <ShInputError>{error}</ShInputError>}
      </div>
    </div>
  );
};
