import React from 'react';

const DinersSvg = ({ width = 66.316 }: { width?: number }) => (
  <svg viewBox="0 0 66.316 45.79" width={width} height={width * 0.6905}>
    <g>
      <path
        fill="#F3F4F4"
        d="M64,37.792c0,2.75-2.25,5-5,5H9c-2.75,0-5-2.25-5-5v-30c0-2.75,2.25-5,5-5h50c2.75,0,5,2.25,5,5V37.792z"
      />
      <g>
        <g>
          <circle fill="#009FDA" cx="30.834" cy="22.791" r="10" />
          <path
            fill="#009FDA"
            d="M30,12.791c5,0,10,4.478,10,10s-5,10-10,10V12.791z"
          />
          <path
            fill="#009FDA"
            d="M30,32.792v-20h7.167c5,0,9.998,4.478,9.998,10s-4.998,10-9.998,10H30z"
          />
          <circle fill="#F3F4F4" cx="30.834" cy="22.791" r="9.438" />
          <g>
            <path
              fill="#009FDA"
              d="M35.437,22.791c0-2.727-2.437-5.008-4.437-5.609v11.219C33,27.799,35.437,25.52,35.437,22.791z"
            />
          </g>
          <g>
            <path
              fill="#009FDA"
              d="M23.562,22.791c0,2.727,2.438,5.008,4.438,5.61v-11.22C26,17.783,23.562,20.063,23.562,22.791z"
            />
          </g>
        </g>
        <g>
          <path
            fill="#F8F8F9"
            d="M59,3.791c2.207,0,4,1.794,4,4v30c0,2.205-1.793,4-4,4H9c-2.206,0-4-1.795-4-4v-30c0-2.206,1.794-4,4-4
					H59 M59,2.791H9c-2.75,0-5,2.25-5,5v30c0,2.75,2.25,5,5,5h50c2.75,0,5-2.25,5-5v-30C64,5.041,61.75,2.791,59,2.791L59,2.791z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default DinersSvg;
