/** @jsx jsx */
import { jsx } from '@emotion/core';

import { ShInputText, ShInputTextProps } from '../ShInputText/ShInputText';

export type ShInputNumberProps = Omit<
  ShInputTextProps,
  'type' | 'value' | 'onChange'
> & {
  value?: number;
  onChange: (value: number) => void;
};

export const ShInputNumber = ({
  value,
  onChange,
  ...props
}: ShInputNumberProps) => {
  const stringValue = value !== undefined ? value.toString() : '';
  return (
    <ShInputText
      type="number"
      value={stringValue}
      onChange={(e) => onChange(parseInt(e.target.value))}
      {...props}
    />
  );
};
