/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { ComponentType } from 'react';
import ImmoCommercialSvg from './illustrations/ImmoCommercialSvg';
import ImmoResidentialSvg from './illustrations/ImmoResidentialSvg';
import ArchitectureSvg from './illustrations/ArchitectureSvg';
import HostelSvg from './illustrations/HostelSvg';
import RestaurantSvg from './illustrations/RestaurantSvg';
import ShopSvg from './illustrations/ShopSvg';
import EventSvg from './illustrations/EventSvg';
import OtherSvg from './illustrations/OtherSvg';
import ConstructionSvg from './illustrations/ConstructionSvg';
import { SvgProps } from './SvgUtils';
import { ShShootingType } from '@shoootin/config';
import PortraitSvg from './illustrations/PortraitSvg';

export const ShSvgShootingTypeIconsMap: {
  [shootingType in ShShootingType]: ComponentType<SvgProps>;
} = {
  architect: ArchitectureSvg,
  commercialRealEstate: ImmoCommercialSvg,
  hotel: HostelSvg,
  restaurant: RestaurantSvg,
  residentialRealEstate: ImmoResidentialSvg,
  retail: ShopSvg,
  event: EventSvg,
  other: OtherSvg,
  portrait: PortraitSvg,
  construction: ConstructionSvg,
};

export type ShSvgShootingTypeProps = SvgProps & {
  shootingType: ShShootingType;
};
export const ShSvgShootingType = ({
  shootingType,
  ...props
}: ShSvgShootingTypeProps) => {
  const IconSvg = ShSvgShootingTypeIconsMap[shootingType];
  return <IconSvg {...props} />;
};
