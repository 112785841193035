/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { ShLocale, ShLocaleConfigs } from '@shoootin/config';
import { ShTranslationAiApi } from '@shoootin/api';
import { ShColors } from '@shoootin/design-tokens';
import { ShMultiline } from '../../designSystem/primitives/text/ShMultiline/ShMultiline';
import { ShButtonAsync } from '../../designSystem/primitives/button/ShButton/ShButtonAsync';
import { ShTranslatableLocale } from '@shoootin/config';

export type ShTranslationAiProps = {
  message: string;
  locales: ShTranslatableLocale[];
  situation: string;
  setValue?: (value: string) => void;
};

export const ShTranslationAi = ({
  message,
  locales,
  situation,
  setValue,
}: ShTranslationAiProps) => {
  const [translation, setTranslation] = React.useState<string | undefined>(
    undefined,
  );

  const translateClientValidationMessage = async (
    locale: ShTranslatableLocale,
  ) => {
    const result = await ShTranslationAiApi.translate(
      message,
      locale,
      situation,
    );
    setTranslation(result.translation);
  };
  return (
    <div>
      {message !== '' && (
        <div>
          <div
            css={{
              display: 'flex',
              gap: 10,
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginBottom: 10,
            }}
          >
            <small>Translate to</small>
            {locales.map((locale) => (
              <ShButtonAsync
                size={'s'}
                onClick={() => translateClientValidationMessage(locale)}
              >
                {locale}
              </ShButtonAsync>
            ))}
          </div>

          {translation && (
            <div>
              <div
                css={{
                  padding: 10,
                  backgroundColor: ShColors.whiteD,
                  border: `1px solid ${ShColors.blackLL}`,
                }}
              >
                <ShMultiline text={translation} />
              </div>
              {setValue && (
                <div
                  css={{ display: 'flex', gap: 10, justifyContent: 'flex-end' }}
                >
                  <ShButtonAsync
                    size={'s'}
                    onClick={() => {
                      setValue(translation);
                      setTranslation(undefined);
                    }}
                  >
                    use this translation
                  </ShButtonAsync>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
