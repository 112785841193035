/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ReactNode, Ref, useEffect, useRef, useState } from 'react';

import { ShToggle } from '../../../designSystem/primitives/ShToggle/ShToggle';
import { ShStyles } from '../../../designSystem/constants/ShStyles';
import {
  ShColors,
  ShMediaQueries,
  ShServiceColorPalettes,
} from '@shoootin/design-tokens';
import { ShService } from '@shoootin/config';
import { switchBoxStyles } from './ShSwitchBoxStyles';
import classnames from 'classnames';

const usePanelHeight = (opened: boolean) => {
  const panelContentRef = useRef<any>(null);

  const computePanelHeight = () => {
    const panelHeight = opened
      ? panelContentRef.current
        ? panelContentRef.current.scrollHeight
        : undefined
      : 0;
    // __DEV__ && console.debug('computePanelHeight', panelHeight);
    return panelHeight;
  };

  const [panelHeight, setPanelHeight] = useState(computePanelHeight());
  const updatePanelHeight = () => setPanelHeight(computePanelHeight());

  // probably to optimize
  useEffect(() => {
    updatePanelHeight();
  });

  return [panelContentRef, panelHeight];
};

const SwitchBoxBefore = ({ opened }: { opened: boolean }) => {
  return (
    <div
      css={{
        ...ShStyles.overlay,
        ...switchBoxStyles.beforeDiv,
        ...(opened && switchBoxStyles.beforeDivOpened),
      }}
    />
  );
};

const SwitchBoxAfter = ({ opened }: { opened: boolean }) => {
  return (
    <div
      css={{
        ...ShStyles.overlay,
        ...switchBoxStyles.afterDiv,
        ...(opened && switchBoxStyles.afterDivOpened),
      }}
    />
  );
};

export type ShSwitchBoxProps = {
  opened: boolean;
  onOpenedChange: (opened: boolean) => void;
  label: ReactNode;
  labelRight?: ReactNode;
  children?: ReactNode;
  headerRef?: Ref<HTMLDivElement>;
  service?: ShService;
  footer?: ReactNode;
};

export const ShSwitchBox = ({
  opened,
  onOpenedChange,
  label,
  labelRight,
  children,
  headerRef,
  service,
  footer,
}: ShSwitchBoxProps) => {
  const [panelContentRef, panelHeight] = usePanelHeight(opened);
  const serviceColors = service ? ShServiceColorPalettes[service] : undefined;
  const backgroundColor = serviceColors?.dark1
    ? ShColors[serviceColors?.dark1]
    : ShColors.base;
  return (
    <div
      className={classnames('ShSwitchBox', service)}
      css={{
        ...switchBoxStyles.main,
        ...(opened && switchBoxStyles.mainOpened),
      }}
    >
      <SwitchBoxBefore opened={opened} />
      <SwitchBoxAfter opened={opened} />

      <div
        ref={headerRef}
        css={{
          ...switchBoxStyles.header,
          ...(opened ? switchBoxStyles.headerOpened : {}),
        }}
      >
        <div
          css={{
            ...ShStyles.overlay,
            backgroundColor,
            ...switchBoxStyles.beforeDivHeader,
            ...(opened && switchBoxStyles.beforeDivHeaderOpened),
          }}
        />
        <div
          css={{
            [ShMediaQueries.ShDesktopsOnly]: {
              marginRight: '1em',
            },
            [ShMediaQueries.ShMobileOnly]: {
              marginLeft: '1em',
            },
          }}
        >
          <ShToggle value={opened} onChange={onOpenedChange} />
        </div>
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div>{label}</div>
          {labelRight && (
            <span
              css={{
                opacity: 0,
                ...(opened && {
                  opacity: 1,
                }),
              }}
            >
              {labelRight}
            </span>
          )}
        </div>
      </div>
      <div
        style={{
          height: panelHeight,
        }}
        ref={panelContentRef}
        css={{
          ...switchBoxStyles.panel,
          ...(opened && switchBoxStyles.panelOpened),
        }}
      >
        {children}
        {footer && (
          <div css={switchBoxStyles.footer}>
            <div>{footer}</div>
          </div>
        )}
      </div>
    </div>
  );
};
