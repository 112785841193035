import React from 'react';

import { SvgProps } from '../../../components/svg/SvgUtils';
import { ShColors } from '@shoootin/design-tokens';

export const VideoPlaySvg = ({
  width = 53,
  color = 'black',
  strokeWidth,
}: SvgProps) => (
  <svg
    height={width}
    width={width}
    viewBox="0 0 53 53"
    stroke={ShColors[color]}
    fill={ShColors[color]}
    strokeWidth={strokeWidth}
  >
    <path d="M21,15.5v23L37.3,27L21,15.5z" />
    <path
      d="M26.5,0.7c14.2,0,25.8,11.5,25.8,25.8c0,14.2-11.5,25.7-25.8,25.7c-14.2,0-25.7-11.5-25.7-25.7C0.8,12.3,12.3,0.7,26.5,0.7z"
      fill="none"
    />
  </svg>
);

export default VideoPlaySvg;
