/** @jsx jsx */

import React, { ReactNode } from 'react';

import { formatDate, formatTime } from '@shoootin/utils';
import { ShText, ShIntlMessageDefinition } from '@shoootin/translations';
import { ShMediaQueries, ShColors } from '@shoootin/design-tokens';
import { jsx } from '@emotion/core';

import { css } from '@emotion/core';

type ShTimeStampStatus = 'done' | 'progress' | 'todo';

export interface ShTimestamp {
  date?: Date;
  //label?: MessageDefinition;
  caption?: ShIntlMessageDefinition;
  status?: ShTimeStampStatus;
}

export const ShTimelineThemes = ['base', 'white', 'whiteD'] as const;
export type ShTimelineTheme = typeof ShTimelineThemes[number];
type ShTimelineThemeConfig = {
  backgroundColor: string;
  color: string;
  progressColor: string;
  todoColor: string;
};

export const ShTimelineThemeConfigs: {
  [key in ShTimelineTheme]: ShTimelineThemeConfig;
} = {
  base: {
    backgroundColor: ShColors.base,
    color: ShColors.white,
    progressColor: ShColors.whiteD,
    todoColor: ShColors.whiteD,
  },
  white: {
    backgroundColor: ShColors.white,
    color: ShColors.black,
    progressColor: ShColors.base,
    todoColor: ShColors.baseL,
  },
  whiteD: {
    backgroundColor: ShColors.whiteD,
    color: ShColors.black,
    progressColor: ShColors.base,
    todoColor: ShColors.baseL,
  },
};
export const useShTimelineTheme = (
  theme?: ShTimelineTheme,
): ShTimelineTheme => {
  return theme ? theme : 'base';
};

const timelineCaption = (captionPadding: string, captionMargin: string) => css`
  margin-top: ${captionMargin};

  ${ShMediaQueries.ShDesktopsOnly} {
    font-size: 18px;
    &:first-child {
      margin-top: -24px;
    }
  }
  ${ShMediaQueries.ShMobileOnly} {
    margin-top: 12px;
    font-size: 16px;
    padding-top: ${captionPadding};
    padding-bottom: ${captionPadding};
  }
`;

const timelineStamp = (
  config: ShTimelineThemeConfig,
  color: string,
  marginTop: string,
  marginLeft: string,
) => css`
  color: ${color};
  flex-basis: 25%;
  flex-grow: 1;
  counter-increment: counter-timeline;
  ${ShMediaQueries.ShDesktopsOnly} {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: ${marginLeft};
  }
  ${ShMediaQueries.ShMobileOnly} {
    padding-left: 100px;
    margin-top: ${marginTop};
  }

  &::before {
    content: counter(counter-timeline);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 72px;
    width: 72px;
    margin-bottom: 50px;
    padding-top: 3px;
    border: 1px solid ${color};
    border-radius: 50%;
    box-sizing: border-box;
    background-color: ${config.backgroundColor};
    font-family: 'League Spartan', Century Gothic, AppleGothic, sans-serif;
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    ${ShMediaQueries.ShMobileOnly} {
      left: 0;
      position: absolute;
      height: 56px;
      width: 56px;
      margin-bottom: 0;
      font-size: 20px;
    }
  }
`;

const timelineList = (config: ShTimelineThemeConfig) => css`
  position: relative;
  z-index: 0;
  margin-top: 50px;
  padding: 0;
  list-style: none;
  counter-reset: counter-timeline;

  ${ShMediaQueries.ShMobileOnly} {
    margin-right: 24px;
    margin-left: 24px;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 36px;
    left: 12.5%;
    right: 12.5%;
    border-bottom: 2px dashed ${config.color};

    ${ShMediaQueries.ShMobileOnly} {
      height: 85%;
      left: 28px;
      top: 8px;
      right: auto;
      border-left: 2px dashed ${config.color};
      border-bottom: none;
    }
  }

  ${ShMediaQueries.ShDesktopsOnly} {
    display: flex;
  }
`;

export const ShTimeline = ({
  timestamps,
  mention,
  themeProp,
}: {
  timestamps: ShTimestamp[];
  mention?: ReactNode;
  themeProp?: ShTimelineTheme;
}) => {
  const theme = useShTimelineTheme(themeProp);
  const config = ShTimelineThemeConfigs[theme];

  // padding-top: 16px;
  // padding-bottom: 16px;

  const hasAtLeastOneDate =
    timestamps.filter((each) => typeof each.date !== 'undefined').length > 0;

  console.log({ hasAtLeastOneDate });

  return (
    <div
      css={{
        backgroundColor: config.backgroundColor,
        color: config.color,
        [ShMediaQueries.ShDesktopsOnly]: {
          textAlign: 'center',
        },
        [ShMediaQueries.ShMobileOnly]: {
          paddingTop: '24px',
        },
      }}
    >
      <ol css={timelineList(config)}>
        {timestamps.map((timestamp, i) => {
          const noDate =
            hasAtLeastOneDate &&
            typeof timestamp.date === 'undefined' &&
            timestamp.status !== 'progress';

          const captionPadding = noDate ? '16px' : '0px';
          const captionMargin = noDate ? '48px' : '24px';

          const color =
            timestamp.status === 'progress'
              ? config.progressColor
              : timestamp.status === 'todo'
              ? config.todoColor
              : config.color;

          return (
            <li
              key={i}
              css={timelineStamp(
                config,
                color,
                i === 0 ? '0px' : '50px',
                i === 0 ? '0px' : '20px',
              )}
            >
              {hasAtLeastOneDate && (
                <div
                  css={{
                    [ShMediaQueries.ShMobileOnly]: {
                      fontSize: 14,
                    },
                  }}
                >
                  {timestamp.date ? (
                    <React.Fragment>
                      <b>{formatDate(timestamp.date)}</b> -{' '}
                      {formatTime(timestamp.date)}
                    </React.Fragment>
                  ) : (
                    <div
                      css={{
                        [ShMediaQueries.ShMobileOnly]: {
                          /*display: 'none'*/
                        },
                      }}
                    >
                      {timestamp.status === 'progress' && <b>In Progress</b>}
                    </div>
                  )}
                </div>
              )}

              {/*{timestamp.label && (
              <p className="timeline__label" css={{
                  [ShMediaQueries.ShMobileOnly]: {
                      fontSize: 16,
                  },
              }}>
                <ShText message={timestamp.label} />
              </p>
            )}*/}
              {timestamp.caption && (
                <div css={timelineCaption(captionPadding, captionMargin)}>
                  <ShText message={timestamp.caption} />
                </div>
              )}
            </li>
          );
        })}
      </ol>
      {mention && (
        <div
          css={{
            marginTop: '88px',
            [ShMediaQueries.ShMobileOnly]: {
              marginTop: '44px',
              textAlign: 'center',
            },
          }}
        >
          {mention}
        </div>
      )}
    </div>
  );
};
