/** @jsx jsx */
import { CSSObject, jsx } from '@emotion/core';
import { ReactNode } from 'react';
import { darken, lighten, transparentize } from 'polished';

import {
  ShColors,
  ShColor,
  ShFontWeights,
  ShFonts,
  ShEasings,
  ShRadius,
} from '@shoootin/design-tokens';
import { ShSpinnerOverlay } from '../../spinner/ShSpinnerOverlay/ShSpinnerOverlay';
import { useShTheme } from '../../../ShTheme';
import { ShStateColors } from '@shoootin/design-tokens';

export const ShButtonVariants = [
  'default',
  'light',
  'ghost',
  'glass',
  'red',
  'blackLL',
  'blackLLWithBaseLColor', // used in videoReview
  'stuck',
  'changes',
] as const;
export type ShButtonVariant = typeof ShButtonVariants[number];

type ShButtonVariantConfig = {
  spinnerColor: ShColor;
  buttonCss: CSSObject;
};
export const ShButtonVariantConfigs: {
  [key in ShButtonVariant]: ShButtonVariantConfig;
} = {
  default: {
    spinnerColor: 'white',
    buttonCss: {
      color: ShColors.white,
      backgroundColor: ShColors.base,
      '&:hover': {
        backgroundColor: ShColors.baseL,
        transition: `transition: background-color .15s ${ShEasings.easeOut},
                      color .15s ${ShEasings.easeOut}`,
      },
      '&:active, &:focus': {
        backgroundColor: lighten(0.05, ShColors.baseL),
        transition: `background-color .1s ${ShEasings.ease},
                      color .1s ${ShEasings.ease}`,
      },
    },
  },
  light: {
    spinnerColor: 'black',
    buttonCss: {
      color: ShColors.black,
      backgroundColor: ShColors.white,
      '&:hover': {
        color: ShColors.link,
        transition: `background-color 150ms ${ShEasings.easeOut},
                      color 150ms ${ShEasings.easeOut}`,
      },
      '&:active, &:focus': {
        color: darken(0.1, ShColors.link),
        backgroundColor: darken(0.1, ShColors.white),
      },
    },
  },
  ghost: {
    spinnerColor: 'black',
    buttonCss: {
      color: ShColors.black,
      backgroundColor: transparentize(0.07, ShColors.white),
      boxShadow: '0 0 1em 0 rgba(0,0,0,.25)',
      '&:hover': {
        color: ShColors.link,
      },
      '&:active, &:focus': {
        color: darken(0.1, ShColors.link),
        backgroundColor: transparentize(0.07, darken(0.1, ShColors.white)),
      },
    },
  },
  glass: {
    spinnerColor: 'white',
    buttonCss: {
      color: ShColors.white,
      border: `1px solid ${ShColors.white}`,
      backgroundColor: ShColors.transparent,
      padding: '9.6px 19px 6.4px',
      '&:hover': {
        borderColor: ShColors.white,
        backgroundColor: ShColors.white,
        color: ShColors.black,
        transition: `background-color 150ms ${ShEasings.easeOut},
                      color 150ms ${ShEasings.easeOut}`,
      },
      '&:active, &:focus': {
        color: ShColors.white,
        backgroundColor: ShColors.transparent,
      },
    },
  },
  red: {
    spinnerColor: 'white',
    buttonCss: {
      color: ShColors.white,
      backgroundColor: ShColors.error,
      '&:hover': {
        backgroundColor: lighten(0.2, ShColors.error),
        transition: `background-color 150ms ${ShEasings.easeOut},
                      color 150ms ${ShEasings.easeOut}`,
      },
      '&:active, &:focus': {
        backgroundColor: lighten(0.2, ShColors.error),
        transition: `background-color .1s ${ShEasings.ease},
                      color .1s ${ShEasings.ease}`,
      },
    },
  },
  blackLL: {
    spinnerColor: 'white',
    buttonCss: {
      color: ShColors.white,
      backgroundColor: ShColors.blackLL,
      '&:hover': {
        backgroundColor: lighten(0.2, ShColors.blackLL),
        transition: `background-color 150ms ${ShEasings.easeOut},
                      color 150ms ${ShEasings.easeOut}`,
      },
      '&:active, &:focus': {
        backgroundColor: lighten(0.2, ShColors.blackLL),
        transition: `background-color .1s ${ShEasings.ease},
                      color .1s ${ShEasings.ease}`,
      },
    },
  },
  blackLLWithBaseLColor: {
    spinnerColor: 'white',
    buttonCss: {
      color: ShColors.baseL,
      backgroundColor: ShColors.blackLL,
      '&:hover': {
        backgroundColor: lighten(0.2, ShColors.blackLL),
        transition: `background-color 150ms ${ShEasings.easeOut},
                      color 150ms ${ShEasings.easeOut}`,
      },
      '&:active, &:focus': {
        backgroundColor: lighten(0.2, ShColors.blackLL),
        transition: `background-color .1s ${ShEasings.ease},
                      color .1s ${ShEasings.ease}`,
      },
    },
  },
  stuck: {
    spinnerColor: 'white',
    buttonCss: {
      color: ShColors.white,
      backgroundColor: ShStateColors.postprodVideo.stuck,
      '&:hover': {
        backgroundColor: lighten(0.2, ShStateColors.postprodVideo.stuck),
        transition: `background-color 150ms ${ShEasings.easeOut},
                      color 150ms ${ShEasings.easeOut}`,
      },
      '&:active, &:focus': {
        backgroundColor: lighten(0.2, ShStateColors.postprodVideo.stuck),
        transition: `background-color .1s ${ShEasings.ease},
                      color .1s ${ShEasings.ease}`,
      },
    },
  },
  changes: {
    spinnerColor: 'white',
    buttonCss: {
      color: ShColors.white,
      backgroundColor: ShStateColors.postprodVideo.changes,
      '&:hover': {
        backgroundColor: lighten(0.2, ShStateColors.postprodVideo.changes),
        transition: `background-color 150ms ${ShEasings.easeOut},
                      color 150ms ${ShEasings.easeOut}`,
      },
      '&:active, &:focus': {
        backgroundColor: lighten(0.2, ShStateColors.postprodVideo.changes),
        transition: `background-color .1s ${ShEasings.ease},
                      color .1s ${ShEasings.ease}`,
      },
    },
  },
};

export const useShButtonVariant = (
  variant?: ShButtonVariant,
): ShButtonVariant => {
  const defaultVariant = useShTheme().button.variant;
  return variant ?? defaultVariant;
};

export const ShButtonSizes = ['xs','ss', 's', 'sm', 'm', 'l'] as const;
export type ShButtonSize = typeof ShButtonSizes[number];
type ShButtonSizeConfig = {
  buttonCss: CSSObject;
};

export const ShButtonSizeConfigs: {
  [key in ShButtonSize]: ShButtonSizeConfig;
} = {
  xs: {
    buttonCss: {
      padding: '3px 4px',
      fontSize: 10,
    },
  },
  ss: {
    buttonCss: {
      padding: '4px 6px',
      fontSize : 12,
    },
  },
  s: {
    buttonCss: {
      padding: '6px 10px',
    },
  },
  sm: {
    buttonCss: {
      padding: '10.6px 10px 7.4px',
    },
  },
  m: {
    buttonCss: {
      padding: '10.6px 20px 7.4px',
    },
  },
  l: {
    buttonCss: {
      padding: '18px 20px 12px',
      fontSize: 16,
    },
  },
};
export const useShButtonSize = (sizeProp?: ShButtonSize): ShButtonSize => {
  const defaultSize = useShTheme().button.size;
  return sizeProp ? sizeProp : defaultSize;
};

export type ShButtonProps = {
  children?: ReactNode;
  variant?: ShButtonVariant;
  size?: ShButtonSize;
  disabled?: boolean;
  onClick?: (e?: any) => void;
  loading?: boolean;
  active?: boolean;
  isInGroup?: boolean;
  isLastInGroup?: boolean;
  isFirstInGroup?: boolean;
  width?: string | number;
};

export const ShButton = ({
  children,
  variant: themeProp,
  size: sizeProp,
  disabled = false,
  onClick,
  loading = false,
  width,
}: ShButtonProps) => {
  const theme = useShButtonVariant(themeProp);
  const size = useShButtonSize(sizeProp);
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      css={getShButtonStyle({
        theme,
        size,
        disabled,
        loading,
        width,
      })}
    >
      {
        <ShSpinnerOverlay
          visible={loading}
          size="xs" //TODO ? why xs ?
          color={ShButtonVariantConfigs[theme].spinnerColor}
        />
      }
      {children}
    </button>
  );
};

const DisabledOption = {
  pointerEvents: 'none',
  backgroundColor: ShColors.disabled,
  color: ShColors.white,
  border: 'none',
  // padding: '10.6px 20px 7.4px',
  transition: `background-color 150ms ${ShEasings.easeOut},
                      color 150ms ${ShEasings.easeOut}`,
} as const;

const LoadingOption = {
  color: ShColors.transparent,
  '&:hover, &:active,  &:focus': {
    color: ShColors.transparent,
  },
} as const;

export const getShButtonStyle = ({
  theme,
  size,
  disabled = false,
  loading = false,
  width,
}: {
  theme: ShButtonVariant;
  size: ShButtonSize;
  disabled?: boolean;
  loading?: boolean;
  width?: string | number;
}): CSSObject => {
  return {
    position: 'relative',
    padding: '10.6px 20px 7.4px',
    borderRadius: ShRadius.m,
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    textAlign: 'center',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    fontWeight: ShFontWeights.m,
    fontFamily: ShFonts.secondary,
    fontSize: 12,
    lineHeight: 1.1,
    transition: `background-color .1s ${ShEasings.easeIn},
                    color .1s ${ShEasings.easeIn}`,
    ...ShButtonVariantConfigs[theme].buttonCss,
    ...ShButtonSizeConfigs[size].buttonCss,
    ...(disabled && DisabledOption),
    ...(loading && LoadingOption),
    ...(width && { width }),
  };
};
