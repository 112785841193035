/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ChangeEventHandler } from 'react';

import {
  ShColors,
  ShFonts,
  ShMediaQueries,
  ShRadius,
} from '@shoootin/design-tokens';
import {
  ShInputTextProps,
  ShInputItemContainer,
} from '../ShInputText/ShInputText';

import { ShInputError } from '../utils/ShInputError/ShInputError';
import { ShInputRequiredMark } from '../utils/ShInputRequiredMark/ShInputRequiredMark';
import { ShInputIcon } from '../utils/ShInputIcon/ShInputIcon';
import {
  ShInputSize,
  ShInputSizeConfigs,
  useShInputSize,
} from '../ShInputTheme';

export type ShInputTextareaProps = Omit<
  ShInputTextProps,
  'type' | 'inputRef' | 'onChange'
> & {
  rows?: number;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  themeSize?: ShInputSize;
};

export const ShInputTextarea = ({
  themeSize: sizeProp,
  error,
  icon,
  required = false,
  placeholder,
  value,
  onChange,
  rows,
}: ShInputTextareaProps) => {
  const size = useShInputSize(sizeProp);
  return (
    <div
      css={{
        fontSize: 16,
        position: 'relative',
        textAlign: 'left',
        userSelect: 'none',
        [ShMediaQueries.ShMobileOnly]: {
          fontSize: 14,
        },
      }}
    >
      <textarea
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        value={value}
        rows={rows}
        css={{
          display: 'block',
          width: '100%',
          padding: ShInputSizeConfigs[size].inputCss.padding,
          border: 'none',
          borderRadius: ShRadius.m,
          backgroundColor: ShColors.whiteD,
          borderColor: ShColors.whiteD,
          boxSizing: 'border-box',
          color: ShColors.black,
          fontFamily: ShFonts.primary,
          fontSize: ShInputSizeConfigs[size].textAreaCSS.fontSize,
          [ShMediaQueries.ShMobileOnly]: {
            fontSize: 14,
          },
          lineHeight: 1.2,
          resize: 'vertical',
          minHeight:
            rows &&
            rows *
              (+ShInputSizeConfigs[size].textAreaCSS.fontSize! ?? 16) *
              1.2,
          '&::placeholder': {
            color: ShColors.placeholder,
          },

          '&:focus': {
            outline: 'none',
          },
        }}
      />
      {icon && (
        <ShInputItemContainer
          size={size}
          css={{
            position: 'absolute',
            top: 0,
            right: 0,
            borderRadius: `0px ${ShRadius.m}px 0px 0px`,
          }}
          onClick={icon.onClick}
        >
          <ShInputIcon component={icon.component} />
        </ShInputItemContainer>
      )}
      {required && (
        <ShInputItemContainer
          size={size}
          css={{ position: 'absolute', top: 3, right: 3, padding: 0 }}
        >
          <ShInputRequiredMark type="star" />
        </ShInputItemContainer>
      )}
      {error && <ShInputError>{error}</ShInputError>}
    </div>
  );
};
