/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, {ReactNode} from 'react';
import {
  ShColors,
  ShFonts,
  ShFontWeights,
  ShColor,
} from '@shoootin/design-tokens';

export type ShTitleSize = 1 | 2 | 3 | 4 | 5 | 6;

type AppTitleProps = {
  children?: string | ReactNode;
  size?: ShTitleSize;
  color?: ShColor;
};

export const ShTitle = ({
  size = 1,
  children,
  color = 'black',
}: AppTitleProps) => (
  <div
    css={{
      fontFamily: ShFonts.secondary,
      fontWeight: ShFontWeights.m,

      '*': {
        color: ShColors[color],
        margin: 0,
      },
    }}
  >
    {jsx(`h${size}`, {}, children)}
  </div>
);
