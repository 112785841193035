/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { ReactNode } from 'react';

import {
  ShColors,
  ShMediaQueries,
  ShMediaQueriesDynamic,
} from '@shoootin/design-tokens';
import {
  ShRadioField,
  ShRadioFieldProps,
} from '../../components/input/ShRadioField/ShRadioField';

const rem = (value: number) => `${value / 16}rem`;
const spacing = (ratio: number = 1) => `${1.5 * ratio}rem`;
const styles = {
  collapseRadio: {
    display: 'block',
  },
  collapseRadioHeader: {
    borderTop: `1px solid ${ShColors.whiteD}`, // Example color value
    marginRight: spacing(0.75),
    marginLeft: spacing(0.75),
    paddingTop: spacing(),
    paddingBottom: spacing(0.5),
    [ShMediaQueries.ShDesktopsOnly]: {
      marginRight: spacing(),
      marginLeft: spacing(),
      paddingLeft: spacing(0),
      paddingTop: spacing(),
      paddingBottom: spacing(),
    },
    [ShMediaQueries.ShMobileOnly]: {},
  },
  collapseRadioSubLabel: {
    [ShMediaQueries.ShDesktopsOnly]: {
      paddingLeft: spacing(2),
    },
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    [ShMediaQueries.ShMobileOnly]: {
      fontSize: 12,
    },
  },
  collapseRadioPanel: {
    overflow: 'hidden',
    height: 0,
    transition: 'height 250ms ease-in',
    pointerEvents: 'none' as 'none',
  },
  openedCollapseRadioPanel: {
    height: 'auto',
    transition: 'height 200ms ease-out',
    pointerEvents: 'auto' as 'auto',
    [ShMediaQueries.ShDesktopsOnly]: {
      paddingLeft: rem(50),
      paddingRight: rem(50),
    },
    '> p': {
      padding: `0 ${spacing(0.75)} ${spacing(1)}`,
      [ShMediaQueries.ShMobileOnly]: {
        fontSize: 12,
      },
      [ShMediaQueries.ShDesktopsOnly]: {
        padding: `.5em ${spacing()} ${spacing(1.125)}`,
      },
    },
  },
};

interface ShOfferCollapseProps extends ShRadioFieldProps {
  sublabel: ReactNode;
  children: ReactNode;
}

export const ShOfferCollapse: React.FC<ShOfferCollapseProps> = ({
  sublabel,
  children,
  ...otherProps
}) => {
  return (
    <div css={styles.collapseRadio}>
      <div css={styles.collapseRadioHeader}>
        <ShRadioField
          {...otherProps}
          bold={true}
          size={ShMediaQueriesDynamic.ShMobileOnlyDynamic() ? 's' : 'm'}
        />
        <div css={styles.collapseRadioSubLabel}>{sublabel}</div>
      </div>
      <div
        css={
          otherProps.value
            ? styles.openedCollapseRadioPanel
            : styles.collapseRadioPanel
        }
      >
        {children}
      </div>
    </div>
  );
};
