import React from 'react';

const VisaSvg = ({ width = 500 }: { width?: number }) => (
  <svg viewBox="0 0 500 163" width={width} height={width * 0.326}>
    <linearGradient
      id="visa-gradient"
      gradientUnits="userSpaceOnUse"
      x1={16.2495}
      y1={167.5675}
      x2={483.7672}
      y2={-3.9879}
    >
      <stop offset={0} stopColor="#222357" />
      <stop offset={1} stopColor="#254AA5" />
    </linearGradient>
    <path
      fill="url(#visa-gradient)"
      d="M258.6,52.4c-0.3,22.4,20,34.9,35.2,42.3c15.7,7.6,20.9,12.5,20.9,19.3c-0.1,10.4-12.5,15-24.1,15.2c-20.2,0.3-32-5.5-41.3-9.8l-7.3,34.1c9.4,4.3,26.7,8.1,44.7,8.3c42.3,0,69.9-20.9,70.1-53.2c0.2-41-56.8-43.3-56.4-61.7c0.1-5.6,5.4-11.5,17.1-13c5.8-0.8,21.7-1.3,39.7,7l7.1-33C354.5,4.4,342,1,326.5,1C286.8,1,258.8,22.1,258.6,52.4 M432.2,3.8c-7.7,0-14.2,4.5-17.1,11.4l-60.4,144.1h42.2l8.4-23.2h51.6l4.9,23.2H499L466.5,3.8H432.2 M438.1,45.9l12.2,58.4h-33.4L438.1,45.9M207.4,3.8l-33.3,155.6h40.2L247.6,3.8H207.4 M147.8,3.8l-41.9,105.9L89,19.7c-2-10-9.8-15.9-18.6-15.9H2L1,8.4c14.1,3.1,30,8,39.7,13.2c5.9,3.2,7.6,6,9.6,13.7l32.1,124.1h42.5L190.1,3.8L147.8,3.8"
    />
  </svg>
);

export default VisaSvg;
