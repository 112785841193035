/** @jsx jsx */
import { jsx } from '@emotion/core';

import { SvgProps } from '../../../components/svg/SvgUtils';
import { ShColors } from '@shoootin/design-tokens';

export const FullscreenSvg = ({ width = 16, color = 'black' }: SvgProps) => (
  <svg
    height={width}
    width={width}
    viewBox="0 0 16 16"
    stroke={ShColors[color]}
    fill="none"
    css={{
      fill: 'none !important',
    }}
  >
    <polyline points="2.5,5 2.5,2.5 5,2.5 " />
    <polyline points="5,13.5 2.5,13.5 2.5,11 " />
    <polyline points="13.5,11 13.5,13.5 11,13.5 " />
    <polyline points="11,2.5 13.5,2.5 13.5,5 " />
    <line x1={10} y1={10} x2={13.5} y2={13.5} />
    <line x1={2.5} y1={2.5} x2={6} y2={6} />
    <line x1={6} y1={10} x2={2.5} y2={13.5} />
    <line x1={13.5} y1={2.5} x2={10} y2={6} />
  </svg>
);

export default FullscreenSvg;
