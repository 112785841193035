/** @jsx jsx */
import { jsx } from '@emotion/core';

import React, { ReactNode, useRef, useState } from 'react';
import { ShButton } from '../../button/ShButton/ShButton';
import { ShButtonAsync } from '../../button/ShButton/ShButtonAsync';
import { createHash } from 'crypto';
import { ShApiClient } from '@shoootin/api';
import axios from 'axios';
import { ShColors } from '@shoootin/design-tokens';
import { TrashSvg } from '../../../../components/svg/icon/TrashSvg';
import { ShSpinner } from '../../spinner/ShSpinner/ShSpinner';

export type ShInputFileUploadResult = {
  filename: string;
  // uploadedUrl: string;
  objectKey: string;
  randomFilename: string;
};
//this component will be used to upload to s3 temporary files

type PresignedUrlRequest = {
  filename: string;
  contentType: string;
  // md5: string;
};
type PresignedUrlResponse = {
  objectKey: string;
  url: string;
  // exists: boolean;
  presignedUploadUrl: string;
};
// const getFileMd5 = (file: File): Promise<string> => {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//
//     reader.onload = () => {
//       const hash = createHash('md5');
//       const buffer = reader.result as ArrayBuffer;
//       const uint8Array = new Uint8Array(buffer);
//       const dataBuffer = Buffer.from(uint8Array);
//
//       hash.update(dataBuffer);
//
//       const md5Hash = hash.digest();
//       resolve(md5Hash.toString('hex'));
//     };
//
//     reader.onerror = () => {
//       reject(new Error('Failed to read file.'));
//     };
//
//     reader.readAsArrayBuffer(file);
//   });
// };
const randomFileName = (filename: string) => {
  const min = 0,
    max = 99999;
  const random = Math.floor(Math.random() * (max - min) + min);
  return `${new Date().getTime()}-${random}-${filename}`;
};

const toPresignUrlRequest = async (
  file: File,
): Promise<PresignedUrlRequest> => {
  console.log({ file });
  const filename = `${randomFileName(file.name)}`;
  const contentType = file.type;
  // const md5 = await getFileMd5(file);
  return {
    filename,
    contentType,
    // md5,
  };
};

const handleUpload = async (
  selectedFile: File,
  setProgress: (progress: number) => void,
): Promise<ShInputFileUploadResult> => {
  const payload = await toPresignUrlRequest(selectedFile!);
  console.log(payload);
  const result = await ShApiClient.call<PresignedUrlResponse>({
    method: 'POST',
    url: `/front/fileupload/generatePresignedUploadUrl`,
    data: payload,
  });
  console.log(result);
  // const fileStream = await getFileStream(selectedFile!);
  const headers = {
    'Content-Type': payload.contentType,
    // 'Content-Length': selectedFile!.size.toString(), // Seems required in nodejs but not in browser
    // 'Content-Md5': btoa(payload.md5), // deactivating this for now as it causes error 400
  };
  console.log(headers);
  const uploadResult = await axios.put(
    result.presignedUploadUrl,
    selectedFile!,
    {
      headers,
      onUploadProgress: (progressEvent) => {
        console.log(
          `Upload Progress: ${
            (progressEvent.loaded * 100) / progressEvent.total
          }%`,
        );
        setProgress(
          Math.ceil((progressEvent.loaded * 100) / progressEvent.total),
        );
        if (progressEvent.loaded === progressEvent.total) {
          console.log('upload complete');
        }
      },
    },
  );

  console.log('\n');
  console.log(
    'uploadFile result',
    uploadResult.status,
    uploadResult.statusText,
    { expectedUrl: result.presignedUploadUrl },
  );
  console.log('\n');
  return {
    filename: selectedFile.name,
    randomFilename: payload.filename,
    objectKey: result.objectKey,
    // uploadedUrl: result.url,
  };
};

export const ShInputFile = ({
  onFileSelect,
  onFileDelete,
  onFileUploaded,
  children,
  autoUpload,
}: {
  onFileSelect?: (file: File) => void;
  onFileDelete?: () => void;
  onFileUploaded?: (result: ShInputFileUploadResult) => void;
  children: ReactNode;
  autoUpload: boolean;
}) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const [file, setFile] = React.useState<File | undefined>(undefined);

  const [progress, setProgress] = React.useState<number>(0);
  const loading = progress > 0 && progress < 100;
  const uploaded = progress === 100;

  const upload = async (file: File) => {
    const result = await handleUpload(file, setProgress);
    onFileUploaded && onFileUploaded(result);
  };

  const handleFileInput = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    // handle validations
    if (event.target.files !== null && event.target.files.length > 0) {
      const chosenFile = event.target.files[0];
      setProgress(0);
      setFile(chosenFile);
      onFileSelect && onFileSelect(chosenFile);
      if (autoUpload) {
        await upload(chosenFile);
      }
    }
  };

  const handleFileDelete = () => {
    setProgress(0);
    setFile(undefined);
    onFileDelete && onFileDelete();
  };

  return (
    <div>
      <input
        ref={fileInput}
        type="file"
        onChange={handleFileInput}
        css={{ display: 'none' }}
      />
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 10,
        }}
      >
        {!uploaded && !loading && (
          <ShButton
            onClick={() => fileInput.current && fileInput.current.click()}
          >
            {children}
          </ShButton>
        )}
        {!uploaded && loading && (
          <div>
            <ShSpinner size={'xs'} color={'base'} />
          </div>
        )}
        {uploaded && <div>File upload complete</div>}

        {file && (
          <div css={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            <small>
              <b>{file.name}</b>
            </small>
            {onFileDelete && (
              <div onClick={handleFileDelete} css={{ cursor: 'pointer' }}>
                <TrashSvg width={17} color={'blackLL'} />
              </div>
            )}
          </div>
        )}
        {!autoUpload && (
          <ShButtonAsync
            disabled={!file}
            onClick={async () => {
              await upload(file!);
            }}
          >
            Upload
          </ShButtonAsync>
        )}
      </div>
      {file && (
        <div css={{ height: 5, width: '100%', marginTop: 2, border: 2 }}>
          <div
            css={{
              height: 5,
              backgroundColor: ShColors.base,
              width: `${progress}%`,
            }}
          ></div>
        </div>
      )}
    </div>
  );
};
