/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import { ShColors } from '@shoootin/design-tokens';

import { ShVideoCommentForm } from '@shoootin/api';
import { ShButton } from '../../designSystem/primitives/button/ShButton/ShButton';

import { ShInputTextarea } from '../../designSystem/primitives/input/ShInputTextarea/ShInputTextarea';
import { ShInputCheckbox } from '../../designSystem/primitives/input/ShInputCheckbox/ShInputCheckbox';
import { SvgPrivate, SvgPublic, SvgTimeCode } from './ShVideoIcons';
import { secondsToDuration } from './ShVideoReviewUtils';
import { ShButtonAsync } from '../../designSystem/primitives/button/ShButton/ShButtonAsync';

export const AddCommentForm = ({
  addComment,
  videoPlaying,
  playVideo,
  pauseVideo,
  getCurrentTime,
}: {
  addComment: (comment: ShVideoCommentForm) => void;
  videoPlaying: boolean;
  playVideo: () => void;
  pauseVideo: () => void;
  getCurrentTime: () => number;
}) => {
  const [commentValue, setCommentValue] = useState<string>('');
  const [timecode, setTimecode] = useState<boolean>(true);
  const [currentTime, setCurrentime] = useState<number>(0);
  const [internal, setInternal] = useState<boolean>(true);
  return (
    <div
      css={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div
        css={{
          width: '100%',
          backgroundColor: ShColors.blackLL,
          padding: 10,
          // borderRadius: 10,
          border: internal
            ? `1px solid ${ShColors.baseL}`
            : `1px solid ${ShColors.blackLL}`,
        }}
      >
        <div css={{ marginBottom: 10 }}>
          <ShInputTextarea
            themeSize={'s'}
            value={commentValue}
            onChange={(e) => {
              if (videoPlaying) {
                pauseVideo();
              }
              setCommentValue(e.target.value);
              setCurrentime(getCurrentTime());
            }}
            placeholder={'Leave your comment here ...'}
            rows={2}
          />
        </div>
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              css={{
                display: 'flex',
                backgroundColor: ShColors.blackL,
                padding: 5,
                borderRadius: 3,
                alignItems: 'center',
                color: internal ? ShColors.base : ShColors.white,
              }}
            >
              <span css={{ paddingRight: 10 }}>
                <SvgTimeCode />
              </span>

              <span css={{ paddingRight: 10, fontSize: 14 }}>
                {secondsToDuration(currentTime)}
              </span>
              <span
                data-for="mainCommentForm"
                data-tip={'Check for timeline comment'}
              >
                <ShInputCheckbox
                  value={timecode}
                  onChange={setTimecode}
                  size={'xs'}
                />
              </span>
            </div>
            <div
              css={{
                display: 'flex',
                backgroundColor: ShColors.blackL,
                borderRadius: 3,
                alignItems: 'center',
                marginLeft: 10,
              }}
            >
              <span
                css={{
                  padding: 4,
                  paddingRight: 8,
                  paddingLeft: 8,
                  cursor: 'pointer',
                  backgroundColor: internal ? 'unset' : ShColors.base,
                }}
                onClick={() => setInternal(false)}
                data-for="mainCommentForm"
                data-tip={'Public comment'}
              >
                <SvgPublic />
              </span>
              <span
                css={{
                  padding: 4,
                  paddingRight: 8,
                  paddingLeft: 8,
                  cursor: 'pointer',
                  backgroundColor: !internal ? 'unset' : ShColors.base,
                }}
                onClick={() => setInternal(true)}
                data-for="mainCommentForm"
                data-tip={'Private comment'}
              >
                <SvgPrivate />
              </span>
            </div>
          </div>
          <ShButtonAsync
            size={'s'}
            variant={internal ? 'glass' : 'ghost'}
            disabled={commentValue === ''}
            onClick={() => {
              addComment({
                comment: commentValue,
                currentTime: timecode ? getCurrentTime() : 0,
                internal,
              });
              setCommentValue('');
              setTimeout(() => {
                playVideo();
              }, 1000);
            }}
          >
            Send
          </ShButtonAsync>
        </div>
      </div>
    </div>
  );
};

export const CommentEditForm = ({
  value,
  closeEdit,
  saveComment,
}: {
  value: string;
  closeEdit: () => void;
  saveComment: (value: string) => void;
}) => {
  const [commentValue, setCommentValue] = useState<string>(value);

  return (
    <div>
      <div css={{ paddingBottom: 5 }}>
        <ShInputTextarea
          themeSize={'s'}
          rows={4}
          value={commentValue}
          onChange={(e) => {
            setCommentValue(e.target.value);
          }}
        />
      </div>
      <div css={{ display: 'flex', justifyContent: 'space-between' }}>
        <div css={{ paddingRight: 10 }}>
          <ShButtonAsync
            size={'s'}
            variant={'glass'}
            onClick={() => {
              saveComment(commentValue);
              closeEdit();
            }}
          >
            Save
          </ShButtonAsync>
        </div>
        <div>
          <ShButton size={'s'} variant={'glass'} onClick={closeEdit}>
            Cancel
          </ShButton>
        </div>
      </div>
    </div>
  );
};
