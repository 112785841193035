/** @jsx jsx */
import { jsx } from '@emotion/core';

import React, { useState } from 'react';
import { ShSpinner } from '../../designSystem/primitives/spinner/ShSpinner/ShSpinner';
import VideoPlaySvg from '../../components/svg/icon/VideoPlaySvg';
import LogoSvg from '../../components/svg/LogoSvg';
import { ShMatterportClientDTO } from '@shoootin/api';
import { ShColors, ShFonts, ShMediaQueries } from '@shoootin/design-tokens';
import {ShText} from "@shoootin/translations";

export const ShSingleMatterportLoading = () => {
  return (
    <div
      css={{
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        height: '100%',
        width: '100%',
        border: 0,
        backgroundColor: 'black',
      }}
    >
      <div
        css={{
          color: 'white',
          overflow: 'hidden',
          position: 'absolute',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ShSpinner size="s" color="white" />
      </div>
    </div>
  );
};

export const ShSingleMatterport = ({
  matterport,
  withShoootinLogo = true,
  isVisible = false,
}: {
  matterport: ShMatterportClientDTO;
  withShoootinLogo?: boolean;
  isVisible?: boolean;
}) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(isVisible);

  const inactive = !matterport.isPublic;
  console.debug({matterport, inactive});

  return (
    <div css={{ position: 'absolute', top: 0, bottom: 0, right: 0, left: 0 }}>
      {!visible && (
        <div
          css={{
            backgroundImage: `url(${matterport.matterportImage})`,
            backgroundColor: 'black',
            zIndex: 15,
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            textAlign: 'center',
          }}
        >
          <div
            css={{
              backgroundColor: 'rgba(0,0,0,0.5)',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              width: '100%',
            }}
          >
            <div
              css={{
                padding: 5,
                width: 'auto',
                margin: 10,
                color: 'white',
                textAlign: 'center',
              }}
            >
              {matterport.clientLogoUrl && (
                <img
                  src={matterport.clientLogoUrl}
                  css={{
                    maxHeight: 70,
                    maxWidth: '30%',
                  }}
                />
              )}

              <h1
                css={{
                  fontSize: 25,
                  fontFamily: ShFonts.secondary,
                  color: ShColors.white,
                  marginBottom: 10,
                  [ShMediaQueries.ShMobileOnly]: {
                    fontSize: 20,
                  },
                }}
              >
                {matterport.title}
              </h1>
              <h2
                css={{
                  fontSize: 20,
                  fontFamily: ShFonts.secondary,
                  color: ShColors.white,
                  marginBottom: 10,
                  [ShMediaQueries.ShMobileOnly]: {
                    fontSize: 16,
                  },
                }}
              >
                {matterport.subtitle}
              </h2>
              {inactive && (
                <div
                  css={{
                    fontSize: 20,
                    fontFamily: ShFonts.secondary,
                    color: ShColors.error,
                    marginBottom: 10,
                    [ShMediaQueries.ShMobileOnly]: {
                      fontSize: 16,
                    },
                  }}
                >
                    <ShText
                        message={'client_matterport_billing_matterportInactive'}
                    />
                </div>
              )}
              {!inactive && (
                <div css={{ height: 80 }}>
                  {loaded && (
                    <a
                      onClick={() => setVisible(true)}
                      css={{
                        cursor: 'pointer',
                      }}
                    >
                      <VideoPlaySvg
                        color={'white'}
                        width={30}
                        strokeWidth={3}
                      />
                    </a>
                  )}
                  {!loaded && (
                    <div>
                      <ShSpinner color="white" size="s" />
                    </div>
                  )}
                </div>
              )}
              {withShoootinLogo && (
                <a
                  css={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}
                  href={'/service/matterport'}
                  target={'_blank'}
                >
                  <LogoSvg width={100} color={'white'} />
                </a>
              )}
            </div>
          </div>
        </div>
      )}

      <div>
        {matterport.clientLogoUrl && (
          <img
            src={matterport.clientLogoUrl}
            css={{
              position: 'absolute',
              display: visible ? 'block' : 'none',
              right: 20,
              top: 20,
              zIndex: 10,
              maxHeight: 70,
              maxWidth: '20%',
            }}
          />
        )}
        {!inactive && (
          <iframe
            width="100%"
            height="100%"
            src={matterport.matterportLink}
            frameBorder={0}
            allowFullScreen
            allow="vr"
            onLoad={() => {
              setLoaded(true);
            }}
            css={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              height: '100%',
              width: '100%',
              border: 0,
              backgroundColor: 'black',
            }}
          />
        )}
        {withShoootinLogo && (
          <div
            css={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              width: '20%',
              margin: 'auto',
              textAlign: 'center',
            }}
          >
            <a href={'/service/matterport'} target={'_blank'}>
              <LogoSvg width={100} color={'white'} />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};
