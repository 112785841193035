/** @jsx jsx */
import { jsx } from '@emotion/core';

import { SvgProps } from '../../../components/svg/SvgUtils';
import { ShColors } from '@shoootin/design-tokens';

export const TrashSvg = ({ width = 28, color = 'black' }: SvgProps) => (
  <svg
    height={width}
    width={width}
    viewBox="0 0 28 28"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    stroke={ShColors[color]}
    css={{
      fill: 'none !important',
    }}
  >
    <g>
      <rect x={7.5} y={6.5} width={14} height={2} />
      <polyline points="11.5,6.5 11.5,4.5 17.5,4.5 17.5,6.5 " />
    </g>
    <polygon points="20.5,8.5 8.5,8.5 9.5,23.5 19.5,23.5 " />
    <line x1={14.5} y1={10.5} x2={14.5} y2={21.5} />
    <line x1={12.5} y1={10.5} x2={12.5} y2={21.5} />
    <line x1={16.5} y1={10.5} x2={16.5} y2={21.5} />
  </svg>
);

export default TrashSvg;
