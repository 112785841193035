import { useMemo } from 'react';
import FuseJS, { IFuseOptions, FuseSearchOptions } from 'fuse.js';
import useConstant from 'use-constant';

// Hook to easily search in a set of documents with FuseJS
export const useShSearch = <T extends object>({
  text,
  docs,
  createIndexOptions,
  minText = 1,
  limit = 20,
}: {
  text: string;
  docs: T[];
  createIndexOptions: () => IFuseOptions<T>;
  minText?: number;
  limit?: number;
}): T[] => {
  const indexOptions = useConstant(() => createIndexOptions());

  const searchEngine = useMemo(() => {
    return new FuseJS(docs, indexOptions);
  }, [docs, indexOptions]);

  return useMemo(() => {
    if (text.length <= minText) {
      return [];
    }
    const searchOptions: FuseSearchOptions = { limit };
    const searchResults = searchEngine.search(text, searchOptions);
    return searchResults.map((searchResult) => searchResult.item);
  }, [searchEngine, text]);
};
