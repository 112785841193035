/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { ShColors } from '@shoootin/design-tokens';
import { ShTooltip } from '../../designSystem/primitives/ShTooltip/ShTooltip';

export const Tooltips = () => {
  return (
    <div>
      <ShTooltip
        id={'mainCommentForm'}
        place={'top'}
        offset={{ top: 10 }}
        backgroundColor={ShColors.blackL}
      />
      <ShTooltip
        id={'comments'}
        place={'left'}
        offset={{ left: 10 }}
        backgroundColor={ShColors.blackL}
      />
    </div>
  );
};
