/** @jsx jsx */
import { jsx } from '@emotion/core';
import { SvgProps } from '../../../components/svg/SvgUtils';
import { ShColors } from '@shoootin/design-tokens';
import React from 'react';

export const ArrowPrevSvg = ({ width = 24, color = 'black' }: SvgProps) => (
  <svg
    className="chevron-prev"
    viewBox="0 0 28 28"
    height={width * (2 / 3)}
    width={width}
    fill="none"
    stroke={ShColors[color]}
  >
    <polyline points="20,26.25 7.75,14 20,1.75 " />
  </svg>
);

export default ArrowPrevSvg;
