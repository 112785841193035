/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import { ShColors } from '@shoootin/design-tokens';
import {
  getProgressBarIndicatorPosition,
  getProgressBarSpriteYPosition,
  secondsToDuration,
  PROGRESSBAR_SPRITE_WIDTH,
  getProgressBarSpriteTooltipPosition,
  getProgressBarCurrentTimeTooltipPosition,
  PROGRESSBAR_CURRENT_TIME_WIDTH,
} from './ShVideoReviewUtils';
import { ShVideoSpriteClientDTO } from '@shoootin/api';

export const getPosition = (e: React.MouseEvent<HTMLElement>) => {
  const bounds = e.currentTarget.getBoundingClientRect();
  return Math.max(0, Math.min((e.clientX - bounds.left) / bounds.width, 1));
};

export const PROGRESSBAR_HEIGHT = 8;
const DEBUG_TOOLTIPS = false;

const ProgressBarLoaded = ({ loaded }: { loaded: number }) => {
  return (
    <div
      css={{
        position: 'absolute',
        top: 10,
        height: PROGRESSBAR_HEIGHT,
        backgroundColor: ShColors.blackL,
      }}
      style={{
        width: `${Math.min(loaded * 100, 100)}%`,
      }}
    />
  );
};

const ProgressBarPlayed = ({ played }: { played: number }) => {
  return (
    <div
      style={{
        width: `${Math.min(played * 100, 100)}%`,
      }}
      css={{
        position: 'absolute',
        top: 10,
        height: PROGRESSBAR_HEIGHT,
        backgroundColor: ShColors.base,
      }}
    />
  );
};

const ProgressBarBackground = () => {
  return (
    <div
      css={{
        position: 'absolute',
        top: 10,
        height: PROGRESSBAR_HEIGHT,
        backgroundColor: ShColors.black,
        width: '100%',
      }}
    />
  );
};

const ProgressBarHoverIndicator = ({
  hover,
  width,
}: {
  hover: number;
  width: number;
}) => {
  const position = getProgressBarIndicatorPosition(width, hover);
  return (
    <div
      css={{
        position: 'absolute',
        height: PROGRESSBAR_HEIGHT,
        backgroundColor: ShColors.white,
        width: 4,
        top: 10,
      }}
      style={{
        left: position,
      }}
    />
  );
};

const ProgressBarHoverDurationAndSprite = ({
  duration,
  hover,
  sprite,
  width,
}: {
  duration: number;
  hover: number;
  sprite?: ShVideoSpriteClientDTO;
  width: number;
}) => {
  const seconds = hover * duration;
  if (sprite) {
    const height = Math.floor(sprite.height / sprite.nb);
    return (
      <div
        css={{
          position: 'absolute',
          height: height,
          backgroundColor: ShColors.blackLL,
          color: ShColors.white,
          width: PROGRESSBAR_SPRITE_WIDTH,
          top: -(height + 10),
          fontSize: 12,
          textAlign: 'center',
          overflow: 'hidden',
        }}
        style={{
          left: getProgressBarSpriteTooltipPosition(width, hover),
        }}
      >
        <img
          src={sprite.url}
          css={{
            position: 'absolute',
            left: 0,
          }}
          style={{
            transform: `translateY(-${getProgressBarSpriteYPosition(
              seconds,
              duration,
              sprite,
            )}px)`,
          }}
          width={sprite.width}
          height={sprite.height}
        />
        <div
          css={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            margin: '0 auto',
            padding: 3,
            width: PROGRESSBAR_CURRENT_TIME_WIDTH,
            backgroundColor: ShColors.blackLL,
            color: ShColors.white,
          }}
        >
          {secondsToDuration(seconds)}
        </div>
      </div>
    );
  } else {
    return (
      <div
        css={{
          position: 'absolute',
          height: 22,
          backgroundColor: ShColors.blackLL,
          color: ShColors.white,
          width: PROGRESSBAR_CURRENT_TIME_WIDTH,
          padding: 3,
          top: -20,
          fontSize: 12,
          textAlign: 'center',
          overflow: 'hidden',
        }}
        style={{
          left: getProgressBarCurrentTimeTooltipPosition(width, hover),
        }}
      >
        {secondsToDuration(seconds)}
      </div>
    );
  }
};

export const ProgressBar = ({
  played,
  loaded,
  duration,
  seekTo,
  sprite,
  width,
}: {
  played: number;
  loaded: number;
  duration: number;
  seekTo: (s: number) => void;
  sprite?: ShVideoSpriteClientDTO;
  width: number;
}) => {
  const [hover, setHover] = useState<number | undefined>(undefined);

  return (
    <div
      css={{
        height: PROGRESSBAR_HEIGHT + 10,
        paddingTop: 10,
        marginBottom: 0,
        position: 'relative',
        cursor: 'pointer',
      }}
      onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
        setHover(getPosition(e))
      }
      onMouseMove={(e: React.MouseEvent<HTMLElement>) =>
        setHover(getPosition(e))
      }
      onMouseLeave={() => !DEBUG_TOOLTIPS && setHover(undefined)}
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        const position = getPosition(e);
        seekTo(position * duration);
      }}
    >
      <ProgressBarBackground />
      <ProgressBarLoaded loaded={loaded} />
      <ProgressBarPlayed played={played} />

      {!!hover && (
        <ProgressBarHoverDurationAndSprite
          duration={duration}
          hover={hover}
          width={width}
          sprite={sprite}
        />
      )}
      {!!hover && <ProgressBarHoverIndicator hover={hover} width={width} />}
    </div>
  );
};
