import {
  ShColors,
  ShFonts,
  ShFontWeights,
  ShMediaQueries,
} from '@shoootin/design-tokens';
import { darken } from 'polished';
import { ShStyles } from '../../designSystem/constants/ShStyles';

export const inlineCheckboxLabelStyle = (bold: boolean) =>
  ({
    ...ShStyles.label,
    display: 'flex',
    alignItems: 'center',
    fontFamily: bold ? ShFonts.secondary : ShFonts.primary,
    fontWeight: ShFontWeights.m,
    cursor: 'pointer',

    '&:hover': {
      span: {
        backgroundColor: darken(0.025, ShColors.whiteD),
      },
    },

    '&:active': {
      span: {
        backgroundColor: darken(0.05, ShColors.whiteD),
      },
    },
    width: '100%',
    [ShMediaQueries.ShMobileOnly]: {
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      fontSize: 14,
      display: 'flex',
    },
  } as const);
