/** @jsx jsx */
import { jsx } from '@emotion/core';

import { ShColors, ShFonts, ShFontWeights } from '@shoootin/design-tokens';

export const ShInputRequiredMark = ({
  type = 'disc',
}: {
  type?: 'disc' | 'star';
}) => (
  <span
    css={{
      fontFamily: ShFonts.secondary,
      fontWeight: ShFontWeights.s,
      color: ShColors.error,
    }}
  >
    {type === 'star' ? '*' : '\u25CF'}
  </span>
);
