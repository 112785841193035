/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ReactNode } from 'react';
import { ThemeProvider, Container, Row, Col } from 'react-bootstrap';

// Shoootin grid system is a bootstrap 4 grid of 60 columns
// CSS files are provided already compiled (run "yarn grid:css to recompile it"
// It's the responsability of the consuming app to load that css

// Provider permits to apply globally the sh- class prefixing
// Prefixing the css with sh- is required because the backend still use old bootstrap3 global grid css
// We need the prefix to avoid conflicts and migrate progressively to the new grid system
export const ShGridProvider = ({ children }: { children: ReactNode }) => (
  <ThemeProvider
    prefixes={{
      container: 'sh-container',
      row: 'sh-row',
      col: 'sh-col',
    }}
  >
    {children}
  </ThemeProvider>
);

// See https://github.com/react-bootstrap/react-bootstrap/blob/master/src/Container.js
export const ShContainer = Container;

// See https://github.com/react-bootstrap/react-bootstrap/blob/master/src/Row.js
export const ShRow = Row;

// See https://github.com/react-bootstrap/react-bootstrap/blob/master/src/Col.js
export const ShCol = Col;
