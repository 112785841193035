/** @jsx jsx */
import { jsx } from '@emotion/core';

import { ShOverlay } from '../../overlay/ShOverlay';
import { ShSpinner, ShSpinnerProps } from '../ShSpinner/ShSpinner';
import { ShColor } from '@shoootin/design-tokens';

export type ShSpinnerOverlayProps = ShSpinnerProps & {
  className?: string;
  visible: boolean;
  popDuration?: number;
};

export const ShSpinnerOverlay = ({
  className,
  visible,
  popDuration,
  size,
  color,
}: ShSpinnerOverlayProps) => (
  <ShOverlay visible={visible} popDuration={popDuration}>
    {() => (
      <div
        className={className}
        css={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ShSpinner size={size} color={color} />
      </div>
    )}
  </ShOverlay>
);
