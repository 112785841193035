/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ShShootingFieldDTO } from '@shoootin/api';
import React, { ReactNode } from 'react';
import { ShInputText } from '../../designSystem/primitives/input/ShInputText/ShInputText';
import { ShInputSelect } from '../../designSystem/primitives/input/ShInputSelect/ShInputSelect';
import { ShInputError } from '../../designSystem/primitives/input/utils/ShInputError/ShInputError';
import { ShCheckboxField } from '../../components/input/ShCheckboxField/ShCheckboxField';
import { ShFonts } from '@shoootin/design-tokens';

const ShootingFieldWrapper = ({
  field,
  children,
}: {
  field: ShShootingFieldDTO;
  children: ReactNode;
}) => {
  return (
    <div css={{ paddingTop: '1.5rem' }}>
      <div
        className="title"
        css={{ marginBottom: '0.3em', fontFamily: ShFonts.secondary }}
      >
        {field.name}
      </div>
      {children}
    </div>
  );
};

const ShootingFieldSelect = ({
  field,
  value,
  onChange,
  error,
}: {
  field: ShShootingFieldDTO;
  value: string;
  onChange: (field: ShShootingFieldDTO, value: string | boolean) => void;
  error: string | undefined;
}) => {
  const options = field.selectValues.map((value: string) => ({
    value: value,
    label: value !== '' ? value : field.name,
  }));
  return (
    <ShootingFieldWrapper field={field}>
      {!!field.info && (
        <div css={{ marginBottom: '0.2em' }}>
          <small>{field.info}</small>
        </div>
      )}
      <ShInputSelect
        size={'l'}
        options={options}
        onChange={(option) => {
          onChange(field, option);
        }}
        value={value}
        placeholder={field.placeholder || field.name}
      />

      {error && <ShInputError>{error}</ShInputError>}
    </ShootingFieldWrapper>
  );
};

const ShootingFieldString = ({
  field,
  value,
  onChange,
  error,
}: {
  field: ShShootingFieldDTO;
  value: string;
  onChange: (field: ShShootingFieldDTO, value: string | boolean) => void;
  error: string | undefined;
}) => {
  return (
    <ShootingFieldWrapper field={field}>
      {!!field.info && (
        <div css={{ marginBottom: 5 }}>
          <small>{field.info}</small>
        </div>
      )}
      <ShInputText
        themeSize={'l'}
        placeholder={field.placeholder}
        value={value}
        required={field.mandatory}
        onChange={(e) => {
          onChange(field, e.target.value);
        }}
        error={error}
      />
    </ShootingFieldWrapper>
  );
};

const ShootingFieldBoolean = ({
  field,
  value,
  onChange,
  error,
}: {
  field: ShShootingFieldDTO;
  value: string;
  onChange: (field: ShShootingFieldDTO, value: string | boolean) => void;
  error: string | undefined;
}) => {
  return (
    <ShootingFieldWrapper field={field}>
      <ShCheckboxField
        value={!!value}
        label={field.info}
        onChange={(value) => onChange(field, value)}
      />
      {error && <ShInputError>{error}</ShInputError>}
    </ShootingFieldWrapper>
  );
};

export const ShShootingField = ({
  field,
  value,
  onChange,
  validationError,
}: {
  field: ShShootingFieldDTO;
  value: string;
  onChange: (field: ShShootingFieldDTO, value: boolean | string) => void;
  validationError?: string;
}) => {
  switch (field.fieldType) {
    case 'String':
      return (
        <ShootingFieldString
          field={field}
          value={value}
          onChange={onChange}
          error={validationError}
        />
      );
    case 'Select':
      return (
        <ShootingFieldSelect
          field={field}
          value={value}
          onChange={onChange}
          error={validationError}
        />
      );
    case 'Boolean':
      return (
        <ShootingFieldBoolean
          field={field}
          value={value}
          onChange={onChange}
          error={validationError}
        />
      );
  }
  return null;
};
