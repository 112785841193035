/** @jsx jsx */
import { jsx } from '@emotion/core';

import React, { ReactNode } from 'react';
import { ShInputError, ShInputText } from '@shoootin/components-web';

import { Formik, useFormikContext } from 'formik';
import { ShCol, ShRow } from '../../grid/ShGrid';
import {
  parseShFormikFieldObjectErrors,
  ShFormikTextField,
  useShFormikField,
} from '../../components/formik/ShFormikComponents';
import { ShFormikCountrySelectField } from '../../components/formik/ShFormikCountrySelectField';
import { ShHr } from '../../designSystem/primitives/ShHtmlElements/ShHtmlElements';
import { ShApiUtils, ShBillingEntityDTO } from '@shoootin/api';

import { produce } from 'immer';
import { pullAt } from 'lodash';
import { Optional } from 'utility-types';
import { ShBillingEntityFormInitialValues } from './ShBillingEntityForms';
import { ShText, useShTranslate } from '@shoootin/translations';
import {
  ClientBillingEntityTargets,
  getClientBillingEntityMessage,
} from './ShClientBillingEntityFormMessages';
import { ShAddressAutocompleteFormik } from '../addressAutocomplete/ShAddressAutocomplete';
import { ShButtonAsync } from '../../designSystem/primitives/button/ShButton/ShButtonAsync';

export type ShClientBillingEntityFormValues = Omit<
  Optional<ShBillingEntityDTO, 'id'>,
  'complete' | 'companyValue' | 'users'
>;

const EmailLineItem = ({
  className,
  first,
  value,
  onChange,
  error,
  action,
}: {
  className?: string;
  first: boolean;
  value: string;
  onChange: (value: string) => void;
  error?: string;
  action: ReactNode;
}) => {
  return (
    <div className={className}>
      <ShRow css={{ display: 'flex', alignItems: 'center' }}>
        <ShCol xs={45}>
          <div style={{ width: '100%', marginBottom: 10 }}>
            <ShInputText
              value={value}
              required={first}
              placeholder="john@doe.com"
              onChange={(e) => onChange(e.target.value)}
            />
          </div>
        </ShCol>
        <ShCol xs={15} style={{ textAlign: 'right' }}>
          {action}
        </ShCol>
      </ShRow>
      {error && <ShInputError>{error}</ShInputError>}
    </div>
  );
};

const EmailFields = () => {
  const [field, meta, helpers] = useShFormikField<
    ShClientBillingEntityFormValues,
    string[]
  >('emails');

  const updateEmails = (updater: (emailsDraft: string[]) => void) => {
    const newElements = produce(field.value, (emailsDraft) => {
      updater(emailsDraft);
    });
    helpers.setValue(newElements);
  };

  const { arrayError, arrayItemErrors } = parseShFormikFieldObjectErrors(meta);

  const handleAdd = () => {
    helpers.setValue(field.value.concat(''));
  };

  const handleRemove = (i: number) => {
    const array = [...field.value];
    pullAt(array, [i]);
    helpers.setValue(array);
  };

  return (
    <React.Fragment>
      {field.value.map((email, i) => {
        const first = i === 0;
        return (
          <EmailLineItem
            key={i}
            css={{ marginTop: first ? 0 : 5 }}
            first={first}
            value={email}
            onChange={(newValue) =>
              updateEmails((draft) => {
                draft[i] = newValue;
              })
            }
            error={arrayItemErrors[i]}
            action={
              first ? (
                <a onClick={() => handleAdd()}>
                  <ShText<ClientBillingEntityTargets>
                    message={'common_actions_add'}
                  />
                </a>
              ) : (
                <a onClick={() => handleRemove(i)}>
                  <ShText<ClientBillingEntityTargets>
                    message={'common_actions_delete'}
                  />
                </a>
              )
            }
          />
        );
      })}

      {arrayError && <ShInputError>{arrayError}</ShInputError>}
    </React.Fragment>
  );
};

const BillingEntityFormContent = ({
  onBack,
  onDeleteClick,
}: {
  onBack: () => void;
  onDeleteClick?: () => void;
}) => {
  const t = useShTranslate();
  const form = useFormikContext<ShClientBillingEntityFormValues>();
  const countryCode = form.values.countryCode;
  return (
    <div>
      <ShHr mini />
      <ShRow>
        <ShCol xs={60}>
          <ShFormikTextField<ShClientBillingEntityFormValues>
            fieldName={'name'}
            placeholder={t(
              getClientBillingEntityMessage(
                'client_billingEntity_namePlaceholder',
              ),
            )}
          />
        </ShCol>
      </ShRow>

      <ShRow css={{ marginTop: 10 }}>
        <ShCol xs={30}>
          <ShFormikTextField<ShClientBillingEntityFormValues>
            fieldName={'company'}
            required={true}
            placeholder={t(
              getClientBillingEntityMessage(
                'client_billingEntity_companyPlaceholder',
              ),
            )}
          />
        </ShCol>
        <ShCol xs={30}>
          <ShFormikTextField<ShClientBillingEntityFormValues>
            fieldName={'department'}
            placeholder={t(
              getClientBillingEntityMessage(
                'client_billingEntity_departmentPlaceholder',
              ),
            )}
          />
        </ShCol>
      </ShRow>
      <ShRow css={{ marginTop: 10 }}>
        <ShCol xs={20}>
          <div>
            <ShFormikCountrySelectField<ShClientBillingEntityFormValues>
              fieldName="countryCode"
              placeholder={t(
                getClientBillingEntityMessage(
                  'client_billingEntity_countryCodePlaceholder',
                ),
              )}
            />
          </div>
        </ShCol>
        <ShCol xs={40}>
          <ShAddressAutocompleteFormik<ShClientBillingEntityFormValues>
            country={countryCode}
            fieldName={'address'}
            required={true}
            placeholder={t(
              getClientBillingEntityMessage(
                'client_billingEntity_addressPlaceholder',
              ),
            )}
          />
        </ShCol>
      </ShRow>
      <ShRow css={{ marginTop: 10 }}>
        {countryCode === 'FR' && (
          <ShCol xs={30}>
            <ShFormikTextField<ShClientBillingEntityFormValues>
              fieldName={'companyId'}
              placeholder={t(
                getClientBillingEntityMessage(
                  'client_billingEntity_companyIdPlaceholder',
                ),
              )}
            />
          </ShCol>
        )}
        {countryCode !== 'FR' && (
          <ShCol xs={30}>
            <ShFormikTextField<ShClientBillingEntityFormValues>
              fieldName={'taxNumber'}
              placeholder={t(
                getClientBillingEntityMessage(
                  'client_billingEntity_taxNumberPlaceholder',
                ),
              )}
            />
          </ShCol>
        )}
      </ShRow>
      <div css={{ marginTop: 10 }}>
        <EmailFields />
      </div>
      <ShRow css={{ marginTop: 10 }}>
        {/*<ShCol xs={60}>
          <ShFormikTextAreaField<ShClientBillingEntityFormValues>
            fieldName={'extraInfos'}
            label={'Extra Infos'}
            rows={2}
          />
        </ShCol>*/}
        <ShCol xs={60}>
          <ShHr mini />
        </ShCol>
        <ShCol xs={60}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {onDeleteClick && (
              <div>
                <ShButtonAsync variant="red" onClick={onDeleteClick}>
                  <ShText<ClientBillingEntityTargets>
                    message={'common_actions_delete'}
                  />
                </ShButtonAsync>
              </div>
            )}
            <div>
              <ShButtonAsync variant="light" onClick={onBack}>
                <ShText<ClientBillingEntityTargets>
                  message={'common_actions_cancel'}
                />
              </ShButtonAsync>
            </div>
            <div>
              <ShButtonAsync onClick={form.submitForm}>
                <ShText<ClientBillingEntityTargets>
                  message={'common_actions_save'}
                />
              </ShButtonAsync>
            </div>
          </div>
        </ShCol>
      </ShRow>
    </div>
  );
};

export type ShClientBillingEntityFormProps = {
  initialValues?: ShClientBillingEntityFormValues;
  onSubmit: (values: ShBillingEntityDTO) => Promise<void>;
  onBack: () => void;
  onDeleteClick?: () => void;
};

export const ShClientBillingEntityForm = ({
  initialValues = ShBillingEntityFormInitialValues,
  onSubmit,
  onBack,
  onDeleteClick,
}: ShClientBillingEntityFormProps) => {
  return (
    <Formik<ShClientBillingEntityFormValues>
      initialValues={initialValues}
      //validationSchema={BillingEntityFormSchema}
      //validateOnMount={!!initialValues}
      onSubmit={async (values, { setErrors }) => {
        try {
          // cast is safe, because values have been validated locally
          await onSubmit(values as ShBillingEntityDTO);
          onBack();
        } catch (e) {
          setErrors(ShApiUtils.getApiFormErrors(e));
          throw e;
        }
      }}
    >
      <BillingEntityFormContent onBack={onBack} onDeleteClick={onDeleteClick} />
    </Formik>
  );
};
