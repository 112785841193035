/** @jsx jsx */
import { InterpolationWithTheme, jsx } from '@emotion/core';

import { useAsync, useAsyncCallback } from 'react-async-hook';
import React, { useState } from 'react';
import {
  ShSpinner,
  ShSpinnerOverlay,
  ShTooltip,
  StarSvg,
} from '@shoootin/components-web';
import { ShMoment } from '@shoootin/utils';
import {
  ShShootingSlotAdminDetailsPhotographerDTO,
  ShShootingSlotAdminPhotographerPreviewDTO,
  ShShootingSlotDTO,
} from '@shoootin/api';
import { ShColors, ShFonts, ShMediaQueries } from '@shoootin/design-tokens';

const shootingSlotBtnStyle = (active: boolean): InterpolationWithTheme<any> => {
  return {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    margin: '0.625rem 0.41666667rem',
    paddingRight: '1rem',
    paddingLeft: '1rem',
    fontFamily: ShFonts.primary,
    boxShadow: '0 0.125rem 0.5rem 0 rgba(0, 0, 0, 0.1)',
    fontSize: '87.5%',
    minWidth: 95,
    backgroundColor: ShColors.white,
    color: ShColors.black,
    '&:hover, &:focus': {
      backgroundColor: ShColors.base,
      color: `${ShColors.whiteD} !important`,
    },
    ...(active && {
      backgroundColor: ShColors.base,
      color: ShColors.whiteD,
    }),
    [ShMediaQueries.ShMobileOnly]: {
      paddingRight: '1.25rem',
      paddingLeft: '1.25rem',
    },
  };
};

const shootingSlotBtnStyleAdmin = (
  active: boolean,
): InterpolationWithTheme<any> => {
  return {
    // marginLeft: '0.41666667rem',
    width: '100%',
    padding: 8,
    fontFamily: ShFonts.primary,
    boxShadow: '0 0.125rem 0.5rem 0 rgba(0, 0, 0, 0.1)',
    fontSize: '87.5%',
    backgroundColor:
      /*hasPreferredPhotographer ? ShColors.drone :*/ ShColors.white,
    color: ShColors.black,
    '&:hover, &:focus': {
      backgroundColor: ShColors.base,
      color: `${ShColors.whiteD} !important`,
    },

    textAlign: 'left',
    '&:hover': {
      backgroundColor: ShColors.whiteD,
      color: `${ShColors.base} !important`,
    },
    ...(active && {
      backgroundColor: ShColors.whiteD,
      color: ShColors.base,
    }),

    [ShMediaQueries.ShMobileOnly]: {
      paddingRight: '1.25rem',
      paddingLeft: '1.25rem',
    },
  };
};

const ShShootingSlotBasic = ({
  loading,
  timeZone,
  shootingSlot,
  onSlotSelected,
  active,
}: {
  loading: boolean;
  timeZone: string;
  shootingSlot: ShShootingSlotDTO;
  onSlotSelected: (
    photographer?: ShShootingSlotAdminDetailsPhotographerDTO,
  ) => void;
  active: boolean;
}) => {
  return (
    <React.Fragment>
      <button
        className={'btn'}
        onClick={() => onSlotSelected()}
        css={shootingSlotBtnStyle(active)}
      >
        <span css={{ display: 'flex', alignItems: 'center' }}>
          <span css={{ width: 57 }}>
            {ShMoment(shootingSlot.start).tz(timeZone).format('LT')}
          </span>
          {shootingSlot.hasPreferredPhotographer && (
            <span css={{ position: 'absolute', top: 2, right: 2 }}>
              <StarSvg width={14} color={active ? 'white' : 'black'} />
            </span>
          )}
        </span>
        <ShSpinnerOverlay visible={loading} color={'white'} size={'xs'} />
      </button>
    </React.Fragment>
  );
};

const ShootingSlotAdminPreview = ({
  shootingSlot,
  timeZone,
  showPreviewPhotographers,
}: {
  shootingSlot: ShShootingSlotDTO;
  timeZone: string;
  showPreviewPhotographers: boolean;
}) => {
  return (
    <div>
      <div css={{ display: 'flex', justifyContent: 'space-between' }}>
        <div css={{ display: 'flex', alignItems: 'center' }}>
          {ShMoment(shootingSlot.start).tz(timeZone).format('LT')} -{' '}
          {ShMoment(shootingSlot.end).tz(timeZone).format('LT')}
        </div>
        <div css={{ display: 'flex', alignItems: 'center' }}>
          {shootingSlot.hasPreferredPhotographer && (
            <span css={{ marginRight: 3, marginTop: 6 }}>
              <StarSvg width={16} color={'black'} />
            </span>
          )}
          <span
            css={{
              display: 'flex',
              backgroundColor: ShColors.base,
              color: ShColors.white,
              width: 20,
              height: 20,
              borderRadius: '50%',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: 12,
            }}
          >
            {shootingSlot.admin!.nbOfAvailability}
          </span>
          {/*{shootingSlot.admin!.nbOfAvailability} photographer
          {shootingSlot.admin!.nbOfAvailability > 1 && 's'}*/}
        </div>
      </div>
      {showPreviewPhotographers && (
        <div css={{ marginTop: 10, flexWrap: 'wrap', display: 'flex' }}>
          {shootingSlot.admin!.photographerPreviews.map(
            (photographer: ShShootingSlotAdminPhotographerPreviewDTO) => (
              <img
                data-for="photographer"
                data-tip={photographer.fullName}
                key={photographer.id}
                css={{
                  height: 20,
                  borderRadius: '50%',
                  marginRight: 5,
                }}
                src={photographer.photoUrl}
              />
            ),
          )}
          <ShTooltip id="photographer" place="bottom" />
        </div>
      )}
    </div>
  );
};

const ShootingSlotAdminDetails = ({
  photographers,
  onPhotographerSelected,
  active,
  photographerId,
}: {
  photographers: ShShootingSlotAdminDetailsPhotographerDTO[];
  onPhotographerSelected: (
    photographer: ShShootingSlotAdminDetailsPhotographerDTO,
  ) => void;
  active: boolean;
  photographerId?: string;
}) => {
  return (
    <div css={{}} onClick={(e) => e.stopPropagation()}>
      {photographers.map(
        (photographer: ShShootingSlotAdminDetailsPhotographerDTO) => (
          <ShootingSlotAdminPhotographer
            key={photographer.id}
            photographer={photographer}
            active={active && photographer.id === photographerId}
            onClick={() => onPhotographerSelected(photographer)}
          />
        ),
      )}
    </div>
  );
};

const ShootingSlotAdminPhotographer = ({
  photographer,
  onClick,
  active,
}: {
  photographer: ShShootingSlotAdminDetailsPhotographerDTO;
  onClick: () => void;
  active: boolean;
}) => {
  const onClickAsync = useAsyncCallback(onClick);

  return (
    <div
      css={{
        position: 'relative',
        borderBottom: `1px solid ${ShColors.pattern}`,
        paddingBottom: 5,
        marginBottom: 5,
        '&:focus,&:hover': {
          backgroundColor: ShColors.base,
          color: ShColors.whiteD,
        },
        ...(active && {
          backgroundColor: ShColors.base,
          color: ShColors.whiteD,
        }),
      }}
    >
      <div
        onClick={onClickAsync.execute}
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 8,
          flexWrap: 'wrap',
        }}
      >
        <div
          css={{
            flexBasis: 50,
          }}
        >
          <img
            css={{
              height: 40,
              width: 40,
              borderRadius: '50%',
              marginRight: 5,
            }}
            src={photographer.photoUrl}
          />
        </div>
        <div css={{ textAlign: 'right', flexBasis: 'calc(100% - 50px)' }}>
          {photographer.good && (
            <span css={{ marginRight: 3, marginTop: 6 }}>
              <StarSvg width={14} color={'black'} />
            </span>
          )}
          {photographer.fullName}
          {' - '}
          <small>{photographer.transportation}</small>
          <br />
          {photographer.autoAcceptation ? 'Auto-accept' : 'Proposal'}
          <br />
          {photographer.inZone ? 'In zone' : 'Not in zone'}{' '}
          <small>
            {photographer.distanceFieldFormat}
            km
          </small>
        </div>
        <div css={{ marginTop: 10 }}>
          {photographer.score !== 0 && (
            <React.Fragment>
              Score : {photographer.scoreFormat} <br />
            </React.Fragment>
          )}
          <React.Fragment>
            Client rating : {photographer.avgClientRating} <br />
            Review rating : {photographer.avgReviewRating} <br />
          </React.Fragment>
          {photographer.nbOfShootingsOfDay !== 0 && (
            <React.Fragment>
              {photographer.nbOfShootingsOfDay} Shootings this day (
              <small>{photographer.timeToClosestShooting} to the closest</small>
              )<br />
            </React.Fragment>
          )}
        </div>
      </div>
      <small css={{ padding: 5 }}>
        <a target="_blank" href={photographer.adminLink} rel="noopener">
          Go to BO
        </a>{' '}
        {/*TOimprove open in popup when backoffice is react ready*/}
        <a target="_blank" href={photographer.intercomLink} rel="noopener">
          Intercom
        </a>
      </small>
      <ShSpinnerOverlay
        visible={onClickAsync.loading}
        /*css={{ backgroundColor: 'white' }}*/
        size={'s'}
      />
    </div>
  );
};

const ShShootingSlotAdmin = ({
  loading,
  timeZone,
  shootingSlot,
  onSlotSelected,
  active,
  getAdminSlotDetails,
  photographerId,
}: {
  loading: boolean;
  timeZone: string;
  shootingSlot: ShShootingSlotDTO;
  onSlotSelected: (
    photographer?: ShShootingSlotAdminDetailsPhotographerDTO,
  ) => void;
  active: boolean;
  getAdminSlotDetails?: (shootingSlot: ShShootingSlotDTO) => any;
  photographerId?: string;
}) => {
  const [showAdminDetails, setShowAdminDetails] = useState(false);

  const slotDetailsAsync = useAsync(async () => {
    if (showAdminDetails && getAdminSlotDetails) {
      return getAdminSlotDetails(shootingSlot);
    }
  }, [shootingSlot, showAdminDetails]);

  //removed AppButton and didn't use ShButton because of legacy modifiers
  //so i used basic <button/> with css styles
  return (
    <React.Fragment>
      <div
        css={{
          width: '100%',
          position: 'relative',
          marginBottom: 10,
          marginLeft: 5,
        }}
      >
        <div
          onClick={() => setShowAdminDetails(!showAdminDetails)}
          css={shootingSlotBtnStyleAdmin(active)}
        >
          <ShootingSlotAdminPreview
            shootingSlot={shootingSlot}
            timeZone={timeZone}
            showPreviewPhotographers={!showAdminDetails}
          />
        </div>
        {showAdminDetails && (
          <div
            css={{
              // marginLeft: '0.41666667rem',
              width: '100%',
              color: ShColors.black,
              backgroundColor: ShColors.whiteD,
              fontSize: 14,
            }}
          >
            {slotDetailsAsync.loading && <ShSpinner size={'xs'} />}
            {slotDetailsAsync.result && (
              <ShootingSlotAdminDetails
                photographers={slotDetailsAsync.result.photographers}
                onPhotographerSelected={onSlotSelected}
                active={active}
                photographerId={photographerId}
              />
            )}
          </div>
        )}
        <ShSpinnerOverlay visible={loading} color={'white'} size={'xs'} />
      </div>
    </React.Fragment>
  );
};

export const ShShootingSlot = ({
  loading,
  timeZone,
  shootingSlot,
  onSlotSelected,
  active,
  getAdminSlotDetails,
  photographerId,
}: {
  loading: boolean;
  timeZone: string;
  shootingSlot: ShShootingSlotDTO;
  onSlotSelected: (
    photographer?: ShShootingSlotAdminDetailsPhotographerDTO,
  ) => void;
  active: boolean;
  getAdminSlotDetails?: (shootingSlot: ShShootingSlotDTO) => any;
  photographerId?: string;
}) => {
  const isAdmin = !!shootingSlot.admin;

  if (!isAdmin) {
    return (
      <ShShootingSlotBasic
        loading={loading}
        timeZone={timeZone}
        shootingSlot={shootingSlot}
        onSlotSelected={onSlotSelected}
        active={active}
      />
    );
  } else {
    return (
      <ShShootingSlotAdmin
        loading={loading}
        timeZone={timeZone}
        shootingSlot={shootingSlot}
        onSlotSelected={onSlotSelected}
        active={active}
        getAdminSlotDetails={getAdminSlotDetails}
        photographerId={photographerId}
      />
    );
  }
};
