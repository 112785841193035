/** @jsx jsx */
import React, { useMemo } from 'react';

import { ShColors, ShFonts } from '@shoootin/design-tokens';

import { ShBillingEntityDTO } from '@shoootin/api';
import {
  ShInputSelect,
  ShInputSelectComponents,
} from '../../designSystem/primitives/input/ShInputSelect/ShInputSelect';
import { ShBackendAssets } from '@shoootin/config';
import { jsx } from '@emotion/core';
import { ShInputSize } from '../../designSystem/primitives/input/ShInputTheme';

type BillingEntitySelectOption = {
  value: string;
  label: string;
  data: ShBillingEntityDTO;
};

const createEntityOption = (
  entity: ShBillingEntityDTO,
): BillingEntitySelectOption => {
  return {
    value: entity.id,
    label: `${entity.name} ${entity.company} ${entity.department} ${entity.address} ${entity.countryCode}`,
    data: entity,
  };
};

const BillingEntityFieldSelectComponents: ShInputSelectComponents<
  string | undefined
> = {
  Option: (props) => {
    // console.debug('Option props', props);
    const { data, innerRef, innerProps, isSelected, isFocused } = props;

    const { data: entity } = data as BillingEntitySelectOption;

    if (!entity) return null;
    return (
      <div
        ref={innerRef}
        onClick={innerProps.onClick}
        css={{
          padding: '2px 8px',
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: isSelected ? ShColors.whiteD : ShColors.white,
          fontFamily:
            isSelected || isFocused ? ShFonts.secondary : ShFonts.primary,
          ':hover': {
            backgroundColor: ShColors.whiteD,
          },
        }}
      >
        <div
          css={{
            flex: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={ShBackendAssets.countryFlag(entity.countryCode)}
            css={{ width: 25, objectFit: 'contain' }}
          />
          {/*<img
            src={getCountryFlag(entity.countryCode)}
            css={{ width: 25, objectFit: 'contain' }}
          />*/}
        </div>
        <span
          css={{
            flex: 1,
            display: 'block',
            position: 'relative',
            cursor: 'pointer',
            padding: 15,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {entity.name && entity.name}
          {entity.name && <br />}
          {entity.company}{' '}
          <small>{entity.department && ` - ${entity.department}`}</small>
          <br />
          <small>{entity.address}</small>
        </span>
      </div>
    );
  },

  SingleValue: (props) => {
    const { children, data, innerProps, selectProps } = props;
    // console.debug('SingleValue props', props);
    const { value, data: entity } = data as BillingEntitySelectOption;

    return selectProps.menuIsOpen || !value || !entity ? (
      <div
        {...innerProps}
        css={{
          display: 'flex',
          flexDirection: 'row',
          maxWidth: 'calc(100% - 8px)',
          overflow: 'hidden',
          position: 'absolute',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {selectProps.placeholder}
      </div>
    ) : (
      <div
        {...innerProps}
        css={{
          display: 'flex',
          flexDirection: 'row',
          maxWidth: 'calc(100% - 8px)',
          overflow: 'hidden',
          position: 'absolute',
        }}
      >
        <div
          css={{
            flex: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={ShBackendAssets.countryFlag(entity.countryCode)}
            css={{ width: 25, objectFit: 'contain' }}
          />
          {/*<img
            src={getCountryFlag(entity.countryCode)}
            css={{ width: 25, objectFit: 'contain' }}
          />*/}
        </div>
        <span
          css={{
            flex: 1,
            display: 'block',
            position: 'relative',
            cursor: 'pointer',
            padding: 15,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {entity.name} {entity.company}{' '}
          {entity.department && ` - ${entity.department}`}
        </span>
      </div>
    );
  },
};

export type ShBillingEntitySelectProps = {
  className?: string;
  currentEntityId: string | undefined;
  entities: ShBillingEntityDTO[];
  onChange: (billingEntity: ShBillingEntityDTO) => void;
  size?: ShInputSize;
  placeholder?: string;
};

export const ShBillingEntitySelect = ({
  className,
  currentEntityId,
  entities,
  onChange,
  size = 'l',
  placeholder = 'Select your billing Entity',
}: ShBillingEntitySelectProps) => {
  const options = useMemo(() => entities.map(createEntityOption), [entities]);
  return (
    <ShInputSelect
      size={size}
      className={className}
      value={currentEntityId}
      onChange={(entityId) => {
        const entity = entities.find((entity) => entity.id === entityId);
        entity && onChange(entity);
      }}
      options={options}
      placeholder={placeholder}
      components={BillingEntityFieldSelectComponents}
    />
  );
};
