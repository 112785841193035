import { useState, useEffect } from 'react';

// useful if you need to store a local copy of an existing input state

// if the source value change,
// the copy should reset to the source value to stay in sync

// mostly useful for debouncing text inputs, as we have to store input state in both places:
// - the query string (debounced)
// - locally (immediate)
export const useLocalInputState = <
  T extends string | number | boolean | undefined = string
>(
  initialValue: T,
) => {
  const [inputValue, setInputValue] = useState(initialValue);

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  return [inputValue, setInputValue] as const;
};
