/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import { ShColors, ShFonts } from '@shoootin/design-tokens';

import { ShPartialVideoReviewUser, ShVideoComment } from '@shoootin/api';
import { ShMoment } from '@shoootin/utils';
import { ShButton } from '../../designSystem/primitives/button/ShButton/ShButton';
import {
  getTooltipPosition,
  secondsToDuration,
  truncateString,
  TIMELINE_TOOLTIP_WIDTH,
} from './ShVideoReviewUtils';

import TickSvg from '../../components/svg/picto/TickSvg';
import { SvgFirstFrame } from './ShVideoIcons';
import { ShVideoReviewUser } from '@shoootin/config';
import { PROGRESSBAR_HEIGHT } from './ShVideoReviewPlayerProgressBar';

export const CommentCompleted = ({
  comment,
  toggleComplete,
}: {
  comment: ShVideoComment;
  toggleComplete: (id: string) => void;
}) => {
  const [hovered, setHovered] = useState<boolean>(false);
  const dataTip = !comment.completed
    ? 'Mark as complete'
    : `Completed by  ${comment.completedBy} ${ShMoment(
        comment.completedAt,
      ).fromNow()}`;

  return (
    <div>
      <a
        onClick={() => {
          toggleComplete(comment.id);
          if (!comment.completed) {
            setHovered(false);
          }
        }}
        css={{
          cursor: 'pointer',
          display: 'block',
          height: 18,
          width: 18,
          borderRadius: '50%',
          border: comment.internal
            ? `1px solid ${ShColors.baseL}`
            : `1px solid ${ShColors.white}`,
          textAlign: 'center',
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        //onMouseOver={() => !hovered && setHovered(true)}
      >
        <span
          data-for="comments"
          data-tip={dataTip}
          css={{
            opacity: comment.completed ? 1 : hovered ? 1 : 0,
            transition: 'opacity 0.2s ease-in-out',
          }}
        >
          <TickSvg color={comment.internal ? 'baseL' : 'white'} width={13} />
        </span>
      </a>
    </div>
  );
};

export const CommentNameAndDate = ({
  userName,
  date,
  userType,
  color,
}: {
  userName: string;
  date: Date;
  userType: ShVideoReviewUser;
  color: string;
}) => {
  return (
    <div>
      {userType === 'editor' && (
        <span css={{ paddingRight: 5 }}>
          <SvgFirstFrame height={12} color={color} />
        </span>
      )}
      {userType === 'shoootin' && (
        <span css={{ paddingRight: 5 }}>
          <img
            src={'https://app.shoootin.com/assets/images/favicon_bo.png?v2'}
            width={15}
            css={{ verticalAlign: 'baseline' }}
          />
        </span>
      )}

      <span css={{ fontFamily: ShFonts.secondary, paddingRight: 10 }}>
        {userName}
      </span>
      <small>{ShMoment(date).fromNow()}</small>
    </div>
  );
};

export const HoveredComment = ({
  hovered,
  duration,
  seekTo,
  setCommentHovered,
  closeHover,
  width,
}: {
  hovered: ShVideoComment;
  duration: number;
  seekTo: (s: number) => void;
  setCommentHovered: (comment: ShVideoComment) => void;
  closeHover: () => void;
  width: number;
}) => {
  const color = hovered.internal ? ShColors.baseL : ShColors.white;
  return (
    <div
      style={{
        left: getTooltipPosition(
          width,
          hovered.currentTime ? hovered.currentTime / duration : undefined,
        ),
      }}
      css={{
        backgroundColor: ShColors.blackL,
        width: TIMELINE_TOOLTIP_WIDTH,
        position: 'absolute',
        bottom: PROGRESSBAR_HEIGHT - 1,
        fontSize: 13,
        maxHeight: 200,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        padding: 10,
        transition: 'all 0.4s ease-in-out',
        color,
      }}
    >
      <div>
        <CommentNameAndDate
          userName={hovered.userName}
          date={hovered.date}
          userType={hovered.userType}
          color={color}
        />
      </div>
      <div
        onClick={() => seekTo(hovered.currentTime ?? 0)}
        css={{
          cursor: 'pointer',
          marginBottom: 5,
          paddingBottom: 5,
          paddingTop: 5,
        }}
        onMouseEnter={() => setCommentHovered(hovered)}
        onMouseLeave={() => closeHover()}
      >
        {hovered.currentTime !== 0 && (
          <small
            css={{
              fontFamily: ShFonts.secondary,
              paddingRight: 10,
            }}
          >
            {secondsToDuration(hovered.currentTime)}
          </small>
        )}
        <span>{truncateString(hovered.comment, 100)}</span>
      </div>
    </div>
  );
};

export const CommentHeader = ({
  filterCompleted,
  toggleFilterCompleted,
  allCommentsNb,
  openCommentsNb,
}: {
  filterCompleted: boolean;
  toggleFilterCompleted: () => void;
  allCommentsNb: number;
  openCommentsNb: number;
}) => {
  return (
    <div
      css={{
        marginBottom: 10,
        backgroundColor: ShColors.blackLL,
        padding: 10,
        fontSize: 14,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div>
        <div>
          <b>{allCommentsNb}</b> Comment{allCommentsNb > 1 && 's'}
        </div>
        <div>
          <b>{openCommentsNb}</b> Open comment{openCommentsNb > 1 && 's'}
        </div>
      </div>

      <span
        data-for="comments"
        data-tip={filterCompleted ? 'Show all comments' : 'Show open comments'}
      >
        <ShButton size={'s'} variant={'glass'} onClick={toggleFilterCompleted}>
          {filterCompleted ? 'View all' : 'View open'}
        </ShButton>
      </span>
    </div>
  );
};
