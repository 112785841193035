/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ReactNode } from 'react';

import { ShTile } from '../ShTile/ShTile';
import { ShFonts, ShFontWeights } from '@shoootin/design-tokens';

export type ShTilesProps<T extends string> = {
  tiles: Readonly<T[]>;
  activeTiles: Readonly<T[]>;
  onTilePress: (tile: T) => void;
  renderTileLabel?: (tile: T) => string | ReactNode;
  renderTileIcon?: (tile: T) => ReactNode;
};

export const ShTiles = <T extends string = string>({
  tiles,
  activeTiles = [],
  onTilePress,
  renderTileLabel,
  renderTileIcon,
}: ShTilesProps<T>) => {
  return (
    <ul
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        margin: 0,
        padding: 0,
        fontFamily: ShFonts.secondary,
        fontWeight: ShFontWeights.m,
        listStyleType: 'none',
        textAlign: 'center',
      }}
    >
      {tiles.map((tile, index) => (
        <ShTile
          key={'tiles-' + index + '_' + tile}
          label={renderTileLabel ? renderTileLabel(tile) : tile}
          active={activeTiles && activeTiles.includes(tile)}
          icon={renderTileIcon ? renderTileIcon(tile) : undefined}
          onTilePress={() => onTilePress(tile)}
        />
      ))}
      <li
        css={{
          flexGrow: 1,
        }}
      />
    </ul>
  );
};
