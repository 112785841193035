import React from 'react';

const MastercardSvg = ({ width = 116.5 }: { width?: number }) => (
  <svg viewBox="0 0 116.5 72" width={width} height={width * 0.618}>
    <rect fill="#FF5F00" x={42.5} y={7.7} width={31.5} height={56.6} />
    <path
      fill="#EB001B"
      d="M44.5,36c0-11,5.1-21.5,13.8-28.3C42.6-4.6,20-1.9,7.7,13.8S-1.9,52,13.8,64.3c13.1,10.3,31.4,10.3,44.5,0C49.6,57.5,44.5,47.1,44.5,36z"
    />
    <g fill="#F79E1B">
      <path d="M116.5,36c0,19.9-16.1,36-36,36c-8.1,0-15.9-2.7-22.2-7.7C73.9,52,76.6,29.4,64.3,13.8c-1.8-2.3-3.8-4.3-6.1-6.1c15.6-12.3,38.3-9.6,50.6,6.1C113.8,20.1,116.5,27.9,116.5,36L116.5,36z" />
      <path d="M113.1,58.3v-1.2h0.5v-0.2h-1.2v0.2h0.5v1.2L113.1,58.3z M115.4,58.3v-1.4H115l-0.4,1l-0.4-1h-0.3v1.4h0.3v-1l0.4,0.9h0.3l0.4-0.9v1.1L115.4,58.3z" />
    </g>
  </svg>
);

export default MastercardSvg;
