/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import { ShInputText, ShStripeSvg } from '@shoootin/components-web';
import { ShText, useShTranslate } from '@shoootin/translations';
import { ShColors, ShMediaQueries } from '@shoootin/design-tokens';
import { ShOrderStepPayment } from './ShPaymentMethodTranslations';
import { ShInputError } from '../../designSystem/primitives/input/utils/ShInputError/ShInputError';
import { ShHelpModalButton } from '../help/ShHelpModalButton';
import { ShCol, ShRow } from '../../grid/ShGrid';

export const ShAchInput = ({
  name,
  setName,
  email,
  setEmail,
  paymentErrors,
  onChange,
  focused,
}: {
  name: string;
  setName: (name: string) => void;
  email: string;
  setEmail: (email: string) => void;
  paymentErrors?: string;
  onChange?: () => void;
  focused: boolean;
}) => {
  const t = useShTranslate();

  const [focus, setFocus] = React.useState<boolean>(focused);

  return (
    <div
      css={{
        padding: 25,
        borderRadius: 3,
        border: focus
          ? `2px solid ${ShColors.base}`
          : `2px solid ${ShColors.pattern}`,
      }}
    >
      <div>
        <div css={{ marginBottom: 20 }}>
          <ShText message={ShOrderStepPayment.paymentMethodNewACHDescription} />
        </div>
        <ShRow>
          <ShCol sm={27}>
            <div css={{ marginBottom: 10 }}>
              <label>Your name</label>
              <ShInputText
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setFocus(true);
                  onChange && onChange();
                }}
                onFocus={() => {
                  setFocus(true);
                  onChange && onChange();
                }}
                placeholder="John Doe"
              />
            </div>
          </ShCol>
          <ShCol sm={33}>
            <div css={{ marginBottom: 10 }}>
              <label>Your email</label>
              <ShInputText
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setFocus(true);
                  onChange && onChange();
                }}
                onFocus={() => {
                  setFocus(true);
                  onChange && onChange();
                }}
                placeholder="john@shoootin.com"
              />
            </div>
          </ShCol>
        </ShRow>
      </div>
      <div className="field-row space" css={{ marginTop: 20 }}>
        {paymentErrors && <ShInputError>{paymentErrors}</ShInputError>}
      </div>

      <div
        className="form-help"
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          marginTop: 25,
          paddingTop: 25,
          borderTop: `1px solid ${ShColors.whiteD}`,
        }}
      >
        <div css={{ [ShMediaQueries.ShMobileOnly]: { flexBasis: '90%' } }}>
          <span css={{ marginBottom: 10, display: 'block' }}>
            <ShStripeSvg width={119} />
          </span>
          <ShText message={ShOrderStepPayment.paymentMethodStripeInfos} />
        </div>
        <ShHelpModalButton modalName="paymentStripe" />
      </div>
    </div>
  );
};

export const ACHMandate = () => {
  return (
    <div
      css={{
        border: `1px solid ${ShColors.pattern}`,
        padding: 10,
      }}
    >
      By clicking [Validate], you authorize Shoootin to debit the bank account
      specified above for any amount owed for charges arising from your use of
      Shoootin’ services and/or purchase of products from Shoootin, pursuant to
      Shoootin’ website and terms, until this authorization is revoked. You may
      amend or cancel this authorization at any time by providing notice to
      Shoootin with 30 (thirty) days notice.
    </div>
  );
};
