/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useState } from 'react';
import {
  ShVideoCommentForm,
  ShVideoCommentReplyForm,
  ShVideoCommentResult,
  ShVideoReviewApiByUserType,
} from '@shoootin/api';
import { AsyncStateStatus, useAsync } from 'react-async-hook';
import { ShPartialVideoReviewUser } from '@shoootin/api';
import ReactTooltip from 'react-tooltip';
import { ShVideoReviewApi } from '@shoootin/api';
import { ShVideoComment } from '@shoootin/api';

const useVideoApi = (videoId: string, ShVideoReview: ShVideoReviewApi) => {
  const videoAsync = useAsync(
    () => {
      return ShVideoReview.getVideo(videoId);
    },
    [videoId],
    {
      // Do not erase previous results on purpose
      setLoading: (state) => ({ ...state, status: 'loading' }),
    },
  );

  const video = videoAsync.result?.video;

  const stuckVideo = async (message: string) => {
    if (ShVideoReview.stuckVideo) {
      await ShVideoReview.stuckVideo(video!.shootingId, message);
      await videoAsync.execute();
    }
  };

  const changesVideo = async (message: string) => {
    if (ShVideoReview.changesVideo) {
      await ShVideoReview.changesVideo(video!.shootingId, message);
      await videoAsync.execute();
    }
  };

  return {
    video,
    videoApi: {
      stuckVideo,
      changesVideo,
    },
  };
};

const useVideoActions = (
  player: React.MutableRefObject<any>,
  _duration?: number,
) => {
  const [isReady, setReady] = useState<boolean>(false);

  const [duration, setDuration] = useState<number>(_duration ?? 0);

  useEffect(() => {
    if (player.current) {
      setDuration(player.current!.getDuration());
    }
  }, [isReady]);

  const getCurrentTime = () => player.current!.getCurrentTime();
  const seekTo = (s: number) => player.current!.seekTo(s, 'seconds');

  const [videoPlaying, setVideoPlaying] = useState<boolean>(false);
  const playVideo = () => setVideoPlaying(true);
  const pauseVideo = () => setVideoPlaying(false);

  const [volume, setVolume] = useState<number>(0.5);
  const [muted, setMuted] = useState<boolean>(false);

  return {
    isReady,
    setReady,
    duration,
    getCurrentTime,
    seekTo,
    videoPlaying,
    playVideo,
    pauseVideo,
    volume,
    setVolume,
    muted,
    setMuted,
  };
};

const useCommentsApi = (videoId: string, ShVideoReview: ShVideoReviewApi) => {
  const commentsAsync = useAsync(
    () => {
      return ShVideoReview.getVideoComments(videoId);
    },
    [videoId],
    {
      // Do not erase previous results on purpose
      setLoading: (state) => ({ ...state, status: 'loading' }),
    },
  );

  // const emptyResult = {
  //   status: 'success' as AsyncStateStatus,
  //   loading: false,
  //   error: undefined,
  // };
  const loadingResult = {
    status: 'loading' as AsyncStateStatus,
    loading: true,
    error: undefined,
  };

  const setLoading = () => {
    commentsAsync.merge({ ...loadingResult });
  };
  const mergeResult = (result: ShVideoCommentResult) => {
    commentsAsync.merge({ result });
  };

  const addComment = async (comment: ShVideoCommentForm) => {
    setLoading();
    const result = await ShVideoReview.addComment(videoId, comment);
    mergeResult(result);
  };

  const toggleComplete = async (id: string) => {
    if (ShVideoReview.toggleComplete) {
      setLoading();
      const result = await ShVideoReview.toggleComplete(videoId, id);
      mergeResult(result);
    }
  };

  const toggleInternal = async (id: string) => {
    if (ShVideoReview.toggleInternal) {
      setLoading();
      const result = await ShVideoReview.toggleInternal(videoId, id);
      mergeResult(result);
    }
  };

  const deleteComment = async (id: string) => {
    setLoading();
    const result = await ShVideoReview.deleteComment(videoId, id);
    mergeResult(result);
  };

  const addReply = async (id: string, reply: ShVideoCommentReplyForm) => {
    setLoading();
    const result = await ShVideoReview.addReply(videoId, id, reply);
    mergeResult(result);
  };

  const deleteReply = async (id: string, replyId: string) => {
    setLoading();
    const result = await ShVideoReview.deleteReply(videoId, id, replyId);
    mergeResult(result);
  };

  const [filterCompleted, setFilterCompleted] = useState<boolean>(false);
  const toggleFilterCompleted = () => {
    setFilterCompleted((s) => !s);
    ReactTooltip.rebuild();
  };

  const comments: ShVideoComment[] = commentsAsync.result?.comments ?? [];
  const openComments = comments.filter(
    (comment: ShVideoComment) => !comment.completed,
  );
  const filteredComments = filterCompleted ? openComments : comments;

  const openCommentsNb = openComments.length;
  const allCommentsNb = comments.length;

  return {
    commentsApi: {
      addComment,
      deleteComment,
      addReply,
      deleteReply,
      toggleComplete,
      toggleInternal,
    },
    comments: {
      loading: commentsAsync.loading,
      comments: filteredComments,
      openCommentsNb,
      allCommentsNb,
    },
    filter: {
      filterCompleted,
      toggleFilterCompleted,
    },
  };
};

const useVideoPlayer = () => {
  const [videoPlayerState, setVideoPlayerState] = useState<any>({
    played: 0,
    playedSeconds: 0,
    loaded: 0,
    loadedSeconds: 0,
  });

  return {
    videoPlayerState,
    setVideoPlayerState,
  };
};

export const useVideoReviewState = (
  videoId: string,
  userType: ShPartialVideoReviewUser,
  player: React.MutableRefObject<any>,
) => {
  const ShVideoReview: ShVideoReviewApi =
    ShVideoReviewApiByUserType[userType as ShPartialVideoReviewUser];

  const { video, videoApi } = useVideoApi(videoId, ShVideoReview);

  if (video) document.title = video.title;

  const actions = useVideoActions(player, video?.duration);
  const videoPlayer = useVideoPlayer();

  const { commentsApi, comments, filter } = useCommentsApi(
    videoId,
    ShVideoReview,
  );

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [comments.comments]);

  return {
    video,
    videoPlayer,
    actions,
    comments,
    filter,
    commentsApi,
    videoApi,
  };
};

export type ShVideoReviewState = ReturnType<typeof useVideoReviewState>;
