/** @jsx jsx */
import { CSSObject, jsx } from '@emotion/core';

import React, { ReactNode, useRef, useLayoutEffect } from 'react';
import useOnClickOutside from 'use-onclickoutside';

// TODO temporary implementation for quick modal animation,
// we should rather use react-spring than a react-motion based solution
// @ts-ignore
import Mortal from 'react-mortal';
import CloseSvg from '../../../components/svg/picto/CloseSvg';
import { ShTitle } from '../ShTitle/ShTitle';
import { ShColor, ShColors, ShMediaQueries } from '@shoootin/design-tokens';
import { useShTheme } from '../../ShTheme';
import { createPortal } from 'react-dom';
import { Popper } from 'react-popper';

// shameless copy of https://usehooks.com/useLockBodyScroll/
function useLockBodyScroll() {
  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);
}

const BodyScrollLock = () => {
  useLockBodyScroll();
  return <React.Fragment />;
};

export const ShModalSizes = ['s', 'm', 'l', 'xl', 'xxl', 'lightbox'] as const;
export type ShModalSize = typeof ShModalSizes[number];
type ShModalSizeConfig = {
  modalCss: CSSObject;
};

export const ShModalSizeConfigs: {
  [key in ShModalSize]: ShModalSizeConfig;
} = {
  s: {
    modalCss: {
      [ShMediaQueries.ShDesktopsOnly]: {
        padding: 25,
        width: 450,
      },
    },
  },

  m: {
    modalCss: {
      [ShMediaQueries.ShDesktopsOnly]: {
        padding: 25,
        width: 600,
      },
      [ShMediaQueries.ShLargeDesktop]: {
        width: 700,
      },
    },
  },
  l: {
    modalCss: {
      [ShMediaQueries.ShDesktopsOnly]: {
        padding: 25,
        width: 800,
      },
      [ShMediaQueries.ShLargeDesktop]: {
        width: 900,
      },
    },
  },

  xl: {
    modalCss: {
      marginTop: '5%',
      marginBottom: '5%',
      [ShMediaQueries.ShDesktopsOnly]: {
        padding: 25,
        width: '95%',
      },
      [ShMediaQueries.ShLargeDesktop]: {
        width: '90%',
      },
    },
  },
  xxl: {
    modalCss: {
      marginTop: '2%',
      marginBottom: '2%',
      [ShMediaQueries.ShDesktopsOnly]: {
        padding: 25,
        width: '98%',
      },
      [ShMediaQueries.ShLargeDesktop]: {
        width: '95%',
      },
    },
  },
  lightbox: {
    modalCss: {
      padding: '10px !important',
      right: 0,
      left: 0,
      transform: 'unset',
      marginTop: '0%',
      marginBottom: '0%',
      minHeight: '100vh',
    },
  },
};
type ShModalStyleConfig = {
  modalCss: CSSObject;
  titleCss: {
    color: ShColor;
  };
};
export const ShModalStyles = ['default', 'transparent', 'dark'] as const;
export type ShModalStyle = typeof ShModalStyles[number];
export const ShModalStyleConfigs: {
  [key in ShModalStyle]: ShModalStyleConfig;
} = {
  default: {
    modalCss: {
      background: '#fff',
    },
    titleCss: { color: 'black' },
  },
  dark: {
    modalCss: {
      background: ShColors.blackL,
    },
    titleCss: { color: 'white' },
  },
  transparent: {
    modalCss: {
      background: 'transparent',
    },

    titleCss: { color: 'white' },
  },
};

export const useShModalSize = (sizeProp?: ShModalSize): ShModalSize => {
  const defaultSize = useShTheme().modal.size;
  return sizeProp ? sizeProp : defaultSize;
};

const ModalContent = ({
  isVisible,
  title,
  content,
  onClose,
  closeOnOverlayClick = true,
  size: sizeProp,
  style: styleProp = 'default',
}: {
  isVisible: boolean;
  title?: ReactNode;
  content: ReactNode;
  onClose: () => void;
  closeOnOverlayClick?: boolean;
  size?: ShModalSize;
  style?: ShModalStyle;
}) => {
  const modalRef = useRef<HTMLDivElement>(null as any);
  const dialogRef = useRef<HTMLDivElement>(null as any);
  const size = useShModalSize(sizeProp);
  const style = ShModalStyleConfigs[styleProp];

  useOnClickOutside(dialogRef, (event) => {
    if (!closeOnOverlayClick) {
      return;
    }

    const modal = modalRef.current!;

    // Only consider clicks on the overlay
    // (ie, clicking on a portal, like dropdown options, should not close the modal)
    const isModalOverlayClick = modal.contains(event.target as any);
    if (!isModalOverlayClick) {
      return;
    }

    // Some customers were really annoyed to see the modal closing when trying to click the scrollbar
    const isScrollbarClick =
      //@ts-expect-error: don't need to support touch events here
      //@ts-ignore
      event.offsetX && event.offsetX > modal.clientWidth;

    if (isScrollbarClick) {
      return;
    }

    onClose();
  });

  return (
    <React.Fragment>
      {isVisible && <BodyScrollLock />}
      <div
        onClick={(e) => e.stopPropagation()}
        ref={modalRef}
        css={{
          overflowY: 'auto',
          position: 'fixed',
          //zIndex: 50,
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: '#000000e6',
          opacity: 0,
          transition:
            'opacity .15s cubic-bezier(.4, 0, 1, 1) 20ms, visibility 0s linear .17s',
          visibility: 'hidden',
          pointerEvents: 'none',
          ...(isVisible && {
            visibility: 'visible',
            opacity: 1,
            transition:
              'opacity .2s cubic-bezier(0, 0, .2, 1), visibility 0s linear',
            pointerEvents: 'initial',
          }),
        }}
      >
        <div
          ref={dialogRef}
          css={{
            position: 'absolute',
            left: '50%',
            top: 0,
            // background: dark ? ShColors.blackL : '#fff',
            // background: style.modalCss.background,
            borderRadius: 3,
            boxSizing: 'border-box',
            opacity: 0,
            marginTop: '10%',
            marginBottom: '10%',
            transition:
              'opacity .15s cubic-bezier(.4, 0, 1, 1), transform .15s cubic-bezier(.4, 0, 1, 1)',
            transform: 'translate(-50%, 0%) scale(.95)',
            ...style.modalCss,
            ...(isVisible && {
              transform: 'translate(-50%, 0%) scale(1)',
              opacity: 1,
              transition:
                'opacity .15s cubic-bezier(0, 0, .2, 1) 20ms, transform .15s cubic-bezier(0, 0, .2, 1) 20ms',
            }),

            [ShMediaQueries.ShMobileOnly]: {
              padding: 12.5,
              width: 'calc(100% - 1rem)',
              marginTop: 100,
            },
            ...ShModalSizeConfigs[size].modalCss,
          }}
        >
          <div
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {title ? (
              <div css={{ flexGrow: 1 }}>
                <ShTitle size={4} color={style.titleCss.color!}>
                  <div
                    css={{
                      [ShMediaQueries.ShMobileOnly]: { fontSize: 16 },
                    }}
                  >
                    {title}
                  </div>
                </ShTitle>
              </div>
            ) : (
              <div />
            )}
            <button
              onClick={() => onClose()}
              css={{
                // position: 'absolute',
                // top: 25,
                // right: 25,
                padding: 0,
                background: 'transparent',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              <CloseSvg color={style.titleCss.color!} />
            </button>
          </div>
          <main>{content}</main>
        </div>
      </div>
    </React.Fragment>
  );
};

export type ShModalProps = {
  title?: ReactNode;
  content: ReactNode;
  onClose: () => void;
  size?: ShModalSize;
  style?: ShModalStyle;
};
export const ShModal = ({
  title,
  content,
  onClose,
  size,
  style: styleProp = 'default',
}: ShModalProps) => {
  // We render in a body portal, which generally solves naturally all z-index problems
  // The portals naturally stacks on top of each others
  // But it stays below z-index elements present on front (navbar, sliders etc)
  const portalTarget = document.body;

  return (
    <Popper placement={'bottom'}>
      {({ ref, style }) =>
        createPortal(
          <Mortal
            ref={ref}
            style={style}
            isOpened={true}
            onClose={() => onClose()}
            motionStyle={
              (/*spring: any, isVisible: boolean*/) => ({
                //TODO useless, because actually anim is handled by indigen CSS
                // opacity: spring(isVisible ? 1 : 0),
                // modalOffset: spring(isVisible ? 0 : -90, {
                //   stiffness: isVisible ? 300 : 200,
                //   damping: isVisible ? 15 : 30,
                // }),
              })
            }
          >
            {(isVisible: boolean) => {
              return (
                <ModalContent
                  isVisible={isVisible}
                  title={title}
                  content={content}
                  onClose={onClose}
                  size={size}
                  style={styleProp}
                />
              );
            }}
          </Mortal>,
          portalTarget,
        )
      }
    </Popper>
  );
};
