/** @jsx jsx */
import { jsx } from '@emotion/core';

import React, { ReactNode, useContext } from 'react';
import {
  ShButtonSize,
  ShButtonVariant,
} from './primitives/button/ShButton/ShButton';
import { merge } from 'lodash';
import { DeepPartial } from 'utility-types';
import { ShInputSize } from './primitives/input/ShInputTheme';
import { ShModalSize } from './primitives/modal/ShModal';

// This theming system permits to set default values for a whole component subtree
// For example, in front Sh we tend to use bigger elements by default
// But on admin app, we tend to use smaller elements by default
// In any case, user can decide to override these defaults by using a prop...

export type ShTheme = {
  button: {
    variant: ShButtonVariant;
    size: ShButtonSize;
  };
  input: {
    size: ShInputSize;
  };
  modal: {
    size: ShModalSize;
  };
};

const DefaultShTheme: ShTheme = {
  button: {
    variant: 'default',
    size: 'm',
  },
  input: {
    size: 'm',
  },
  modal: {
    size: 's',
  },
};

const ShThemeContext = React.createContext<ShTheme>(DefaultShTheme);

export type ShThemePartial = DeepPartial<ShTheme>;

export const ShThemeProvider = ({
  theme: partialTheme,
  children,
}: {
  theme: ShThemePartial;
  children: ReactNode;
}) => {
  const theme = merge<any, any, any>(
    {},
    DefaultShTheme,
    partialTheme,
  ) as ShTheme;

  return (
    <ShThemeContext.Provider value={theme}>{children}</ShThemeContext.Provider>
  );
};

export const useShTheme = (): ShTheme => {
  const theme = useContext(ShThemeContext);
  return theme;
};
