/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ShStyles } from '../../../designSystem/constants/ShStyles';
import {
  ShColors,
  ShEasings,
  ShFonts,
  ShFontWeights,
  ShRadius,
} from '@shoootin/design-tokens';

// TODO we should not use a "state" but just pass simple color props to the bubble
export const AppBubbleStates = ['previous', 'current', 'next', 'none'] as const;

// TODO we should not use a "state" but just pass simple color props to the bubble
export type AppBubbleState = typeof AppBubbleStates[number];

export const AppBubbleStateColor = {
  previous: [ShColors.black, ShColors.white],
  current: [ShColors.base, ShColors.white],
  next: [ShColors.whiteD, ShColors.link],
  none: [ShColors.transparent, ShColors.linkInvert],
};

export const AppBubbleSizes = {
  s: 30,
  m: 40,
  l: 50,
} as const;

export type AppBubbleSize = keyof typeof AppBubbleSizes;

export const AppBubbleSizeList = Object.keys(AppBubbleSizes) as AppBubbleSize[];

export type AppBubbleProps = {
  children?: string;
  size?: AppBubbleSize;
  state: AppBubbleState;
};

const AppBubble = ({
  children,
  size = 'm',
  state = 'next',
}: AppBubbleProps) => {
  return (
    <div
      css={{
        ...ShStyles.centered,
        height: AppBubbleSizes[size],
        width: AppBubbleSizes[size],
        paddingTop: Math.trunc(AppBubbleSizes[size] / 8),
        borderRadius: ShRadius.half,
        boxSizing: 'border-box',
        fontFamily: ShFonts.secondary,
        fontWeight: ShFontWeights.m,
        fontSize: AppBubbleSizes[size] / 2.5,
        color: AppBubbleStateColor[state][1],
        backgroundColor: AppBubbleStateColor[state][0],
        zIndex: 1,

        transition: `background-color ${ShEasings.ease} 300ms,
        color ${ShEasings.ease} 300ms`,
        ...(state === 'current' && AppBubbleCurrentOption),
        ...(state === 'previous' && AppBubblePreviousOption),
        ...(state === 'next' && AppBubbleNextOption),
        ...(state === 'none' && AppBubbleNoneOption),
      }}
    >
      {children}
    </div>
  );
};

export default AppBubble;

const AppBubbleCurrentOption = {
  backgroundColor: ShColors.base,
  color: ShColors.white,
  '&:hover': {
    backgroundColor: ShColors.base,
    color: ShColors.white,
    transition: `background-color 150ms ${ShEasings.easeOut},
                      color 150ms ${ShEasings.easeOut}`,
  },
};

const AppBubblePreviousOption = {
  backgroundColor: ShColors.black,
  color: ShColors.white,
};

const AppBubbleNextOption = {
  color: ShColors.link,
  backgroundColor: ShColors.whiteD,
};

const AppBubbleNoneOption = {
  color: ShColors.linkInvert,
  backgroundColor: ShColors.transparent,
  transition: `background-color .1s ${ShEasings.easeIn},
                    color .1s ${ShEasings.easeIn}`,

  '&:hover': {
    backgroundColor: ShColors.base,
    color: ShColors.white,
    transition: `background-color 150ms ${ShEasings.easeOut},
                      color 150ms ${ShEasings.easeOut}`,
  },
};
