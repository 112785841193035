/** @jsx jsx */
import { jsx } from '@emotion/core';
import Select, { SelectComponentsConfig } from 'react-select';

import { ShColors, ShFonts, ShRadius } from '@shoootin/design-tokens';
import { StylesConfig } from 'react-select';
import {
  ShInputSize,
  ShInputSizeConfigs,
  useShInputSize,
} from '../ShInputTheme';

// See https://react-select.com/styles#style-object
const reactSelectStyles = (size: ShInputSize): StylesConfig => {
  return {
    container: (base) => ({
      ...base,
      color: ShColors.black,
      fontFamily: ShFonts.primary,
      fontSize: '16px',
      lineHeight: 1.2,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: ShColors.black,
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
      backgroundColor: 'transparent',
    }),
    indicatorsContainer: (base) => ({
      ...base,
      padding: 0,
      '> div': {
        padding: 0,
      },
    }),
    input: (base) => ({
      ...base,
      outline: 'none',
      margin : 0,
      padding : 0,
    }),
    valueContainer: (base) => ({
      ...base,
      padding: 0,
      ' > div > span': ShInputSizeConfigs[size].selectCss.singleValue,
    }),
    //TODO
    //@ts-ignore
    control: (base) => ({
      ...base,
      borderRadius: ShRadius.m,
      backgroundColor: ShColors.whiteD,
      border: 'none',
      boxShadow: 'none',
      // If user selects 100 options, the input should grow
      // (otherwise it would overflow)
      //minHeight: 53,
      minHeight: 'unset',
      ...ShInputSizeConfigs[size].selectCss.control,
    }),
    placeholder: (base) => ({
      ...base,
      color: ShColors.placeholder,
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? ShColors.whiteD : ShColors.white,
      color: ShColors.black,
      padding: 10, //TODO theme
      fontFamily: state.isSelected ? ShFonts.secondary : ShFonts.primary,
      fontSize: '16px',
      lineHeight: 1.2,
      ':hover': {
        backgroundColor: ShColors.whiteD,
      },
    }),
    menu: (base) => {
      return {
        ...base,
        marginTop: 0,
        borderRadius: 0,
        boxShadow: '0 0 1rem 0 rgba(0,0,0,.1)',
      };
    },
    menuPortal: (base) => ({
      ...base,
      // Both back/front modals use z-index,
      // and we render the select as a body portal.
      // The select menu must have a higher z-index than the modal
      // or a select inside a modal would render its menu under the modal
      zIndex: 999999,
    }),
  };
};

// Option value is by default a nillable string,
// but you can reduce the scope and use an enum for example
type OptionValue = boolean | number | string | undefined;
type DefaultOptionValue = number | string;

export type ShInputSelectOption<OV extends OptionValue = DefaultOptionValue> = {
  value: OV;
  label?: string;
  data?: any;
};

// Convenient conversion function
export const toShInputSelectOptions = <OV extends OptionValue>(
  values: readonly OV[],
  createLabel: (value: OV) => string = (value) => String(value),
): ShInputSelectOption<OV>[] =>
  values.map((value) => ({
    value,
    label: createLabel(value),
  }));

export type ShInputSelectComponents<
  OV extends OptionValue = DefaultOptionValue
> = Pick<
  SelectComponentsConfig<ShInputSelectOption<OV>>,
  // Only allow to override these 2 for now
  'Option' | 'SingleValue'
>;

export type ShInputSelectProps<OV extends OptionValue = DefaultOptionValue> = {
  size?: ShInputSize;
  className?: string;
  options: ShInputSelectOption<OV>[];
  value: OV;
  onChange: (value: OV) => void;
  placeholder?: string;
  components?: ShInputSelectComponents<OV>;
  menuPosition?: 'fixed' | 'absolute';
  menuIsOpen?: boolean;
  isSearchable?: boolean;
};

// Expose simpler interface on purpose, React-select is an implementation detail
export const ShInputSelect = <OV extends OptionValue = DefaultOptionValue>({
  size: sizeProp,
  className,
  value,
  onChange,
  options,
  placeholder,
  components,
  menuPosition,
  menuIsOpen,
  isSearchable,
}: ShInputSelectProps<OV>) => {
  const size = useShInputSize(sizeProp);
  return (
    <Select<ShInputSelectOption<OV>>
      value={options.find((o) => o.value === value) ?? { value }}
      onChange={(option) => {
        // @ts-ignore
        onChange(option.value);
      }}
      className={className}
      styles={reactSelectStyles(size)}
      options={options}
      placeholder={placeholder}
      components={components}
      menuPortalTarget={document.body}
      menuPosition={menuPosition}
      menuIsOpen={menuIsOpen}
      isSearchable={isSearchable}
    />
  );
};

export type ShInputSelectMultiProps<
  OV extends OptionValue = DefaultOptionValue
> = {
  size?: ShInputSize;
  className?: string;
  options: ShInputSelectOption<OV>[];
  values: readonly OV[];
  onChange: (values: OV[]) => void;
  placeholder?: string;
  components?: ShInputSelectComponents<OV>;
  menuPosition?: 'fixed' | 'absolute';
  menuIsOpen?: boolean;
  isSearchable?: boolean;
};
export const ShInputSelectMulti = <
  OV extends OptionValue = DefaultOptionValue
>({
  size: sizeProp,
  className,
  values,
  onChange,
  options,
  placeholder,
  components,
  menuPosition,
  menuIsOpen,
  isSearchable,
}: ShInputSelectMultiProps<OV>) => {
  const size = useShInputSize(sizeProp);
  return (
    <Select<ShInputSelectOption<OV>>
      isMulti
      value={values.map(
        (value) => options.find((o) => o.value === value) ?? { value },
      )}
      onChange={(options) => {
        // @ts-ignore
        onChange((options ?? []).map((o) => o.value));
      }}
      className={className}
      styles={reactSelectStyles(size)}
      options={options}
      placeholder={placeholder}
      components={components}
      menuPortalTarget={document.body}
      menuPosition={menuPosition}
      menuIsOpen={menuIsOpen}
      isSearchable={isSearchable}
    />
  );
};
