/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { ShColors, ShFonts, ShMediaQueries } from '@shoootin/design-tokens';
import { ShClientACHDTO } from '@shoootin/api';
import { ShInputRadio } from '../../designSystem/primitives/input/ShInputRadio/ShInputRadio';
import { ShText } from '@shoootin/translations';
import { ShLinkText } from '../../designSystem/primitives/text/ShLinkText/ShLinkText';

const ACHInfo = ({
  ach,
  small = false,
}: {
  ach: ShClientACHDTO;
  small?: boolean;
}) => {
  const commonStyles = {
    marginTop: 2,
    marginBottom: 2,
    [ShMediaQueries.ShMobileOnly]: { fontSize: 14 },
  };
  return (
    <React.Fragment>
      <div css={commonStyles}>···· {ach.last4}</div>
      <div
        css={{
          fontSize: small ? 15 : 18,
          fontFamily: ShFonts.secondary,
          ...commonStyles,
        }}
      >
        {ach.bankName}
      </div>
      <div css={commonStyles}>Routing number : {ach.routingNumber}</div>
    </React.Fragment>
  );
};

export const ShSavedACH = ({
  selected = false,
  onSelect,
  ach,
  onDelete,
  small = false,
}: {
  selected?: boolean;
  onSelect?: () => void;
  ach: ShClientACHDTO;
  onDelete?: () => void;
  small?: boolean;
}) => {
  return (
    <div
      css={{
        borderRadius: 3,
        padding: small ? 10 : 25,
        paddingBottom: small ? 10 : 15,
        border: selected
          ? small
            ? `1px solid ${ShColors.base}`
            : `2px solid ${ShColors.base}`
          : small
            ? `1px solid ${ShColors.pattern}`
            : `2px solid ${ShColors.pattern}`,
        [ShMediaQueries.ShMobileOnly]: {
          padding: small ? 10 : 15,
          borderLeft: `3px solid ${ShColors.base}`,
        },
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          paddingBottom: 5,
          paddingTop: 5,
        }}
      >
        {onSelect && (
          <span
            css={{
              marginRight: 20,
              [ShMediaQueries.ShMobileOnly]: {
                order: 2,
                marginRight: 0,
              },
            }}
          >
            <ShInputRadio value={selected} onChange={onSelect} />
          </span>
        )}
        <span
          css={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            justifyContent: 'center',
            marginBottom: -4,
            [ShMediaQueries.ShMobileOnly]: {
              order: 1,
              marginLeft: 15,
            },
          }}
        >
          <ACHInfo ach={ach} small={small} />
        </span>
      </div>
      {onDelete && (
        <div
          css={{
            textAlign: 'right',
            paddingTop: 10,
            fontSize: '80%',
          }}
        >
          <span onClick={onDelete}>
            <ShLinkText bold={false}>
              <ShText message={'client_accountPageBilling_removeACH'} />
            </ShLinkText>
          </span>
        </div>
      )}
    </div>
  );
};
