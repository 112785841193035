/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { ShPartialVideoReviewUser, ShVideoClientDTO } from '@shoootin/api';
import { ShColors } from '@shoootin/design-tokens';
import { ShButton } from '../../designSystem/primitives/button/ShButton/ShButton';
import { ArrowNextSvg as ShSvgArrowNext } from '../../components/svg/picto/ArrowNextSvg';
import { ArrowPrevSvg as ShSvgArrowPrev } from '../../components/svg/picto/ArrowPrevSvg';
import { LogoSvg as ShLogoSvg } from '../../components/svg/LogoSvg';
import { ShIntercomArticles } from '../intercomArticles/ShIntercomArticles';
import { ShSpinner } from '../../designSystem/primitives/spinner/ShSpinner/ShSpinner';
import { SvgFirstFrame } from './ShVideoIcons';

export const Header = ({
  video,
  openCommentsNb,
  allCommentsNb,
  toggleCommentDisplay,
  commentDisplay,
  userType,
}: {
  video?: ShVideoClientDTO;
  openCommentsNb: number;
  allCommentsNb: number;
  toggleCommentDisplay: () => void;
  commentDisplay: boolean;
  userType: ShPartialVideoReviewUser;
}) => {
  return (
    <div
      css={{
        width: '100%',
        marginBottom: 10,
        padding: 10,
        border: `1px solid ${ShColors.blackL}`,
        backgroundColor: ShColors.blackLL,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <span css={{ marginRight: 10, paddingTop: 3 }}>
          {userType !== 'editor' ? (
            <ShLogoSvg width={80} color={'white'} />
          ) : (
            <SvgFirstFrame height={15} color={'white'} />
          )}
        </span>
        {video && <b>{video.title}</b>}
        {!video && <ShSpinner size={'xs'} color={'whiteD'} />}
      </div>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div css={{ marginRight: 10 }}>
          <ShButton onClick={toggleCommentDisplay}>
            <span css={{ marginRight: 5 }}>
              {commentDisplay ? (
                <ShSvgArrowNext width={12} color={'white'} />
              ) : (
                <ShSvgArrowPrev width={12} color={'white'} />
              )}
            </span>
            {allCommentsNb - openCommentsNb} / {allCommentsNb}
          </ShButton>
        </div>
        <div>
          <ShIntercomArticles id={300}>
            <ShButton size={'s'} variant={'glass'}>
              Help
            </ShButton>
          </ShIntercomArticles>
        </div>
      </div>
    </div>
  );
};
