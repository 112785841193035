/** @jsx jsx */
import { jsx } from '@emotion/core';

import { SvgProps } from '../../../components/svg/SvgUtils';
import { ShColors } from '@shoootin/design-tokens';
import React from 'react';

export const ArrowNextSvg = ({ width = 24, color = 'black' }: SvgProps) => (
  <svg
    className="chevron-next"
    viewBox="0 0 28 28"
    height={width * (2 / 3)}
    width={width}
    fill="none"
    stroke={ShColors[color]}
  >
    <polyline points="8,1.75 20.25,14 8,26.25 " />
  </svg>
);

export default ArrowNextSvg;
