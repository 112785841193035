import { defineMessages } from 'react-intl';
import { ShIntlMessageDefinition } from '@shoootin/translations';

const step_prefix_book = 'front_order_step_book';

export const ShOrderStepBook = defineMessages({
  intro: {
    id: `${step_prefix_book}_intro`,
    defaultMessage:
      'Sélectionnez une date et une heure pour votre rendez-vous.',
  },
  unavailable: {
    id: `${step_prefix_book}_unavailable`,
    defaultMessage:
      "La disponibilité que vous aviez initialement choisie ({initialSlot}) n'est plus disponible, nous vous invitons à choisir un autre créneau.",
  },
  availabilityOf: {
    id: `${step_prefix_book}_availabilityOf`,
    defaultMessage: 'Disponibilités du {day} :',
  },
  currentTimezone: {
    id: `${step_prefix_book}_currentTimezone`,
    defaultMessage:
      'Tous les créneaux sont affichés pour le fuseau horaire {timeZone}.',
  },
  ifNoAvailability: {
    id: `${step_prefix_book}_ifNoAvailability`,
    defaultMessage:
      'S’il n’y a aucun créneau disponible pour vos dates, notre équipe va vous trouvez une solution, contactez-nous pour que l’on puisse vous aider.',
  },
  noAvailabilityBefore: {
    id: `${step_prefix_book}_noAvailabilityBefore`,
    defaultMessage:
      "Nous n'avons plus de disponibilités avant le {selectedDay}.",
  },
  noAvailabilityMustBeDone: {
    id: `${step_prefix_book}_noAvailabilityMustBeDone`,
    defaultMessage:
      'Votre reportage doit être fait le {selectedDay} ? {clickHere} et notre équipe va trouver une solution.',
  },
  clickHere: {
    id: `${step_prefix_book}_clickHere`,
    defaultMessage: 'Cliquez ici',
  },
  noAvailabilityAtAll: {
    id: `${step_prefix_book}_noAvailabilityAtAll`,
    defaultMessage:
      "Notre équipe va trouver une solution et revenir vers vous rapidement. Pour cela, merci d'envoyer votre demande de reportage en cliquant sur le bouton ci-desous",
  },

  noAvailabilityAtAllTitle: {
    id: `${step_prefix_book}_noAvailabilityAtAllTitle`,
    defaultMessage: 'Pas de disponibilités ',
  },

  changeDateModalTitle: {
    id: `${step_prefix_book}_changeDateModalTitle`,
    defaultMessage: 'Reschedule my shooting',
  },
  confirmDateChange: {
    id: `${step_prefix_book}_confirmDateChange`,
    defaultMessage: 'Reschedule my shooting to {day} from {start} to {end}',
  },

  preferredPhotographers: {
    id: `${step_prefix_book}_preferredPhotographers`,
    defaultMessage: 'Your preferred photographers',
  },
});
